<template>
    <div v-if="!loading"  class="user"  v-click-outside="vcoConfig">
        <div class="user-name" @click="openUserMenu = !openUserMenu">
            <div class="user-icon">
                <img class="userAvatar" v-if="getUserInfo.avatar_file !== '' && getUserInfo.avatar_file !== null && getUserInfo.avatar_file !== undefined" :src="`${getConstants.common.domain_url}${getUserInfo.avatar_file}`" alt="">
                <svg v-else xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 600 600" fill="#fff"><defs><clipPath id="b"><circle cx="300" cy="300" r="280"/></clipPath><clipPath id="a"><rect width="100%" height="498"/></clipPath></defs><circle cx="300" cy="300" r="280" fill="#73818f" clip-path="url(#a)"/><circle cx="300" cy="230" r="115"/><circle cx="300" cy="550" r="205" clip-path="url(#b)"/></svg>
            </div>
            <span>{{getUserInfo.name}}</span>
            
            <svg class="icon">
                <use xlink:href="#arr-down"></use>
            </svg>
        </div>
        <div v-show="openUserMenu"  class="user-menu">

            <template v-for="(items, title) in getUserMenu.menu" :key="title">
                <div v-if="String(title) !== 'sign_out'" class="user-title">{{title}}</div>
                <template  v-if="String(title) !== 'sign_out'">
                    <a v-for="item in items" :key="item" :href="item.url" :title="item.title" class="user-link">
                        {{item.title}}

                        <span v-if="item.title === 'Affiliate Program'">${{getUserMenu.affiliate_balance}}</span>
                    </a>

                    <button v-if="String(title) === 'billing'"  
                        v-clipboard:copy="`https://pixelfy.me/?aff=${getUserMenu.affiliate_id}`" v-clipboard:success="copyLink"
                        class="user-link copy-affiliate">
                            <template v-if="copiedLink">Copied</template>
                            <template v-else>Invite a friend</template>
                    </button>
                </template>
            </template>
            
            <hr >

            <a class="user-link" :href="getUserMenu?.menu?.sign_out[0]?.url" :title="`${getUserMenu?.menu?.sign_out[0]?.title}`">
                <svg class="mr-2" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M17 16L21 12M21 12L17 8M21 12H7M13 16V17C13 17.7956 12.6839 18.5587 12.1213 19.1213C11.5587 19.6839 10.7956 20 10 20H6C5.20435 20 4.44129 19.6839 3.87868 19.1213C3.31607 18.5587 3 17.7956 3 17V7C3 6.20435 3.31607 5.44129 3.87868 4.87868C4.44129 4.31607 5.20435 4 6 4H10C10.7956 4 11.5587 4.31607 12.1213 4.87868C12.6839 5.44129 13 6.20435 13 7V8" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path></svg> 
                {{getUserMenu?.menu?.sign_out[0]?.title}}
            </a>
        </div>
    </div>
    <Skeleton v-else style="height: 32px; width: 175px; margin-left: auto;" />
</template>

<script lang="ts">
import {defineComponent} from 'vue';
import {mapGetters} from 'vuex';
import Skeleton from '../common/Skeleton.vue';

export default defineComponent({
    name: 'UserMenu',
    components:{ Skeleton },
    data() {
        return {
            openUserMenu : false,
            loading: true,
            copiedLink: false,
            vcoConfig: {
                middleware: this.middleware,
                events: ['click'],
                // Note: The default value is true, but in case you want to activate / deactivate
                //       this directive dynamically use this attribute.
                isActive: true,
                // Note: The default value is true. See "Detecting Iframe Clicks" section
                //       to understand why this behaviour is behind a flag.
                detectIFrame: true,
                // Note: The default value is false. Sets the capture option for EventTarget addEventListener method.
                //       Could be useful if some event's handler calls stopPropagation method preventing event bubbling.
                capture: true
            },
        }
    },

    watch: {
        getUserMenu(val) {
            if(val.menu.sign_out) {
                this.loading = false;
            }
        },

    },


    methods: {

        async copyLink() {
            this.copiedLink = true;
            new Promise( (resolve)=>{setTimeout(resolve, 2000) } )
                .then( ()=> {
                this.copiedLink = false;
            });
        },

        middleware (event:any) {
            if(event) {
                this.openUserMenu = false;
            }
        },

    },


    computed: {
        ...mapGetters(['getUserInfo', 'getUserMenu', 'getConstants']),
    }

});
</script>

<style lang="scss">
    .user {
        display: inline-flex;
        margin-left: auto !important;
        position: relative;
        &-name {
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 150%;
            color: #292D34;
            display: flex;
            align-items: center;
            cursor: pointer;

            .icon {
                width: 12px;
                height: 7px;
                margin-left: 8px;
            }
        }

        &-icon {
            width: 32px;
            height: 32px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 8px;
            img {
                width: 32px;
                height: 32px;
                border-radius: 50%;
            }
        }

        &-menu {
            position: absolute;
            top: calc(100% + 32px);
            right: 0;
            background: #FFFFFF;
            border-radius: 6px;
            box-shadow: 0 1rem 3rem rgba(0, 0, 0, .18);
            width: 240px;
            padding-bottom: 24px;

            &:before {
                content: "";
                background-image: url("data:image/svg+xml,%3Csvg width='24' height='7' viewBox='0 0 24 7' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M14.8284 1.82843L17.6569 4.65685C19.1571 6.15715 21.192 7 23.3137 7L0.686291 7C2.80802 7 4.84285 6.15715 6.34314 4.65686L9.17157 1.82843C10.7337 0.266332 13.2663 0.266329 14.8284 1.82843Z' fill='white'/%3E%3C/svg%3E%0A");
                background-repeat: no-repeat;
                width: 34px;
                height: 8px;
                position: absolute;
                top: -7px;
                right: 12px;
            }
        }

        &-title {
            padding: 34px 20px 8px;
            font-style: normal;
            font-weight: 700;
            font-size: 14px;
            line-height: 28px;
            letter-spacing: 1px;
            text-transform: uppercase;
            color: #5B60CA;
            margin-bottom: 2px;
        }

        &-link {
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            color: #292D34;
            padding: 8px 20px;
            text-decoration: none;
            transition: .3s;
            display: flex;
            align-items: center;
            margin-bottom: 2px;

            &.copy-affiliate {
                margin: 8px 20px;
                padding: 11px 24px;
                background: #FFFFFF;
                border: 1px solid #292D34;
                border-radius: 6px;
                justify-content: center;
                width: calc(100% - 40px);
            }

            svg {
                margin-right: 12px;
            }

            span {
                margin-left: auto;
            }
            &:hover {
                color: #5B60CA;
                background: rgba(0, 10, 214, 0.07);
            }

        }

        hr {
            margin-left: 20px;
            margin-right: 20px;
            border: 0;
            border-top: 1px solid rgba(0,0,0,0.1);
            margin-top: 28px;
            margin-bottom: 12px;    

        }
    }

    @media screen and (max-width: 991px) {
        .user-name {
            span {
                display: none;
            }
        }
    }
</style>