
import {defineComponent} from 'vue';
import {mapGetters} from 'vuex';
import ProfileChangeDomain from './ProfileChangeDomain.vue';
import InputCount from '../InputCount.vue';
import TextareaCount from '../TextareaCount.vue';
import MultipleDropdown from '../MultipleDropdown.vue';
// import myUpload from 'vue-image-crop-upload';
import Modal from '../Modal.vue';
import UploadImage from '../UploadImage.vue';
import { Cropper } from 'vue-advanced-cropper'

export interface profileData {
  domain: string;
  photo: string;
  shortlink: string;
  username: string;
  bio: string;
  plan: string;
}


export default defineComponent({
    name: 'ProfileCard',
    components:{ ProfileChangeDomain, InputCount, TextareaCount, MultipleDropdown, Modal, UploadImage, Cropper },
    emits: ['changeProfile'],
    props: {
        data: {} as any
    },

    data() {
        return {
            dataProfile: this.data,
            load: true,
            showUploadModal: false,
			cropImage: '',
            cropperImgUpload: '',
        }
    },
    watch: {
        data(val) {
            this.dataProfile = val;
        }
    },
    computed: mapGetters(['getUserInfo', 'getError']),

    methods: {

        closeModal(status: boolean) {
            this.showUploadModal = status;
        },

        saveModal() {
            if(this.cropImage !='') {
                this.dataProfile.options.avatar_image = this.cropImage;
                this.saveData(this.dataProfile);
            }
            this.cropImage = '';
            this.cropperImgUpload = '';
        },

        changeImage(img: any) {
            this.cropperImgUpload = img[0];
        },

        changeCropper(item: {coordinates: any, canvas:any}) {
            this.cropImage = item.canvas?.toDataURL("image/webp");
        },

        changeDomain(item: string) {
            this.dataProfile.domain = item;
            this.saveData(this.dataProfile);
        },
        changeInput(item: string) {
            this.dataProfile.code = item;
            this.saveData(this.dataProfile);
        },
        changeUserName(item: string) {
            this.dataProfile.title = item;
            this.saveData(this.dataProfile);
        },
        changeDescription(item: string) {
            this.dataProfile.desc = item;
            this.saveData(this.dataProfile);
        },

        changeCode(val: []) {
            this.dataProfile.pixelcodes = val;
            this.saveData(this.dataProfile);
        },

        saveData(item:  any) {
            this.$emit('changeProfile', item)
        },

        toggleShow() {
            this.cropImage = '';
            this.cropperImgUpload = '';
            this.showUploadModal = !this.showUploadModal;
		},

        cropSuccess(imgDataUrl: string){
            // this.imgDataUrl = imgDataUrl;

            this.dataProfile.options.avatar_image = imgDataUrl;
            this.saveData(this.dataProfile);
        },
        
    },
    created() {
        if(this.dataProfile.title == '') {
            this.changeUserName(this.getUserInfo.name)
        }
    }
});
