
import {defineComponent} from 'vue';

export default defineComponent({
    name: 'SelectFontSize',
    components:{},
    props: {
        selected: String
    },
    emits: ['changeFontSize'],
    data() {
        return {
            classNameFont: this.selected,
        }
    },
    methods: {
        selectSize(item: {'name': string, 'className': string}) {
            this.classNameFont = item.className;
            this.$emit('changeFontSize',item)

        }
    },
    watch: {
        selected(val) {
            this.classNameFont = val;
        }
    },
    setup() {
        const size = [
            {
                'name' : 'XS',
                'className' : 'font-xs'
            },
            {
                'name' : 'S',
                'className' : 'font-s'
            },
            {
                'name' : 'M',
                'className' : 'font-m'
            },
            {
                'name' : 'L',
                'className' : 'font-l'
            },
            {
                'name' : 'XL',
                'className' : 'font-xl'
            }
        ];

        return {
            size
        }
    }
});
