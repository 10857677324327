
import {defineComponent} from 'vue';
import {mapGetters} from 'vuex';

export default defineComponent({
    name: 'AnaliticsSocialLinkPerformance',
    components:{},
    props: ['title', 'data', 'chartUnique'],
    data() {
        return {
            dataItems: this.data || [],
            unique: this.chartUnique 
        }
    },
    watch: {
        data(val) {
            this.dataItems = val;
        },
        chartUnique(val) {
            this.unique = val;
        } 
    },
    computed: {
        ...mapGetters(['getSocialsData']),
    },
});
