<template>
    <div class="item-block">
        <div  
        class="links-item">
            <div class="drag-drop">
                <i class="icon-dots"></i>
            </div>
            <div class="links-content">
                <div>
                    <div class="content-col">

                        <div class="links-title input-lib" :class="[{'focus': focused}]">
                            <div @click="openModal(index)" class="links-icon" v-if="linksItems.options?.favicon !== 'PhotoIcon'">
                                
                                <template v-if="linksItems.options.favicon !== null">
                                    <component v-if="icons[linksItems.options.favicon]"
                                        :class="{'selected-icon' : linksItems.options?.favicon !== 'PhotoIcon'}"
                                        size="24"
                                        :stroke-width="strokeWidth"
                                        :is="icons[linksItems.options.favicon]"
                                        :style="{ color }"
                                    />
                                    <img v-else class="icon" :src="linksItems.options?.favicon"  onerror="this.src = 'data:image/svg+xml;base64,PHN2ZyBmaWxsPSJyZWQiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgdmlld0JveD0iMCAwIDExNS4xOSAxMjMuMzgiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDExNS4xOSAxMjMuMzgiIHhtbDpzcGFjZT0icHJlc2VydmUiPjxwYXRoIGNsYXNzPSJzdDAiIGQ9Ik05My4xMyA3OS41YzEyLjA1IDAgMjEuODIgOS43NyAyMS44MiAyMS44MnMtOS43NyAyMS44Mi0yMS44MiAyMS44Mi0yMS44Mi05Ljc3LTIxLjgyLTIxLjgyUzgxLjA4IDc5LjUgOTMuMTMgNzkuNXpNOC4wOC4yNWg5NS4yOGMyLjE3IDAgNC4xMS44OSA1LjUzIDIuM2E3LjgyOCA3LjgyOCAwIDAgMSAyLjMgNS41M3Y3MC4wMWEyOC43OTcgMjguNzk3IDAgMCAwLTguMjUtNC40OFY5Ljk4YzAtLjQzLS4xNi0uNzktLjQ2LTEuMDUtLjI2LS4yNi0uNjYtLjQ2LTEuMDUtLjQ2SDkuOTRjLS40MyAwLS43OS4xNi0xLjA1LjQ2LS4yNi4yNi0uNDYuNjUtLjQ2IDEuMDVWODBoLjAzbDMxLjk3LTMwLjYxYzEuMjgtMS4xOCAzLjI5LTEuMDUgNC40NC4yMy4wMy4wMy4wMy4wNy4wNy4wN2wyNi44OCAzMS44YTI5LjA1MyAyOS4wNTMgMCAwIDAtNy42MiAxOS42NWMwIDMuMjkuNTUgNi40NSAxLjU1IDkuNEg4LjA4Yy0yLjE3IDAtNC4xMS0uODktNS41My0yLjNzLTIuMy0zLjM5LTIuMy01LjUzVjguMDhjMC0yLjE3Ljg5LTQuMTEgMi4zLTUuNTNTNS45NC4yNSA4LjA4LjI1em02NS45IDc5LjEgMy43MS0yMi43OWMuMy0xLjcxIDEuOTEtMi45IDMuNjItMi42LjY2LjEgMS4yNS40MyAxLjcxLjg2bDE3LjEgMTcuOTdjLTIuMTgtLjUyLTQuNDQtLjc5LTYuNzgtLjc5YTI4Ljk3NCAyOC45NzQgMCAwIDAtMTkuMzYgNy4zNXptOC02MS4xNmMzLjEzIDAgNS45OSAxLjI4IDguMDMgMy4zMmExMS4yOSAxMS4yOSAwIDAgMSAzLjMyIDguMDNjMCAzLjEzLTEuMjggNS45OS0zLjMyIDguMDNhMTEuMjkgMTEuMjkgMCAwIDEtOC4wMyAzLjMyYy0zLjEzIDAtNS45OS0xLjI4LTguMDMtMy4zMmExMS4yOSAxMS4yOSAwIDAgMS0zLjMyLTguMDNjMC0zLjEzIDEuMjgtNS45OSAzLjMyLTguMDMgMi4wNy0yLjA3IDQuOTEtMy4zMiA4LjAzLTMuMzJ6bTMuODQgNjkuODYgMTkuOTYgMjEuNmMxLjU4LTIuMzkgMi41LTUuMjUgMi41LTguMzMgMC04LjM2LTYuNzgtMTUuMTQtMTUuMTQtMTUuMTQtMi42Ni0uMDEtNS4xNS42Ny03LjMyIDEuODd6bTE0LjYyIDI2LjUzLTE5Ljk2LTIxLjZjLTEuNTggMi4zOS0yLjUgNS4yNS0yLjUgOC4zMyAwIDguMzYgNi43OCAxNS4xNCAxNS4xNCAxNS4xNCAyLjY2LjAxIDUuMTUtLjY3IDcuMzItMS44N3oiLz48L3N2Zz4='" alt="">
                                </template>
                            </div>

                            <input @focus="focused = true" @blur="focused = false"  type="text" placeholder="Link title"  :maxlength="limit" v-model="linkName" @change="setLinkName(linksItems, index)">
                            <span class="symbols">{{linkName.length}}/{{limit}}</span>
                        </div>
                        <!-- <div v-if="(!validation.linkName  && linkName.length >= 1) || (linkName.length < 4 && linkName.length >= 1)" class="error-mess">{{validation.errorMess.linkName}}</div> -->
                    </div>

                    <div class="content-col">
                        <input :class="{'is-invalid': (!validation.linkUrl  && linkUrl.length >= 1) || (linkUrl.length < 4  && linkUrl.length >= 1)}" class="input-lib" type="text" placeholder="Enter URL here" v-model="linkUrl" @change="setLinkUrl(linksItems, index)">

                        <div v-if="(!validation.linkUrl && linkUrl.length >= 1) || (linkUrl.length < 4 && linkUrl.length >= 1)" class="error-mess">{{validation.errorMess.linkUrl}}</div>
                    </div>

                    <div v-show="!confirm" class="link-nav">
                        <div class="nav-action">
                            <button @click="openModal(index)" class="link-action">
                                <template v-if="linksItems.options?.favicon == 'PhotoIcon'">Add icon</template>
                                <template v-else>Change icon</template>
                            </button>
                        </div>
                        <div class="nav-action">
                            <i class="icon-delete" @click="confirmChange()">
                                <svg class="icon">
                                    <use xlink:href="#trash"></use>
                                </svg>
                            </i>
                            <label :for="`checkbox-sw_${linksItems.id}`" class="switch-lib">
                                <input 
                                    :id="`checkbox-sw_${linksItems.id}`" 
                                    type="checkbox" 
                                    name="deeplinks"  
                                    class="switch-input"
                                    :class="{'is-invalid': (!validation.linkUrl ) || (linkUrl.length < 4 )}"
                                    v-model="check" 
                                    @change="publishEvent(linksItems, index)">
                                <span class="switch-lib-slider"></span>
                            </label>                            
                        </div>
                    </div>
                </div>
                <!-- <Transition name="nested" > -->
                    <div class="confirm" v-if="confirm">
                        <div class="confirm-item">
                            <div class="confirm-title">Delete this link forever? </div>
                            <p class="confirm-subtitle">This action will also delete all the statistics for this link.</p>
                        </div>
                        <div class="confirm-btn">
                            <button  @click="confirm = false" class="btn-lib btn-lib-sm btn-lib-secondary">
                                Cancel
                            </button>
                            <button @click="deleteEvent(linksItems, index)" class="btn-lib btn-lib-sm btn-lib-dark">
                                Delete
                            </button>
                        </div>
                    </div>
                <!-- </Transition> -->

            </div>
            
        </div>
        

    </div>
</template>

<script lang="ts">
import * as icons from 'vue-tabler-icons';
import { defineComponent} from 'vue';
import {mapGetters} from 'vuex';

export default defineComponent({
    name: 'LinkButtonsItem',
    components:{},
    emits: ['openModal', 'setLinkName', 'publishEvent', 'setLinkUrl', 'deleteEvent'],
    props: {
        data : {
            type: Object as any,
            required: true
        },
        index: Number as any
    },

    data() {
        return {
            linksItems: this.data as any,
            dragging: false,
            tabModal: 0,
            color: '#5B60CA',
            strokeWidth: '2',
            limit: 40,
            selectIndex: 0,
            confirm: false,
            check: this.data.status === 'active' ? true : false,
            // check: true ,
            linkName: this.data.title || '',
            linkUrl: this.data.url || '',
            validation: {
                'linkName': true,
                'linkUrl': true,
                'errorMess': {
                    'linkName' : 'The Link Title is too short.',
                    'linkUrl' : 'The URL is not valid.'
                }
            },
            focused: false,
        }
    },

    watch: {
        linkName(val) {
            this.linksItems.title = val;
        },

        linkUrl(val) {
            this.linksItems.url = val;
        },

        data(val) {
            if(val.status === this.getConstants.common.status[0]) {
                this.check = true;
            }
        },

        linksItems(val) {

            if(val.url.length > 4  && val.url.includes('.') && this.getUrlDomain(val.url)) {
                this.validation.linkUrl = true;
            }else{
                this.validation.linkUrl = false;
            }

            
        }
    },

    methods : {
        confirmChange() {
            this.confirm = !this.confirm;
        },

        publishEvent(event: any, index: number) {

            if(event.url?.length > 4  && this.getUrlDomain(event.url)) {
                this.validation.linkUrl = true;
            }else{
                this.validation.linkUrl = false;
            }


            if(this.check && this.validation.linkUrl) {
                event.status = this.getConstants.common.status[0]
            }else{
                event.status = this.getConstants.common.status[1]
            }

            console.log('publishEvent',event)

            
            this.$emit('publishEvent', {
                'item': event,
                'index': index
            })
        },

        setLinkUrl(event: any, index: number) {

            if(!event.url.includes('http') && event.url.length > 0) {
                this.linkUrl = `https://${event.url}`;
                event.url = `https://${event.url}`;
            }


            
            if(event.url.length > 4 && event.url.includes('.') && this.getUrlDomain(event.url)) {
                this.validation.linkUrl = true;             
            }else{
                this.validation.linkUrl = false;
            }

            this.publishEvent(event, index);

            this.$emit('setLinkUrl', {
                'item': event,
                'index': index
            })


        },        

        setLinkName(event: any, index: number) {


            if(event.url?.length > 4  && this.getUrlDomain(event.url)) {
                this.validation.linkUrl = true;
            }else{
                this.validation.linkUrl = false;
            }

       

            this.$emit('setLinkName', {
                'item': event,
                'index': index
            })
        },
     
        deleteEvent: function(event: any, index:number) {

            this.$emit('deleteEvent', {
                'item': event,
                'index': index
            })

           
        },

        openModal(index: number) {
            this.$emit('openModal', index)
        },

        getUrlDomain(url: any) {
            if(url.includes('http')) {
                let hostUrl = new URL(String(url));
            
                return  this.getDomain.indexOf('.'+(hostUrl.host.split('.').filter(Boolean))[hostUrl.host.split('.').filter(Boolean).length-1]) != -1;
            }

            return false;
        },


    },
    computed : {
        ...mapGetters(['getConstants', 'getDomain']),

        icons() {
            return icons;
        },
    },
    
});
</script>

<style lang="scss">
    .nested-enter-active, .nested-leave-active {
        transition: all 0.3s ease-in-out;
    }
    /* delay leave of parent element */
    .nested-leave-active {
        transition-delay: 0.25s;
    }

    .nested-enter-from,
    .nested-leave-to {
        transform: translateY(30px);
        opacity: 0;
    }

    .selected-icon {
        color: #000000 !important;
    }

    .link-nav {
        min-height: 60px;
    }

    .confirm {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        margin: 10px -12px 0;
        width: 100%;

        &-item {
            max-width: 360px;
            padding: 0 12px;
            margin: 10px 0;
        }

        &-title {
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 1.2;
            letter-spacing: -0.2px;
            color: #292D34;
        }
        
        &-subtitle {
            font-family: 'Lato';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 1.2;
            color: #50545B;
        }

        &-btn {
            display: flex;
            align-items: center;
            padding: 0 12px;
            margin: 10px 0;

            button {
                width: 90px !important;
                &:first-child {
                    margin-right: 12px;
                }
            }
        }
    }

</style>