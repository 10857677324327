<template >
<footer class="footer">
    <div>© 2022 Pixelfy Corp Inc.</div>
    <div class="footer-link">
        <a target="_blank" class="link-lib mr-3" :href="`${getConstants.common?.domain_url}/terms`" rel="nofollow">Terms of use</a> 
        <a target="_blank" class="link-lib" :href="`${getConstants.common?.domain_url}/privacy`" rel="nofollow">Privacy policy</a>
    </div>
</footer>  
</template>

<script lang="ts">
import {defineComponent} from 'vue';
import {mapGetters} from 'vuex';

export default defineComponent({
    name: 'Footer',
    computed: mapGetters(['getConstants']),
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.footer {
    padding: 12.5px 24px;
    background: #EAEFEF;
    display: flex;
    align-items: center;
    transition: .3s;
    margin-top: 37px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #292D34;
    .mr-3 {
        margin-right: 1rem;
    }

    &-link {
        margin-left: 20px;

        .link-lib {
            font-weight: normal;
        }
    }
}
</style>