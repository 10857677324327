<template>
<h1></h1>
</template>

<script lang="ts">
    import {defineComponent} from 'vue';
   

    export default defineComponent({
        name: "Home",
        beforeCreate() {
            window.location.href = '/admin'
        },
    });

</script>

<style scoped>

</style>