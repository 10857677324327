
import {defineComponent} from 'vue';
import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import Skeleton from '../Skeleton.vue';


export default defineComponent({
    name: 'AnaliticsLineChart',
    components:{ Skeleton },
    props: ['chart', 'chartInfo', 'chartUnique'],
    data() {
        return {
            root: '' as any,
            dataChartFull: this.chart[0] || [],
            dataInfo: this.chartInfo[0] || [],
            dataChart: [] as any,
            unique: this.chartUnique,
            loaded: false,
        }
    },
    watch: {
        chart(val) {
            this.dataChartFull = val[0];
            this.reloadChart();

        },
        chartInfo(val) {
            this.loaded = false;
            this.dataInfo = val[0];
        },
        
        chartUnique(val){
            this.unique = val;
            this.reloadChart();
        }
        
    },
    methods: {
        reloadChart(){
            this.loaded = false;
            this.root.dispose();

            this.dataChart = [];

            if(this.unique === 1) {
                Object.values(this.dataChartFull.page).forEach((element:any, index) => {
                    this.dataChart.push({
                        'date': element.date,
                        'page': this.dataChartFull.page[index].unique,
                        'simple': this.dataChartFull.simple[index].unique,
                        'social': this.dataChartFull.social[index].unique,
                    })
                });
            }else{
                Object.values(this.dataChartFull.page).forEach((element:any, index) => {
                    this.dataChart.push({
                        'date': element.date,
                        'page': this.dataChartFull.page[index].total,
                        'simple': this.dataChartFull.simple[index].total,
                        'social': this.dataChartFull.social[index].total,
                    })
                });
            }

            setTimeout(() =>{
                this.loaded = true;
                setTimeout(() =>{
                    this.loadChart(this.dataChart);
                }, 200)
            }, 800)
        },

       loadChart($data: any)  {

            let root = am5.Root.new((this.$refs.chartdiv as HTMLInputElement));

            // Set themes
            // https://www.amcharts.com/docs/v5/concepts/themes/
            root.setThemes([
                am5themes_Animated.new(root)
            ]);
            // Create chart
            // https://www.amcharts.com/docs/v5/charts/xy-chart/
            var chart = root.container.children.push(am5xy.XYChart.new(root, {
                panX: false,
                panY: false,
                pinchZoomX: false
            }));
            // The data

            let data = $data;

             // Create axes
            // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
            let xAxis = chart.xAxes.push(am5xy.CategoryAxis.new(root, {
                categoryField: "date",
                startLocation: 0.5,
                endLocation: 0.5,
                renderer: am5xy.AxisRendererX.new(root, {}),
                tooltip: am5.Tooltip.new(root, {})
            }));

            xAxis.data.setAll(data);
                
                // Add cursor
                // https://www.amcharts.com/docs/v5/charts/xy-chart/cursor/
                let cursor = chart.set("cursor", am5xy.XYCursor.new(root, {
                    behavior: "none"
                }));

                cursor.lineY.set("visible", false);

               

                let yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
                    renderer: am5xy.AxisRendererY.new(root, {})
                }));

                // Add series
                // https://www.amcharts.com/docs/v5/charts/xy-chart/series/

                function createSeries(name:any, field:any, fieldColor:any) {
                    let series = chart.series.push(am5xy.LineSeries.new(root, {
                        name: name,
                        xAxis: xAxis,
                        yAxis: yAxis,
                        stacked:true,
                        valueYField: field,
                        fill: fieldColor,
                        categoryXField: "date",
                        tooltip: am5.Tooltip.new(root, {
                        pointerOrientation: "horizontal",
                        labelText: "[bold]{name}[/]\n{categoryX}: {valueY}"
                        })
                    }));

                    series.fills.template.setAll({
                        fillOpacity: 0.7,
                        visible: true
                    });

                    series.data.setAll(data);
                    series.appear(1000);
                }

                createSeries("Page views", "page", am5.color(0x82A9F1));
                createSeries("Links clicks", "simple", am5.color(0x7C66DC));
                createSeries("Social clicks", "social", am5.color(0xBE4CB8) );


                chart.appear(1000, 100);


            this.root = root;


       }
    },
    mounted() {
        // < !--Chart code-- >
        am5.addLicense("AM5C335843595");
        am5.addLicense("AM5M335847822");
        this.loadChart(this.dataChart);

    },
    created() {
        this.dataChart = [] as any;

        Object.values(this.dataChartFull?.page).forEach((element:any, index) => {
            this.dataChart.push({
                'date': element.date,
                'page': this.dataChartFull.page[index].unique,
                'simple': this.dataChartFull.simple[index].unique,
                'social': this.dataChartFull.social[index].unique,
            })
        });

        this.loaded = true;
    }
      
});
