
import {defineComponent} from 'vue';
import {mapGetters, mapActions} from 'vuex';

export default defineComponent({
    name: "Login",
    components: {  },
    data() {
        return {
            data: {
                email: '1@pixelfy.me',
                pass: 'testtest'
            }
        }
    },
    computed: mapGetters(['getToken']),
    methods: {
        ...mapActions(['tokenUser']),
    }
});
