
import {defineComponent} from 'vue';
import {mapGetters} from 'vuex';

export interface dropdownItems {
  id: number;
  name: string;
}

export default defineComponent({
    name: 'MultipleDropdown',
    emits: ['changeDropdown'],
    components:{},
    props: ['select', 'items'],
    data() {
        return {
            selectedItems: this.select as number[] || [],
            dropdownItems: this.items as Array<dropdownItems> || [],
            show: false,
            vcoConfig: {
                middleware: this.middleware,
                events: ['click'],
                isActive: true,
                detectIFrame: true,
                capture: true
            },
            constants: [] as any
        }
    },

    watch: {
        select(val) {
            this.selectedItems = val;
        },
        items(val) {
            this.dropdownItems = val;
        },
        getConstants(val) {
            this.constants = val;
        }
    },

    methods: {
        selectedItem(item: {'id': number, 'name':string}) {
            if(this.selectedItems.find((el: number) => el === item.id)){
                this.selectedItems.splice(this.selectedItems.indexOf(item.id), 1);
            }else{
                this.selectedItems.push(item.id)
            }

            this.$emit('changeDropdown', this.selectedItems)
        },
        middleware () {
            this.show = false; 
        },
    },

    computed : {
        ...mapGetters(['getConstants']),
    }
});
