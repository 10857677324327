
import {defineComponent} from 'vue';
import {mapGetters} from 'vuex';


export default defineComponent({
    name: 'InputSocialType',
    components:{},
    emits: ['value', 'publishEvent', 'deleteEvent', 'backItem'],
    props: {
        index: Number as any,
        data: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            focused: false,
            backValue: {} as any,
            inputValue: this.data.url || "",
            dataSocial: this.data,
            confirm: false,
            // check: this.data.status === 'active' ? true : false,
            check:  true,
            errorValue: false, 
        }
    }, 

    watch: {
        data(val) {
            this.dataSocial = val;
        },
        inputValue(val){
            if((val.includes('@') && val.length > 4 && val.includes('.') )|| (val.length > 4 && this.getUrlDomain(val))) {
                this.errorValue = false;
            }
        }
    },

    computed: mapGetters(['getSocialsData', 'getConstants', 'getDomain']),

    methods: {
        getNickname(val: string ){
            let value = val.split('/').filter(Boolean);
            let nicknameValue = value[value.length - 1];
            return nicknameValue;
        },

        changeInput(inputValue:string, event: any) {

            /** if(event.options.favicon == 'facebook') {
                this.inputValue = `https://www.facebook.com/${this.getNickname(inputValue)}`;
            }else if(event.options.favicon == 'instagram') {
                this.inputValue = `https://www.instagram.com/${this.getNickname(inputValue)}`;
            }else  */

            if(!inputValue.includes('http') && inputValue.length > 0 && event.options.favicon !== 'email') {
                this.inputValue = `https://${inputValue}`;
                
                inputValue = `https://${inputValue}`;
            }

            if((inputValue.length > 4 && this.getUrlDomain(inputValue) ) || (inputValue.includes('@') && inputValue.length > 4 && inputValue.includes('.'))) {
                this.errorValue = false;
            }else {
                this.errorValue = true;
            }

            

            if(event.options.favicon == 'email' && !inputValue.includes('mailto:')) {
                this.inputValue = `mailto:${inputValue}`;
            }else{
                this.inputValue = inputValue;
            }
            

            this.$emit('value', {
                'index': this.index,
                'value': this.inputValue
            });

            this.publishEvent(event, this.index)
            
        },

        deleteEvent: function(event: any, index: number) {
            this.$emit('deleteEvent', {
                'item': event,
                'index': index
            })
        },

        publishEvent(event: any, index: number) {

            if((event.url.length > 4 && this.getUrlDomain(event.url)) || (event.url.includes('@') && event.url.length > 4 && event.url.includes('.'))) {
                this.errorValue = false;
            }else {
                this.errorValue = true;
            }

            
            if(this.check && !this.errorValue) {
                event.status = this.getConstants.common.status[0]
            }else{
                event.status = this.getConstants.common.status[1]
            }


            this.$emit('publishEvent', {
                'item': event,
                'index': index
            })
        },
        backItem(){
            this.$emit('backItem', this.backValue);
            this.backValue = {};
        },

        getUrlDomain(url: any) {
            if(url.includes('http')) {
                let hostUrl = new URL(String(url));
            
                return  this.getDomain.indexOf('.'+(hostUrl.host.split('.').filter(Boolean))[hostUrl.host.split('.').filter(Boolean).length-1]) != -1;
            }

            return false;
        },

        validateEmail(email: string) {
            let re = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
            return re.test(String(email).toLowerCase());
        }
    },
   
});
