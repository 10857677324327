
import {defineComponent} from 'vue';

export default defineComponent({
    name: 'AnaliticsTopReferrers',
    components:{},
    props: ['title', 'progressColor', 'data', 'chartUnique'],
    
    data() {
        return {
            dataChart: this.data || [],
            unique: this.chartUnique,
            loaded: false,
        }
    },
    watch: {
        data(val) {
            this.dataChart = val;
        },

        chartUnique(val){
            this.unique = val;
        }
    }
});
