
import {defineComponent} from 'vue';
import SvgSprites from '@/components/common/SvgSprites.vue';

export default defineComponent({
    name: 'App',
    components: {
        SvgSprites
    }
});
