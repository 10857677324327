
import {defineComponent} from 'vue';

export default defineComponent({
    name: 'TextareaCount',
    emits: ['value'],
    components:{},
    props: {
        placeholder: String,
        maxsymbols: Number,
        value: String,
        inputClass: String,
        required: Boolean
    },
    data() {
        return {
            focused: false,
            inputValue: this.value ? this.value : '',
            heightTextarea: '28px',
        }
    },
    watch: {
        value(val) {
            this.inputValue = val;
        }
    },
    methods: {
        changeInput(value: string) {
            this.$emit('value', value)
        },

        resize(e:any){
            e.target.style.height = '28px';
            e.target.style.height = `${e.target.scrollHeight}px`;
        }
    }
});
