<template>
    <div class="analitics-container">

        <h6 class="h6-lib ff-Poppins font-weight-600">{{title}}</h6>
        <div class="analitics-items">
            <div v-for="item in dataItems" :key="item" class="analitics-item social-item">
                <div class="analitics-name">
                    <span>
                        <svg class="icon">
                            <use :xlink:href="`${getSocialsData[item.options?.favicon]?.icon}`"></use>
                        </svg>
                    </span> 
                    <span v-tippy="`${item.url}`">{{item.url}}</span>
                </div>
                <div class="analitics-number">
                    <template v-if="unique">{{item.unique}}</template>
                    <template v-else>{{item.total}}</template>
                </div>
                <div class="analitics-ctr">
                    <div class="ctr-title">CTR </div>
                    <div class="ctr-rate" >
                        <template v-if="unique">{{item.ctr_unique}}%</template>
                        <template v-else>{{item.ctr_total}}%</template>
                    </div>
                </div>
            </div>
        </div>
        <img v-if="Object.values(dataItems).length === 0" src="@/assets/images/no-data.svg" alt="Empty data" class="w-100 no-data" />
    </div>
</template>

<script lang="ts">
import {defineComponent} from 'vue';
import {mapGetters} from 'vuex';

export default defineComponent({
    name: 'AnaliticsSocialLinkPerformance',
    components:{},
    props: ['title', 'data', 'chartUnique'],
    data() {
        return {
            dataItems: this.data || [],
            unique: this.chartUnique 
        }
    },
    watch: {
        data(val) {
            this.dataItems = val;
        },
        chartUnique(val) {
            this.unique = val;
        } 
    },
    computed: {
        ...mapGetters(['getSocialsData']),
    },
});
</script>

<style lang="scss">
  .analitics-name {
    span {
        display: inline-block;
        white-space: nowrap;
        overflow: hidden;
        max-width: calc(100% - 30px);
    }
  }
</style>