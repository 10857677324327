<template>
   <form action="#" class="search">
        <input v-model="search" type="text" class="input-lib search-input" placeholder="Search icon">
    </form>
    <div class="icon-container">
        <div class="icon-item">
            <h6 class="title">Icons </h6>
            
            <ul>
              <template v-for="(item, index) in filteredList"  :key="index">
                <li :class="{'active': selectionIcon == item.name}"  
                    @click="changeIcon(item.name)"
                    v-if="Object.keys(item).length > 1"
                    >
                    <component 
                            size="24"
                            :stroke-width="strokeWidth"
                            :is="item"
                            :style="{ color }"
                        />
                
                </li>
              </template>
            </ul>
        </div>
    </div>
</template>

<script lang="ts">
import {defineComponent} from 'vue';
import * as icons from 'vue-tabler-icons';

export default defineComponent({
    name: 'SelectedIcon',
    components:{},
    emits: ['icon'],
    data() {
        return {
            color: 'black',
            strokeWidth: '2',
            search: '',
            iconsModal: icons,
            selectionIcon: '',
        };
    },
    computed: {
        icons() {
          return icons;
        },
        
        filteredList(): any {
          return  Object.fromEntries(Object.entries(icons).filter(([key]) => key.toLowerCase().includes(this.search.toLowerCase())));
        }
        
    },
    methods : {
        changeIcon(name: string) {
            this.selectionIcon = name;
            this.$emit('icon', name);
        }
    },
    

});
</script>

<style lang="scss">

.search {
  width: 100%;
  margin-bottom: 24px;

  &-input {
    width: 100%;
    display: block;
  }
}

.icon-container {
  border-top: 2px solid #E0E9EE;
  border-bottom: 2px solid #E0E9EE;
  max-height: 240px;
  overflow-x: hidden;
  overflow-y: auto;
  padding-top: 24px;
  padding-bottom: 24px;
  padding-left: 32px;
  padding-right: 16px;
  margin-left: -32px;
  margin-right: -32px;
  margin-bottom: 28px;

  &::-webkit-scrollbar {
    width: 7px;
    /* width of the entire scrollbar */
  }

  &::-webkit-scrollbar-track {
    background: #ECEDF4;
    /* color of the tracking area */
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 20px;
    /* roundness of the scroll thumb */
    border: 7px solid #B1BDD1;
    /* creates padding around scroll thumb */
  }

  .icon-item {
    /*    max-height: 255px;
    overflow-y: auto;
    overflow-x: hidden;*/
    margin-bottom: 16px;

    &::-webkit-scrollbar {
      width: 7px;
      /* width of the entire scrollbar */
    }

    &::-webkit-scrollbar-track {
      background: #ECEDF4;
      /* color of the tracking area */
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 20px;
      /* roundness of the scroll thumb */
      border: 7px solid #B1BDD1;
      /* creates padding around scroll thumb */
    }

    .title {
      font-family: 'Lato';
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      color: #292D34;
      margin-bottom: 16px;
      text-align: left;
    }
    .subtitle {
      font-family: 'Lato';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 1;
      color: #828D99;
      margin-bottom: 24px;
      margin-top: -8px;
    }
  }

  ul {
    list-style: none;
    padding: 0;
    margin: -6px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    li {
      margin: 6px;
      padding: 10px;
      width: 44px;
      height: 44px;
      border: 1px solid #DCE3EA;
      border-radius: 10px;
      position: relative;
      color: #111928;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      transition: border-color 0.2s cubic-bezier(0.08, 0.59, 0.29, 0.99) 0s;
      // &:before {
      //   content: "";
      //   position: absolute;
      //   top: 50%;
      //   left: 50%;
      //   transform: translate(-50%, -50%);
      //   transition: border-color 0.2s cubic-bezier(0.08, 0.59, 0.29, 0.99) 0s;
      // }
      svg,
      img {
        width: 24px;
        height: 24px;
      }

      &.active,
      &:hover {
        // border-color: #5B60CA;
        background: #EAEFEF;
        // &:before {
        //   width: 44px;
        //   height: 44px;
        //   border: 3px solid #5B60CA;
        //   border-radius: 10px;
        // }
      }
    }
  }
}
</style>