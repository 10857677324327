<template>
  <span class="skeleton" />
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "Skeleton",
});
</script>

<style lang="scss">
.skeleton {
  background-color: #eee;
  width: 100%;
  position: relative;

  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    background-image: linear-gradient(
      90deg,
      rgba(#fff, 0) 0,
      rgba(#fff, 0.2) 20%,
      rgba(#fff, 0.5) 60%,
      rgba(#fff, 0)
    );
    animation: shimmer 1s infinite;
    content: "";
  }

  @keyframes shimmer {
    100% {
      transform: translateX(100%);
    }
  }
}
</style>
