<template>
    <div class="input-lib" :class="[{'focus': focused, inputClass}, {'is-invalid': inputValue.length < 2 && required}]">
        <textarea @input="resize($event)" @focus="focused = true" @blur="focused = false" @change="changeInput(inputValue)" type="text" :maxlength="maxsymbols" :placeholder="placeholder" v-model="inputValue"></textarea>
        <span class="symbols">{{inputValue.length}}/{{maxsymbols}}</span>
    </div>
</template>

<script lang="ts">
import {defineComponent} from 'vue';

export default defineComponent({
    name: 'TextareaCount',
    emits: ['value'],
    components:{},
    props: {
        placeholder: String,
        maxsymbols: Number,
        value: String,
        inputClass: String,
        required: Boolean
    },
    data() {
        return {
            focused: false,
            inputValue: this.value ? this.value : '',
            heightTextarea: '28px',
        }
    },
    watch: {
        value(val) {
            this.inputValue = val;
        }
    },
    methods: {
        changeInput(value: string) {
            this.$emit('value', value)
        },

        resize(e:any){
            e.target.style.height = '28px';
            e.target.style.height = `${e.target.scrollHeight}px`;
        }
    }
});
</script>

<style lang="scss">
    
</style>