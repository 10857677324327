
import {defineComponent} from 'vue';
import {mapGetters} from 'vuex';
import { VueDraggableNext } from 'vue-draggable-next';
import InputSocialType from './InputSocialType.vue';
import Modal from '../Modal.vue';

export interface SocialItem {
  id: number;
  type: string;
  options: {
    favicon: string;
  };
  title: any;
  url: string;
  status: string;
  order: number;
}

export default defineComponent({
    name: 'SocialIcons',
    emits: ['socialLinks'],
    components:{
        draggable: VueDraggableNext,
        InputSocialType,
        Modal
    },
    props: {
        data : {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            socialItems: this.data as Array<SocialItem>,
            backStatus: false,
            showModal: false,
            search: '',
            editable: true,
            isDragging: false,
            delayedDragging: false
        }
    },

    watch: {
        data(val) {
            this.socialItems = val;
        },
        isDragging(newValue) {
            if (newValue) {
                this.delayedDragging = true;
                return;
            }
            this.$nextTick(() => {
                this.delayedDragging = false;
            });
        }
        
    },

    methods: {
        openModal() {
            this.showModal = true;
        },
        closeModal(status: boolean) {
            this.showModal = status;
        },
        addEvent (index: any) {
            let item = {
                "id": (new Date()).getTime(),
                "type": this.getConstants.pp_inner_links.type[1],
                "title": null,
                "url": "",
                "options": {
                    'favicon': index
                },
                "pp_tr_link_id": (new Date()).getTime(),
                "order": this.socialItems.length,
                "status": this.getConstants.common.status[1]
            };
            this.socialItems.push(item)
            this.saveData(this.socialItems);
            this.showModal = false;
        },
        deleteEvent: function(event: {'item': any, 'index': number}) {
            this.socialItems.splice(this.socialItems.indexOf(event.item), 1);
            this.saveData(this.socialItems);
        },
     
        draggableChange() {
            for (let i = 0; i < this.socialItems.length; i++) {
                this.socialItems[i].order = i;
            }
            
            this.saveData(this.socialItems);

        },

        setSocialInputValue(event: any) {
            this.socialItems[event.index].url = event.value;
            // this.socialItems[event.index].title = event.value;

            this.saveData(this.socialItems);

        },


        publishEvent(event: {'item': any, 'index': number}) {
            this.socialItems[event.index].status = event.item.status;
            this.saveData(this.socialItems);
        },
        
        backItem(){
            this.backStatus = true;
        },
         saveData(links:  Array<SocialItem>) {
            this.$emit('socialLinks', links)
        },
        
    },

    computed: {
        ...mapGetters(['getSocialsData', 'getConstants']),

        filteredList(): any {
            return  Object.fromEntries(Object.entries(this.getSocialsData).filter(([key]) => key.toLowerCase().includes(this.search.toLowerCase())));
        },
        dragOptions() {
            return {
                animation: 300,
                ghostClass: "ghost"
            };
        },
    }
});
