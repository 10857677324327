
import {defineComponent} from 'vue';
import {mapGetters} from 'vuex';

export default defineComponent({
    name: 'Dropdown',
    components:{},
    props: {
        selected: {
            type: String,
            default: ''
        },
        dropdownType: String,
        dataDropdown: {} as any
    },
    emits: ['changeValue'],
    data() {
        return {
            show: false,
            selectedValue: this.selected ? this.selected : '',
            data: this.dataDropdown ? this.dataDropdown : [],
            vcoConfig: {
                middleware: this.middleware,
                events: ['click'],
                // Note: The default value is true, but in case you want to activate / deactivate
                //       this directive dynamically use this attribute.
                isActive: true,
                // Note: The default value is true. See "Detecting Iframe Clicks" section
                //       to understand why this behaviour is behind a flag.
                detectIFrame: true,
                // Note: The default value is false. Sets the capture option for EventTarget addEventListener method.
                //       Could be useful if some event's handler calls stopPropagation method preventing event bubbling.
                capture: true
            }
        }
    },

    watch: {
        selected(val) {
            this.selectedValue = val;
        },
        dataDropdown(val) {
            this.data = val;
        },
    },
    
    computed: mapGetters(['getConstants']),

    methods: {
        changeType(item: string) {
            this.$emit('changeValue', item);
            this.selectedValue = item;
            this.show = false;
        },
        
        middleware (event:any) {
            if(event) {
                this.show = false;
            }
        },
    },

    created() {
        if(this.selectedValue == '') {
            this.$emit('changeValue', this.getConstants.common.domain_url_static.replace('https://', '').replace('http://', ''));
        }
    },
    
});
