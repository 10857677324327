
    import {defineComponent} from 'vue';
    import {mapGetters, mapActions} from 'vuex';
    import PxLifeMenuHelp from '@/components/common/PxLifeMenuHelp.vue';
    import LinkButtons from '@/components/common/LinkButtons/LinkButtons.vue';
    import SocialIcons from '@/components/common/SocialIcons/SocialIcons.vue';
    import ProfileCard from '@/components/common/Profile/ProfileCard.vue';
    import AppearanceTheme from '@/components/common/Appearance/AppearanceTheme.vue';
    import Header from '@/components/layout/Header.vue'; // @ is an alias to /src
    import Footer from '@/components/layout/Footer.vue';
    import ViewPage from '@/components/layout/ViewPage.vue';
    import VueQr3 from 'vue3-qr-code-styling';
    import Modal from '@/components/common/Modal.vue';
    import Analitics from '@/components/layout/Analitics.vue';
    import Skeleton from '../components/common/Skeleton.vue';
    import Select from '@/components/common/Select.vue';

    export default defineComponent({
        name: "Home",
        components: {
            PxLifeMenuHelp,
            LinkButtons,
            SocialIcons,
            ProfileCard,
            AppearanceTheme,
            Header,
            Footer,
            ViewPage,
            VueQr3,
            Modal,
            Analitics,
            Skeleton,
            Select
        },
        data() {
            return {
                currentTab : 0,
                qrLink: '',
                analiticsPeriod : 30,
                analiticsUnique: 1,
                showSidebar: true,
                showQr: false,
                previewMobile: false,
                updatePage: false,
                puplish: false,
                copiedLink: false,
                data: {} as any,
                socialLinksDb: {} as any,
                simpleLinksDb: {} as any,
                loading: true,
                loadBtn: false,
                copyText: 'Copy',
                sharing: {
                    title: 'Say hi to Vite! A brand new, extremely fast development setup for Vue.',
                    description: 'This week, I’d like to introduce you to "Vite", which means "Fast". It’s a brand new development setup created by Evan You.',
                    quote: 'The hot reload is so fast it\'s near instant. - Evan You',
                    hashtags: 'vuejs,vite,javascript',
                    twitterUser: 'youyuxi'
                },
                networks: [
                    { network: 'facebook', name: 'Share on Facebook', icon: '#fb', color: '#1877F2' },
                    { network: 'twitter', name: 'Share on Twitter', icon: '#twitter', color: '#1DA1F2' },
                    { network: 'linkedin', name: 'Share on LinkedIn', icon: '#linkedin', color: '#0A66C2' },
                    { network: 'telegram', name: 'Share via Telegram', icon: '#telegram', color: '#2AABEE ' },
                    { network: 'email', name: 'Share via Email', icon: '#email', color: '#111928' },
                    // { network: 'messenger', name: 'Messenger', icon: '#messenger', color: '#0084ff' },
                    // { network: 'whatsapp', name: 'Whatsapp', icon: '#whatsapp', color: '#25d366' },
                ],
                showModal: false,
                showAlert: false,

                dataGetAnalitics: {
                    pp_tr_link_id: '',
                    period: 30,
                    count_by: 'unique'
                },

                dataAnalitics: [] as any,
                selectDataType: [
                    {
                        value: 1,
                        title: 'Show unique activity only',
                    },
                    {
                        value: 0,
                        title: 'Show total all activity',
                    }
                ],
                selectDataPeriod: [
                    {
                        value: 7,
                        title: 'Last 7 days',
                    },
                    {
                        value: 14,
                        title: 'Last 14 days',
                    },
                    {
                        value: 30,
                        title: 'Last 30 days',
                    },
                    {
                        value: 356,
                        title: 'Show all',
                    }
                ]
            }
        },

        watch: {
            getAnalitic(val) {
                this.dataAnalitics = val;
            },

            allData(val) {
                this.simpleLinksDb = val.pp_inner_links.filter((link: { type: string; }) => {return link.type === 'simple'}).sort(function (a:any, b:any){
                    return a.order-b.order
                })
                this.socialLinksDb =  val.pp_inner_links.filter((link: { type: string; }) => {return link.type === 'social'}).sort(function (a:any, b:any){
                    return a.order-b.order
                })

                this.qrLink = `${this.getConstants.common.protocol}${val.domain}/${val.code}`;

                this.loading = false;

            },

            getUserInfo(val) {
                if(Object.keys(val).length > 0) {
                    this.fetchData();
                    this.mainMenu();
                    this.userMenu();
                    this.userDomain();
                    this.loadAnalitic(this.dataGetAnalitics)
                }
            },

            getToken(val) {
                if(val !== '') {
                    this.loginUser();
                }
                
                if(val !== '' && this.getError == 403 && this.updatePage) {
                    this.saveUpdate();
                }
            },

            getConstants(val) {
                this.validToken(val.common);
            },

            getError(val) {
                this.loadBtn = false;
                this.showAlert = true;

                // console.log('error', val)
                if(val == 403 || val == 401) {
                    this.refreshToken(this.getConstants.common);
                }


                if(Object.keys(val).length == 0) {
                    this.puplish = true;
                    this.updatePage = false;
                    this.previewMobile = false;

                    // new Promise( (resolve)=>{setTimeout(resolve, 3500) } )
                    //     .then( ()=> {
                    //     this.puplish = false;
                    // });

                }else{
                    this.puplish = false;
                }


                new Promise( (resolve)=>{setTimeout(resolve, 3500) } )
                    .then( ()=> {
                        this.showAlert = false;
                });
            }
        },

        computed: mapGetters(['allData', 'getToken', 'getUserInfo', 'getConstants', 'getError', 'getAnalitic']),

        methods: {
            ...mapActions([
                'validToken', 
                'loginUser', 
                'mainMenu', 
                'userMenu', 
                'getUserProfile', 
                'userDomain', 
                'fetchData', 
                'updatePPPages', 
                'constantsApi', 
                'refreshToken',
                'loadAnalitic'
            ]),

            updateLinks(links: any){
                this.updatePage = true;
                this.puplish = false;
                this.socialLinksDb =  this.allData.pp_inner_links.filter((link: { type: string; }) => {return link.type === 'social'}).sort(function (a:any, b:any){
                    return a.order-b.order
                });
                
                this.simpleLinksDb =  links.sort(function (a:any, b:any){
                    return a.order-b.order
                });
                this.allData.pp_inner_links = [];
                this.allData.pp_inner_links = [...this.simpleLinksDb, ...this.socialLinksDb];
            },

            updateSocialLinks(links: any) {
                this.updatePage = true;
                this.puplish = false;
                // this.allData.pp_inner_links = links;
                this.socialLinksDb =  links.sort(function (a:any, b:any){
                    return a.order-b.order
                });
                this.simpleLinksDb =  this.allData.pp_inner_links.filter((link: { type: string; }) => {return link.type === 'simple'}).sort(function (a:any, b:any){
                    return a.order-b.order
                });

                this.allData.pp_inner_links = [];
                this.allData.pp_inner_links = [...this.simpleLinksDb, ...this.socialLinksDb];
                // this.dataVal.social = links;
            },

            updateProfile(item: any) {
                this.updatePage = true;
                this.puplish = false;
                this.allData = item;
            },

            updateTheme(item: any) {
                this.updatePage = true;
                this.puplish = false;
                this.allData.customization.currentTheme = item;
            },

            updateThemeCustom(item: any) {
                this.updatePage = true;
                this.puplish = false;
                if(item.typeBg == 'solidColor') {
                    this.allData.customization.currentTheme.custom.typeBg = item.typeBg 
                    this.allData.customization.currentTheme.custom.style.bgColor = item.bgColor 
                }else if(item.typeBg == 'gradientTemplates'){
                    this.allData.customization.currentTheme.custom.typeBg = item.typeBg 
                    this.allData.customization.currentTheme.custom.style.gardientColor = item.gardientColor 
                }else{
                    this.allData.customization.currentTheme.custom.typeBg = item.typeBg 
                    this.allData.customization.currentTheme.custom.style.bgurl = item.bgurl 
                }
            },

            updateSidebar(e:boolean) {
                this.showSidebar = e;
            },
            
            closeQrModal(e: boolean) {
                this.showQr = e;
            },

            async copyLink() {
                this.copiedLink = true;
                this.copyText = 'Сopied';

                new Promise( (resolve)=>{setTimeout(resolve, 2000) } )
                    .then( ()=> {
                    this.copyText = 'Copy';
                    this.copiedLink = false;
                });
            },

            updateButton(item:any) {
                this.allData.customization.currentTheme.custom.class.btn.name = item.className;
                this.updatePage = true;
                this.puplish = false;
            },

            updateButtonColor(color: string) {
                this.updatePage = true;
                this.puplish = false;
                this.allData.customization.currentTheme.custom.style.buttonColor = color;
            },

            updateTitleColor(color: string) {
                this.updatePage = true;
                this.puplish = false;
                this.allData.customization.currentTheme.custom.style.titleColor = color;
            },

            updateSocialColor(color: string) {
                this.updatePage = true;
                this.puplish = false;
                this.allData.customization.currentTheme.custom.style.iconsColor = color;
            },

            updateFontSize(item: {'name': string, 'className': string}) {
                this.updatePage = true;
                this.puplish = false;
                this.allData.customization.currentTheme.custom.class.fontSize = item.className;
            },

            updateFontColor(color: string) {
                this.updatePage = true;
                this.puplish = false;
                this.allData.customization.currentTheme.custom.style.buttonTextColor = color;
            },

            updateFontFamily(className: string) {
                this.updatePage = true;
                this.puplish = false;
                this.allData.customization.currentTheme.custom.class.fontFamily = className;
            },

            showPreview() {
                // your code for handling resize...
                let widthWindow = window.innerWidth;
                if(widthWindow < 992) {
                    this.previewMobile = true;
                }
            },

            cancelUpdate() {
                this.fetchData();
                this.updatePage = false;
            },

            saveUpdate() {
                this.loadBtn = true;
                this.updatePPPages(this.allData);
            },

            closePreview() {
                this.previewMobile = false;
            },


            closeModal(e: boolean) {
                this.showModal = e;
            },

            onChangeSelectUnique(event: { title: string; value: number; }) {
                this.analiticsUnique = Number(event.value);
                this.dataGetAnalitics.count_by = this.analiticsUnique ? 'unique' : 'total';
                this.loadAnalitic(this.dataGetAnalitics)
            },

            onChangeSelectPeriod(event: { title: string; value: number; }) {
                this.analiticsPeriod = Number(event.value);
                this.dataGetAnalitics.period = this.analiticsPeriod;
                this.loadAnalitic(this.dataGetAnalitics)
            }

        },
        created() {
            this.constantsApi();
        }
        
    });

