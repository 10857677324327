<template>
    <div class="item-block">
        <div class="links-item"  >
            <div class="drag-drop">
                <i class="icon-dots"></i>
            </div>
            <div class="links-content">
                <div class="links-flex">
                    <div class="input-lib social-type" :class="[{'focus': focused}, {'is-invalid': errorValue && inputValue.length >= 1}]">
                        <div class="link-social">
                            <div class="social-url">
                                <svg class="icon">
                                    <use :xlink:href="`${getSocialsData[dataSocial.options?.favicon]?.icon}`"></use>
                                </svg>
                            </div>
                        </div>
                        <input @focus="focused = true" @blur="focused = false" type="text" :placeholder="`Enter ${getSocialsData[dataSocial.options?.favicon]?.name} link`" v-model="inputValue"  @change="changeInput(inputValue, dataSocial)">
                    </div>
                    <div class="social-menu">
                        <label :for="`checkbox-swsocial_${dataSocial?.id}`" class="switch-lib">
                            <input :id="`checkbox-swsocial_${dataSocial?.id}`" type="checkbox" name="deeplinks" 
                                :class="{'is-invalid': (errorValue && inputValue.length >= 1 || !getUrlDomain(inputValue) && dataSocial.options.favicon !== 'email') ||
                                (errorValue && inputValue.length >= 8 || !validateEmail(inputValue) && dataSocial.options.favicon === 'email') 
                                        }" 
                                class="switch-input" v-model="check"  @change="publishEvent(dataSocial, index)">
                            <span class="switch-lib-slider"></span>
                        </label>
                        <i @click="confirm = !confirm"  class="icon-delete">
                            <svg class="icon">
                                <use xlink:href="#trash"></use>
                            </svg>
                        </i>
                    </div>
                </div>

                <div v-if="errorValue && inputValue.length >= 1 && dataSocial.options.favicon !== 'email'" class="error-mess">The URL is not valid.</div>
                <div v-if="errorValue && inputValue.length >= 1 && dataSocial.options.favicon === 'email'" class="error-mess">The Email is not valid.</div>

                
                <Transition name="nested" >
                    <div class="confirm" v-if="confirm">
                        <div class="confirm-item">
                            <div class="confirm-title">Delete this link forever? </div>
                            <p class="confirm-subtitle">This action will also delete all the statistics for this link.</p>
                        </div>
                        <div class="confirm-btn">
                            <button  @click="confirm = !confirm" class="btn-lib btn-lib-sm btn-lib-secondary">
                                Cancel
                            </button>
                            <button @click="deleteEvent(dataSocial, index)" class="btn-lib btn-lib-sm btn-lib-dark">
                                Delete
                            </button>
                        </div>
                    </div>
                </Transition>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import {defineComponent} from 'vue';
import {mapGetters} from 'vuex';


export default defineComponent({
    name: 'InputSocialType',
    components:{},
    emits: ['value', 'publishEvent', 'deleteEvent', 'backItem'],
    props: {
        index: Number as any,
        data: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            focused: false,
            backValue: {} as any,
            inputValue: this.data.url || "",
            dataSocial: this.data,
            confirm: false,
            // check: this.data.status === 'active' ? true : false,
            check:  true,
            errorValue: false, 
        }
    }, 

    watch: {
        data(val) {
            this.dataSocial = val;
        },
        inputValue(val){
            if((val.includes('@') && val.length > 4 && val.includes('.') )|| (val.length > 4 && this.getUrlDomain(val))) {
                this.errorValue = false;
            }
        }
    },

    computed: mapGetters(['getSocialsData', 'getConstants', 'getDomain']),

    methods: {
        getNickname(val: string ){
            let value = val.split('/').filter(Boolean);
            let nicknameValue = value[value.length - 1];
            return nicknameValue;
        },

        changeInput(inputValue:string, event: any) {

            /** if(event.options.favicon == 'facebook') {
                this.inputValue = `https://www.facebook.com/${this.getNickname(inputValue)}`;
            }else if(event.options.favicon == 'instagram') {
                this.inputValue = `https://www.instagram.com/${this.getNickname(inputValue)}`;
            }else  */

            if(!inputValue.includes('http') && inputValue.length > 0 && event.options.favicon !== 'email') {
                this.inputValue = `https://${inputValue}`;
                
                inputValue = `https://${inputValue}`;
            }

            if((inputValue.length > 4 && this.getUrlDomain(inputValue) ) || (inputValue.includes('@') && inputValue.length > 4 && inputValue.includes('.'))) {
                this.errorValue = false;
            }else {
                this.errorValue = true;
            }

            

            if(event.options.favicon == 'email' && !inputValue.includes('mailto:')) {
                this.inputValue = `mailto:${inputValue}`;
            }else{
                this.inputValue = inputValue;
            }
            

            this.$emit('value', {
                'index': this.index,
                'value': this.inputValue
            });

            this.publishEvent(event, this.index)
            
        },

        deleteEvent: function(event: any, index: number) {
            this.$emit('deleteEvent', {
                'item': event,
                'index': index
            })
        },

        publishEvent(event: any, index: number) {

            if((event.url.length > 4 && this.getUrlDomain(event.url)) || (event.url.includes('@') && event.url.length > 4 && event.url.includes('.'))) {
                this.errorValue = false;
            }else {
                this.errorValue = true;
            }

            
            if(this.check && !this.errorValue) {
                event.status = this.getConstants.common.status[0]
            }else{
                event.status = this.getConstants.common.status[1]
            }


            this.$emit('publishEvent', {
                'item': event,
                'index': index
            })
        },
        backItem(){
            this.$emit('backItem', this.backValue);
            this.backValue = {};
        },

        getUrlDomain(url: any) {
            if(url.includes('http')) {
                let hostUrl = new URL(String(url));
            
                return  this.getDomain.indexOf('.'+(hostUrl.host.split('.').filter(Boolean))[hostUrl.host.split('.').filter(Boolean).length-1]) != -1;
            }

            return false;
        },

        validateEmail(email: string) {
            let re = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
            return re.test(String(email).toLowerCase());
        }
    },
   
});
</script>

<style lang="scss">
    @media screen and (max-width: 991px) {
        .hide-mob {
            display: none !important;
        }
    }

    .link-social {
        font-family: 'Lato';
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 28px;

    }

    .social-url {
        display: flex;
        align-items: center;
        .icon {
            width: 24px;
            height: 24px;
            margin-right: 10px;
        }
    }

    .nested-enter-active, .nested-leave-active {
        transition: all 0.3s ease-in-out;
    }
    /* delay leave of parent element */
    .nested-leave-active {
    transition-delay: 0.25s;
    }

    .nested-enter-from,
    .nested-leave-to {
    transform: translateY(30px);
    opacity: 0;
    }


    .selected-icon {
        color: #000000 !important;
    }

    .confirm {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        margin: 10px -12px 0;
        width: 100%;

        &-item {
            max-width: 360px;
            padding: 0 12px;
            margin: 10px 0;
        }

        &-title {
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 1.2;
            letter-spacing: -0.2px;
            color: #292D34;
        }
        
        &-subtitle {
            font-family: 'Lato';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 1.2;
            color: #50545B;
        }

        &-btn {
            display: flex;
            align-items: center;
            padding: 0 12px;
            margin: 10px 0;

            button {
                width: 90px !important;
                &:first-child {
                    margin-right: 12px;
                }
            }
        }
    }

</style>