<template>
    <Skeleton v-if="load"  style="  min-height: 42px; margin-bottom: 1rem; display: block; overflow: hidden;" />
    <div v-else class="input-lib"  :class="[{'focus': focused}, {'is-invalid': inputValue.length < 6}]">
        <Dropdown @changeValue="changeDropdown" :selected="selectedDropdown" :dataDropdown="getUserDomain" dropdownType="domain" />
        <span class="ml-[10px]">/</span>
        <input class="ml-[10px]" type="text" @focus="focused = true" @blur="focused = false" placeholder="mypage" v-model="inputValue"  @change="changeInput(inputValue)">
    </div>
    <div v-if="inputValue.length == 0" class="error-mess mb-3">The username is required.</div>
    <div v-else-if="inputValue.length < 6" class="error-mess mb-3">The username is too short.</div>
</template>

<script lang="ts">
import {defineComponent} from 'vue';
import Dropdown from '../Dropdown.vue';
import {mapGetters} from 'vuex';
import Skeleton from '../Skeleton.vue';

export default defineComponent({
    name: 'ProfileChangeDomain',
    components:{ Dropdown, Skeleton },
    emits: ['value', 'changeDD'],
    props: {
        domain: String,
        value: String,
    },
    data() {
        return {
            focused: false,
            inputValue: this.value ? this.value : "",
            selectedDropdown: this.domain || '',
            load: true
        }
    }, 

    watch: {
        value(val) {
            this.inputValue = val.replace(/[^a-zA-ZА0-9]/g, '');
        },

        domain(val) {
            this.selectedDropdown = val;
        },

    },

    computed: mapGetters(['getUserDomain', 'getConstants', 'getUserInfo']),

    methods: {
        changeInput(inputValue:string) {
            let validationInputValue = inputValue.replace(/[^a-zA-ZА0-9]/g, '')
            this.$emit('value', validationInputValue);
        },

        changeDropdown(item: string) {
            this.$emit('changeDD', item);

        }
    },

    created() {
        this.load = false;

        if(this.inputValue == '') {
            this.$emit('value', this.getUserInfo.name.replace(/[^a-zA-ZА0-9]/g, ''));
        }
    },

});
</script>

<style lang="scss">
  .profile-info .input-lib {
    .ml-\[10px\] {
        margin-left: 10px;
    }

    &.is-invalid {
        margin-bottom: 0;
    }
  }

  .mb-3 {
    margin-bottom: 1rem;
  }
</style>