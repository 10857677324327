
import {defineComponent} from 'vue';
import Dropdown from '../Dropdown.vue';
import {mapGetters} from 'vuex';
import Skeleton from '../Skeleton.vue';

export default defineComponent({
    name: 'ProfileChangeDomain',
    components:{ Dropdown, Skeleton },
    emits: ['value', 'changeDD'],
    props: {
        domain: String,
        value: String,
    },
    data() {
        return {
            focused: false,
            inputValue: this.value ? this.value : "",
            selectedDropdown: this.domain || '',
            load: true
        }
    }, 

    watch: {
        value(val) {
            this.inputValue = val.replace(/[^a-zA-ZА0-9]/g, '');
        },

        domain(val) {
            this.selectedDropdown = val;
        },

    },

    computed: mapGetters(['getUserDomain', 'getConstants', 'getUserInfo']),

    methods: {
        changeInput(inputValue:string) {
            let validationInputValue = inputValue.replace(/[^a-zA-ZА0-9]/g, '')
            this.$emit('value', validationInputValue);
        },

        changeDropdown(item: string) {
            this.$emit('changeDD', item);

        }
    },

    created() {
        this.load = false;

        if(this.inputValue == '') {
            this.$emit('value', this.getUserInfo.name.replace(/[^a-zA-ZА0-9]/g, ''));
        }
    },

});
