
import * as icons from 'vue-tabler-icons';
import { defineComponent} from 'vue';
import {mapGetters} from 'vuex';

export default defineComponent({
    name: 'LinkButtonsItem',
    components:{},
    emits: ['openModal', 'setLinkName', 'publishEvent', 'setLinkUrl', 'deleteEvent'],
    props: {
        data : {
            type: Object as any,
            required: true
        },
        index: Number as any
    },

    data() {
        return {
            linksItems: this.data as any,
            dragging: false,
            tabModal: 0,
            color: '#5B60CA',
            strokeWidth: '2',
            limit: 40,
            selectIndex: 0,
            confirm: false,
            check: this.data.status === 'active' ? true : false,
            // check: true ,
            linkName: this.data.title || '',
            linkUrl: this.data.url || '',
            validation: {
                'linkName': true,
                'linkUrl': true,
                'errorMess': {
                    'linkName' : 'The Link Title is too short.',
                    'linkUrl' : 'The URL is not valid.'
                }
            },
            focused: false,
        }
    },

    watch: {
        linkName(val) {
            this.linksItems.title = val;
        },

        linkUrl(val) {
            this.linksItems.url = val;
        },

        data(val) {
            if(val.status === this.getConstants.common.status[0]) {
                this.check = true;
            }
        },

        linksItems(val) {

            if(val.url.length > 4  && val.url.includes('.') && this.getUrlDomain(val.url)) {
                this.validation.linkUrl = true;
            }else{
                this.validation.linkUrl = false;
            }

            
        }
    },

    methods : {
        confirmChange() {
            this.confirm = !this.confirm;
        },

        publishEvent(event: any, index: number) {

            if(event.url?.length > 4  && this.getUrlDomain(event.url)) {
                this.validation.linkUrl = true;
            }else{
                this.validation.linkUrl = false;
            }


            if(this.check && this.validation.linkUrl) {
                event.status = this.getConstants.common.status[0]
            }else{
                event.status = this.getConstants.common.status[1]
            }

            console.log('publishEvent',event)

            
            this.$emit('publishEvent', {
                'item': event,
                'index': index
            })
        },

        setLinkUrl(event: any, index: number) {

            if(!event.url.includes('http') && event.url.length > 0) {
                this.linkUrl = `https://${event.url}`;
                event.url = `https://${event.url}`;
            }


            
            if(event.url.length > 4 && event.url.includes('.') && this.getUrlDomain(event.url)) {
                this.validation.linkUrl = true;             
            }else{
                this.validation.linkUrl = false;
            }

            this.publishEvent(event, index);

            this.$emit('setLinkUrl', {
                'item': event,
                'index': index
            })


        },        

        setLinkName(event: any, index: number) {


            if(event.url?.length > 4  && this.getUrlDomain(event.url)) {
                this.validation.linkUrl = true;
            }else{
                this.validation.linkUrl = false;
            }

       

            this.$emit('setLinkName', {
                'item': event,
                'index': index
            })
        },
     
        deleteEvent: function(event: any, index:number) {

            this.$emit('deleteEvent', {
                'item': event,
                'index': index
            })

           
        },

        openModal(index: number) {
            this.$emit('openModal', index)
        },

        getUrlDomain(url: any) {
            if(url.includes('http')) {
                let hostUrl = new URL(String(url));
            
                return  this.getDomain.indexOf('.'+(hostUrl.host.split('.').filter(Boolean))[hostUrl.host.split('.').filter(Boolean).length-1]) != -1;
            }

            return false;
        },


    },
    computed : {
        ...mapGetters(['getConstants', 'getDomain']),

        icons() {
            return icons;
        },
    },
    
});
