
import {defineComponent} from 'vue';
import {mapGetters} from 'vuex';
import Skeleton from '../common/Skeleton.vue';

export default defineComponent({
    name: 'UserMenu',
    components:{ Skeleton },
    data() {
        return {
            openUserMenu : false,
            loading: true,
            copiedLink: false,
            vcoConfig: {
                middleware: this.middleware,
                events: ['click'],
                // Note: The default value is true, but in case you want to activate / deactivate
                //       this directive dynamically use this attribute.
                isActive: true,
                // Note: The default value is true. See "Detecting Iframe Clicks" section
                //       to understand why this behaviour is behind a flag.
                detectIFrame: true,
                // Note: The default value is false. Sets the capture option for EventTarget addEventListener method.
                //       Could be useful if some event's handler calls stopPropagation method preventing event bubbling.
                capture: true
            },
        }
    },

    watch: {
        getUserMenu(val) {
            if(val.menu.sign_out) {
                this.loading = false;
            }
        },

    },


    methods: {

        async copyLink() {
            this.copiedLink = true;
            new Promise( (resolve)=>{setTimeout(resolve, 2000) } )
                .then( ()=> {
                this.copiedLink = false;
            });
        },

        middleware (event:any) {
            if(event) {
                this.openUserMenu = false;
            }
        },

    },


    computed: {
        ...mapGetters(['getUserInfo', 'getUserMenu', 'getConstants']),
    }

});
