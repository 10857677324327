<template>
        <div class="container">
            <input class="input-lib" type="email" name="email" v-model="data.email">
            <input class="input-lib" type="text" name="pass" v-model="data.pass">
            <button @click="tokenUser(data)" class="btn-lib btn-lib-primary">submit</button>

            {{data.email}}
            {{data.pass}}
        </div>
</template>

<script lang="ts">
import {defineComponent} from 'vue';
import {mapGetters, mapActions} from 'vuex';

export default defineComponent({
    name: "Login",
    components: {  },
    data() {
        return {
            data: {
                email: '1@pixelfy.me',
                pass: 'testtest'
            }
        }
    },
    computed: mapGetters(['getToken']),
    methods: {
        ...mapActions(['tokenUser']),
    }
});
</script>

<style scoped lang="scss">

</style>