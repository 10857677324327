<template>
    <div :class="data.customization.currentTheme.cssClass" 
        :style="{
            background: (data.customization.currentTheme.custom.typeBg == 'solidColor') ?  data.customization.currentTheme.custom.style.bgColor : 
                        (data.customization.currentTheme.custom.typeBg == 'gradientTemplates') ? 'linear-gradient(0deg, '+data.customization.currentTheme.custom.style.gardientColor.bottom+' 0%, '+data.customization.currentTheme.custom.style.gardientColor.top+' 100%)' : 
                        (data.customization.currentTheme.custom.typeBg == 'imageTemplates') ? 'url('+(data.customization.currentTheme.custom.style.bgurl ? data.customization.currentTheme.custom.style.bgurl : data.options?.bg_url )+')' : '',  backgroundSize: 'cover', backgroundPosition: 'center'
        }" 
        class="preview-mobile">
        <div class="mobile-content">
            <a href="/" class="footer-logo"><svg :style="{color: data.customization.currentTheme.custom.style.iconsColor}" class="icon"><use xlink:href="#logo-pp"></use></svg></a>
            <div class="my-auto position-relative"> 
                <button @click="showModal = !showModal" class="btn-share"
                    :style="{background: 
                        ((data.customization.currentTheme.custom.class.btn.name.includes('btn-square') || 
                        data.customization.currentTheme.custom.class.btn.name.includes('btn-rounded') || 
                        data.customization.currentTheme.custom.class.btn.name.includes('btn-regular')) && !data.customization.currentTheme.custom.class.btn.name.includes('outline')) ? data.customization.currentTheme.custom.style.buttonColor : 'none',

                            color: data.customization.currentTheme.custom.style.buttonTextColor,
                            
                            border: (data.customization.currentTheme.custom.class.btn.name.includes('outline')) ? `1px solid ${data.customization.currentTheme.custom.style.buttonColor}` : 'none'}"
                ><svg class="icon"><use xlink:href="#sh-btn"></use></svg></button>
                <div class="photo" :style="{borderColor:  data.customization.currentTheme.custom.style.titleColor , borderWidth: '3px', borderStyle: !data.options?.avatar_image ? 'dashed' : 'solid'}">
                    <img v-if="data.options?.avatar_image"  :src="data.options?.avatar_image" onerror="this.src = 'data:image/svg+xml;base64,PHN2ZyBmaWxsPSJyZWQiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgdmlld0JveD0iMCAwIDExNS4xOSAxMjMuMzgiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDExNS4xOSAxMjMuMzgiIHhtbDpzcGFjZT0icHJlc2VydmUiPjxwYXRoIGNsYXNzPSJzdDAiIGQ9Ik05My4xMyA3OS41YzEyLjA1IDAgMjEuODIgOS43NyAyMS44MiAyMS44MnMtOS43NyAyMS44Mi0yMS44MiAyMS44Mi0yMS44Mi05Ljc3LTIxLjgyLTIxLjgyUzgxLjA4IDc5LjUgOTMuMTMgNzkuNXpNOC4wOC4yNWg5NS4yOGMyLjE3IDAgNC4xMS44OSA1LjUzIDIuM2E3LjgyOCA3LjgyOCAwIDAgMSAyLjMgNS41M3Y3MC4wMWEyOC43OTcgMjguNzk3IDAgMCAwLTguMjUtNC40OFY5Ljk4YzAtLjQzLS4xNi0uNzktLjQ2LTEuMDUtLjI2LS4yNi0uNjYtLjQ2LTEuMDUtLjQ2SDkuOTRjLS40MyAwLS43OS4xNi0xLjA1LjQ2LS4yNi4yNi0uNDYuNjUtLjQ2IDEuMDVWODBoLjAzbDMxLjk3LTMwLjYxYzEuMjgtMS4xOCAzLjI5LTEuMDUgNC40NC4yMy4wMy4wMy4wMy4wNy4wNy4wN2wyNi44OCAzMS44YTI5LjA1MyAyOS4wNTMgMCAwIDAtNy42MiAxOS42NWMwIDMuMjkuNTUgNi40NSAxLjU1IDkuNEg4LjA4Yy0yLjE3IDAtNC4xMS0uODktNS41My0yLjNzLTIuMy0zLjM5LTIuMy01LjUzVjguMDhjMC0yLjE3Ljg5LTQuMTEgMi4zLTUuNTNTNS45NC4yNSA4LjA4LjI1em02NS45IDc5LjEgMy43MS0yMi43OWMuMy0xLjcxIDEuOTEtMi45IDMuNjItMi42LjY2LjEgMS4yNS40MyAxLjcxLjg2bDE3LjEgMTcuOTdjLTIuMTgtLjUyLTQuNDQtLjc5LTYuNzgtLjc5YTI4Ljk3NCAyOC45NzQgMCAwIDAtMTkuMzYgNy4zNXptOC02MS4xNmMzLjEzIDAgNS45OSAxLjI4IDguMDMgMy4zMmExMS4yOSAxMS4yOSAwIDAgMSAzLjMyIDguMDNjMCAzLjEzLTEuMjggNS45OS0zLjMyIDguMDNhMTEuMjkgMTEuMjkgMCAwIDEtOC4wMyAzLjMyYy0zLjEzIDAtNS45OS0xLjI4LTguMDMtMy4zMmExMS4yOSAxMS4yOSAwIDAgMS0zLjMyLTguMDNjMC0zLjEzIDEuMjgtNS45OSAzLjMyLTguMDMgMi4wNy0yLjA3IDQuOTEtMy4zMiA4LjAzLTMuMzJ6bTMuODQgNjkuODYgMTkuOTYgMjEuNmMxLjU4LTIuMzkgMi41LTUuMjUgMi41LTguMzMgMC04LjM2LTYuNzgtMTUuMTQtMTUuMTQtMTUuMTQtMi42Ni0uMDEtNS4xNS42Ny03LjMyIDEuODd6bTE0LjYyIDI2LjUzLTE5Ljk2LTIxLjZjLTEuNTggMi4zOS0yLjUgNS4yNS0yLjUgOC4zMyAwIDguMzYgNi43OCAxNS4xNCAxNS4xNCAxNS4xNCAyLjY2LjAxIDUuMTUtLjY3IDcuMzItMS44N3oiLz48L3N2Zz4='" alt="">
                </div>

                <div class="nickname"
                    :class="[data.customization.currentTheme.custom.class.fontSize,
                            data.customization.currentTheme.custom.class.fontFamily
                    ]"
                    :style="{
                        color: data.customization.currentTheme.custom.style.titleColor
                    }">
                    <span>{{data.title}}</span>
                    <div class="nickname-subtitle">{{data.desc}}</div>
                </div>
                
                <transition-group name="switch">
                    <div v-for="item in simpleLink" :key="item">

                        <a @click="updateLinkAnalitic(item.id)" 
                            :href="item.url" 
                            v-if="item.status === 'active' && 
                                item.type === 'simple' && 
                                item.url.length > 4 && 
                                getUrlDomain(item.url)"
                                
                            class="mobile-btn " 
                            target="_blank"  
                            :class="[{'empty': !item.title}, 
                                    data.customization.currentTheme.custom.class.fontSize,
                                    data.customization.currentTheme.custom.class.fontFamily, 
                                    data.customization.currentTheme.custom.class.btn.name
                            ]" 
                            
                            :style="{
                                background: data.customization.currentTheme.custom.style.buttonColor,
                                color: data.customization.currentTheme.custom.style.buttonTextColor,
                                borderColor: data.customization.currentTheme.custom.style.buttonColor
                            }">

                            

                            <component v-if="icons[item.options.favicon] && item.options.favicon !== 'PhotoIcon'"
                                size="24"
                                :stroke-width="strokeWidth"
                                :is="icons[item.options.favicon]"
                                :style="{ colorIcon }"
                            />
                            <img v-else-if="!icons[item.options.favicon] && item.options.favicon" :src="item.options.favicon" alt="">
                            <span class="text">
                                {{item.title}}

                                <template v-if="!item.title">
                                    Link title
                                </template>
                            </span>

                        </a>
                    </div>
                </transition-group>
                
            </div>
            <ul class="mobile-social">
                <transition-group name="switch">
                    <li  v-for="item in socialLink" :key="item.id">
                        <a v-if="(item.status === 'active' && item.type === 'social' && item.url.length > 4 && getUrlDomain(item.url) && item.options.favicon !== 'email') || (item.status === 'active' && item.type === 'social' && item.url.length > 4 && item.options.favicon === 'email' )"
                            :style="{color: data.customization.currentTheme.custom.style.iconsColor}" 
                            :href="item.url" 
                            target="_blank"
                            @click="updateLinkAnalitic(item.id)">
                            <svg class="icon"><use :xlink:href="getSocialsData[item.options.favicon]?.icon"></use></svg>
                        </a>
                    </li>
                </transition-group>
            </ul>
        </div>
        
    </div>

    <Modal v-if="showShareModal"
        :show="showModal" 
        @close="closeModal" 
        modalTitle="Share this PixelPass"
        type="share">
            <ShareNetwork
                v-for="network in networks"
                class="sharing-item"
                :network="network.network"
                :key="network.network"
                :url="`https://${data.domain}/${data.code}`"
                :title="data.title"
                :description="data.desc"
                :quote="sharing.quote"
                :hashtags="sharing.hashtags"
                :twitterUser="sharing.twitterUser"
            >
                <svg :style="'color:'+network.color" class="icon"><use :xlink:href="network.icon"></use></svg>
                <span>{{ network.name }}</span>
                <svg class="icon new-page"><use xlink:href="#new-page"></use></svg>
            </ShareNetwork>
            <div class="sharing-copy">
                <input type="text" :value="`https://${data.domain}/${data.code}`" disabled>
                <button v-clipboard:copy="`https://${data.domain}/${data.code}`" v-clipboard:success="copyLink" >{{copyText}} </button>
            </div>
    
    </Modal>
</template>

<script lang="ts">
import {defineComponent} from 'vue';
import {mapGetters, mapActions} from 'vuex';
import Modal from '../common/Modal.vue';
import * as icons from 'vue-tabler-icons';

export default defineComponent({
  components: { Modal },
    name: 'ViewPage',
    props: {
        simpleLink: Object as any,
        dataTheme: Object as any,
        socialLink: Object as any,
        showShareModal: Boolean,
    },
    data() {
        return {
            data: this.dataTheme as any,
            showModal: false,
            colorIcon: this.dataTheme?.customization.currentTheme.custom.style.buttonTextColor,
            strokeWidth: '2',
            copyText: 'Copy',
            sharing: {
                title: 'Say hi to Vite! A brand new, extremely fast development setup for Vue.',
                description: 'This week, I’d like to introduce you to "Vite", which means "Fast". It’s a brand new development setup created by Evan You.',
                quote: 'The hot reload is so fast it\'s near instant. - Evan You',
                hashtags: 'vuejs,vite,javascript',
                twitterUser: 'youyuxi'
            },
            networks: [
                { network: 'facebook', name: 'Share on Facebook', icon: '#fb', color: '#1877F2' },
                { network: 'twitter', name: 'Share on Twitter', icon: '#twitter', color: '#1DA1F2' },
                { network: 'linkedin', name: 'Share on LinkedIn', icon: '#linkedin', color: '#0A66C2' },
                { network: 'telegram', name: 'Share via Telegram', icon: '#telegram', color: '#2AABEE ' },
                { network: 'email', name: 'Share via Email', icon: '#email', color: '#111928' },
                // { network: 'messenger', name: 'Messenger', icon: '#messenger', color: '#0084ff' },
                // { network: 'whatsapp', name: 'Whatsapp', icon: '#whatsapp', color: '#25d366' },
            ]
        }
    },

    watch: {
        dataTheme(val) {
            this.data = val;
        }
    },

    methods: {
        ...mapActions(['updateLinkAnalitic']),

        closeModal(e: boolean) {
            this.showModal = e;
        },
        async copyLink() {
            this.copyText = 'Сopied';

            new Promise( (resolve)=>{setTimeout(resolve, 1000) } )
            .then( ()=> {
                this.copyText = 'Copy';
            });
        },

        getUrlDomain(url: any) {
            if(url.includes('http')) {
                let hostUrl = new URL(String(url));
            
                return  this.getDomain.indexOf('.'+(hostUrl.host.split('.').filter(Boolean))[hostUrl.host.split('.').filter(Boolean).length-1]) != -1;
            }

            return false;

            
        }
    },
    computed : {
        ...mapGetters(['getSocialsData', 'getDomain']),
        
        icons() {
            return icons;
        },
        
    },
});
</script>

<style scoped lang="scss">
    .nickname-subtitle {
        opacity: .6;
    }

    .position-relative {
        position: relative;
    }
    .my-auto {
        margin-top: auto;
        margin-bottom: auto;
    }

</style>