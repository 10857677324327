<template>
    <div class="analitics-container">
        <h6 class="h6-lib ff-Poppins font-weight-600 normal-margin">Top platforms</h6>
        <!-- <img src="@/assets/images/group7218.png" alt="" class="w-100"> -->
        <div v-if="loaded" class="chart-percent" :class="{'d-none' : Object.values(dataChart).length === 0}" ref="chartdiv1"></div>
        <Skeleton v-else class="chart-percent d-block" />
        <img v-if="Object.values(dataChart).length === 0" src="@/assets/images/no-data.svg" alt="Empty data" class="w-100 no-data" />
    </div>
</template>

<script lang="ts">
import {defineComponent} from 'vue';
import * as am5 from '@amcharts/amcharts5';
import * as am5percent from "@amcharts/amcharts5/percent";
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import am5themes_Responsive from "@amcharts/amcharts5/themes/Responsive";
import Skeleton from '../Skeleton.vue';

export default defineComponent({
    name: 'AnaliticsTopPlatforms',
    components:{ Skeleton },
    props: ['data', 'chartUnique'],
    data() {
        return {
            root: '' as any,
            dataChart: this.data || [],
            unique: this.chartUnique,
            loaded: false,
        }
    },

    watch: {
        data(val) {
            this.dataChart = val;
            this.reloadChart();
        },

        chartUnique(val){
            this.unique = val;
            this.reloadChart();
        }
    },

    methods: {
        reloadChart(){
            this.loaded = false;
            this.root.dispose();

            setTimeout(() =>{
                this.loaded = true;
                setTimeout(() =>{
                    this.loadChart(this.dataChart);
                }, 200)
            }, 500)
        },

       loadChart($data: any)  {
            // Set themes
            // https://www.amcharts.com/docs/v5/getting-started/#Root_element
            let chartTwo = am5.Root.new((this.$refs.chartdiv1 as HTMLInputElement));

            // Set themes
            // https://www.amcharts.com/docs/v5/concepts/themes/
            chartTwo.setThemes([
                am5themes_Animated.new(chartTwo)
            ]);

            let responsive = am5themes_Responsive.newEmpty(chartTwo);

            responsive.addRule({
            relevant: am5themes_Responsive.widthM,
                applying: function() {
                    chartR2.set("layout", chartTwo.verticalLayout);
                    legend.setAll({
                    y: null,
                    // centerY: null,
                    x: am5.p0,
                    centerX: am5.p0
                    });
                },
                removing: function() {
                    chartR2.set("layout", chartTwo.horizontalLayout);
                    legend.setAll({
                    y: am5.p50,
                    centerY: am5.p50,
                    x: null,
                    // centerX: null
                    });
                }
            });

            chartTwo.setThemes([
                am5themes_Animated.new(chartTwo),
                responsive
            ]);


            // Create chart
            // https://www.amcharts.com/docs/v5/charts/percent-charts/pie-chart/
            let chartR2 = chartTwo.container.children.push(am5percent.PieChart.new(chartTwo, {
                layout: chartTwo.horizontalLayout,
                innerRadius: am5.percent(50)
            }));


            // Create series
            // https://www.amcharts.com/docs/v5/charts/percent-charts/pie-chart/#Series
            let series = chartR2.series.push(am5percent.PieSeries.new(chartTwo, {
                valueField: this.unique ? 'unique_value' : 'total_value',
                categoryField: "title",
                fillField: "hex_color",
                alignLabels: false
            }));

            series.labels.template.setAll({
                text: "{title}",
                textType: "circular",
                centerX: 0,
                centerY: 0
            });

            // Disabling labels and ticks
            series.labels.template.set("visible", false);
            series.ticks.template.set("visible", false);


            // Set data
            //   let data = [
            //         { value: 10, category: "iOS", color: am5.color(0xBF66DD) },
            //         { value: 9, category: "Mac OS", color: am5.color(0xD665D1) },
            //         { value: 6, category: "Android", color: am5.color(0x9E67DC) },
            //         { value: 5, category: "Windows mobile", color: am5.color(0x7C66DC) },
            //         { value: 4, category: "Other", color: am5.color(0x7B85DF) }
            //     ];

            let data = $data;

            // https://www.amcharts.com/docs/v5/charts/percent-charts/pie-chart/#Setting_data
            series.data.setAll(data);


            // Create legend
            // https://www.amcharts.com/docs/v5/charts/percent-charts/legend-percent-series/
            let legend = chartR2.children.push(
                am5.Legend.new(chartTwo, {
                    centerY: am5.percent(50),
                    y: am5.percent(50),
                    layout: chartTwo.verticalLayout
            }));

            legend.markers.template.setAll({
                width: 14,
                height: 14
            });

            legend.markerRectangles.template.setAll({
                cornerRadiusTL: 10,
                cornerRadiusTR: 10,
                cornerRadiusBL: 10,
                cornerRadiusBR: 10
            });

            legend.labels.template.setAll({
                fontSize: 16,
                marginBottom: 29
            });

            legend.data.setAll(series.dataItems);


            // Play initial series animation
            // https://www.amcharts.com/docs/v5/concepts/animations/#Animation_of_series
            series.appear(1000, 100);



        
            //END Set themes
            this.root = chartTwo;
       }
    },
    mounted() {
        // < !--Chart code-- >
        am5.addLicense("AM5C335843595");
        am5.addLicense("AM5M335847822");
        this.loadChart(this.dataChart);
    },
    created() {
        this.loaded = true;
    }
    
});
</script>

<style lang="scss">
  .chart-percent {
    min-height: 230px;
    width: 100%;
  }
</style>