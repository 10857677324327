<template>
    <div class="dropdown dropdown-multiple" v-click-outside="vcoConfig">
        <button class="select-lib w-100" @click="show = !show">
            <template v-if="selectedItems.length > 0" >
                <span v-for="(item, index) in selectedItems" :key="index">{{dropdownItems?.filter(el => el.id === item)[0]?.name}}; </span>
            </template>
            <template v-else>Change tracking codes</template>
        </button>
        <div class="dropdown-menu select-menu" :class="{'active': show}">
            <a  v-for="item in dropdownItems" 
                @click.prevent="selectedItem(item)" 
                class="dropdown-item" 
                :class="{'active': selectedItems.find(i => i === item.id)}" 
                :href="`${item.id}`"
                :key="item.id">  
                    {{item.name}}
            </a>  
            <a class="btn-lib btn-lib-primary " target="_blank" :href="`${getConstants.common.domain_url}/tracking-codes`" >Add Code</a>
        </div>
    </div>
</template>

<script lang="ts">
import {defineComponent} from 'vue';
import {mapGetters} from 'vuex';

export interface dropdownItems {
  id: number;
  name: string;
}

export default defineComponent({
    name: 'MultipleDropdown',
    emits: ['changeDropdown'],
    components:{},
    props: ['select', 'items'],
    data() {
        return {
            selectedItems: this.select as number[] || [],
            dropdownItems: this.items as Array<dropdownItems> || [],
            show: false,
            vcoConfig: {
                middleware: this.middleware,
                events: ['click'],
                isActive: true,
                detectIFrame: true,
                capture: true
            },
            constants: [] as any
        }
    },

    watch: {
        select(val) {
            this.selectedItems = val;
        },
        items(val) {
            this.dropdownItems = val;
        },
        getConstants(val) {
            this.constants = val;
        }
    },

    methods: {
        selectedItem(item: {'id': number, 'name':string}) {
            if(this.selectedItems.find((el: number) => el === item.id)){
                this.selectedItems.splice(this.selectedItems.indexOf(item.id), 1);
            }else{
                this.selectedItems.push(item.id)
            }

            this.$emit('changeDropdown', this.selectedItems)
        },
        middleware () {
            this.show = false; 
        },
    },

    computed : {
        ...mapGetters(['getConstants']),
    }
});
</script>

<style lang="scss">
    .dropdown {

        .btn-lib-primary {
            margin-left: 1rem;
            margin-right: 1rem;
            margin-top: 24px;
            width: calc(100% - 2rem);

            &:focus {
                color: #fff;
            }
        }

        .select-lib {
            text-align: left;
        }

        &-menu {
            &.active {
                &.select-menu {
                    left: 0;
                    top: 100%;
                }
            }
        }

        &-multiple {
            .dropdown-item {
                padding-left: 50px;
                position: relative;

                &:before {
                    content: "";
                    height: 20px;
                    width: 20px;
                    background: #FFFFFF;
                    border: 2px solid #C4D1DE;
                    border-radius: 5px;
                    position: absolute;
                    top: 50%;
                    left: 16px;
                    transform: translateY(-50%);
                }

                &.active {
                    &:before {
                        background: #5B60CA;
                        border-color: #5B60CA;
                    }

                    &:after {
                        content: "";
                        background-image: url("data:image/svg+xml,%3Csvg width='12' height='9' viewBox='0 0 12 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4.06641 8.8233C4.30078 9.0589 4.69922 9.0589 4.93359 8.8233L11.8242 1.8966C12.0586 1.66099 12.0586 1.26047 11.8242 1.02487L10.9805 0.176702C10.7461 -0.0589005 10.3711 -0.0589005 10.1367 0.176702L4.51172 5.83115L1.86328 3.19241C1.62891 2.95681 1.25391 2.95681 1.01953 3.19241L0.175781 4.04058C-0.0585938 4.27618 -0.0585938 4.6767 0.175781 4.9123L4.06641 8.8233Z' fill='white'/%3E%3C/svg%3E%0A");
                        width: 12px;
                        height: 9px;
                        position: absolute;
                        top: 50%;
                        left: 20px;
                        transform: translateY(-50%);
                    }
                }

                &:focus {
                    color: #5B60CA;
                }
            }
        }
    }
</style>