<template>
    <div class="input-lib" :class="[{'focus': focused, inputClass}, {'is-invalid': inputValue.length < 2 && required}]">
        <input @focus="focused = true" @blur="focused = false" @change="changeInput(inputValue)" type="text" :maxlength="maxsymbols" :placeholder="placeholder" v-model="inputValue">
        <span class="symbols">{{inputValue.length}}/{{maxsymbols}}</span>
    </div>
    <div v-if="inputValue.length == 0 && required" class="error-mess mb-3">The {{placeholder}} is required.</div>
    <div v-else-if="inputValue.length < 2 && required" class="error-mess mb-3">The {{placeholder}} is too short.</div>
</template>

<script lang="ts">
import {defineComponent} from 'vue';

export default defineComponent({
    name: 'InputCount',
    emits: ['value'],
    components:{},
    props: {
        placeholder: String,
        maxsymbols: Number,
        value: String,
        inputClass: String,
        required: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            focused: false,
            inputValue: this.value ? this.value : '',
        }
    },
    watch: {
        value(val) {
            this.inputValue = val;
        }
    },
    methods: {
        changeInput(value: string) {
            this.$emit('value', value)
        },
       
    }
});
</script>

<style lang="scss">
    
</style>