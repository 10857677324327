
import {defineComponent} from 'vue';
import UploadImage from '../UploadImage.vue'
import SelectColor from '../SelectColor.vue';
import {mapGetters} from 'vuex';
import { Cropper } from 'vue-advanced-cropper'
import 'vue-advanced-cropper/dist/style.css';

export default defineComponent({
    name: 'AppearanceBackground',
    props: {
        dataItem: {
            type: Object
        },
        imgPreview: String,
    },
    emits: ['changeBg'],
    components:{
        UploadImage, 
        SelectColor,
        Cropper
    },

    data() {
        return {
            tab: 0,
            cropImage: '',
            cropperShow: false,
            clearUploadImage: false,

            changeBg: this.dataItem || {
                    'typeBg': 'solidColor',
                    'ref': '0regular',
                    'class': {
                        'fontSize': 'font-m',
                        'fontFamily': 'ff-AnekMalayalam',
                        
                        'btn': {
                            'type': 'btn-square',
                            'bg': 'bg-dark',
                            'color': 'text-white',
                        },

                        'socialClass': 'text-dark',
                        'titleClass': 'text-dark' ,
                        'bgClass': 'bg-white'
                    },
                    'style': {
                        'bgColor': '#ffffff' as any,
                        'gardientColor': {
                            'top': '#ffffff',
                            'bottom': '#ffffff'
                        },
                        'titleColor': '#292D34',
                        'buttonColor': '#292D34',
                        'buttonTextColor': '#ffffff',
                        'iconsColor': '#292D34',
                    }
                },
            cropperImgUpload: '',
        }
    },

    watch:{
        dataItem(val){
            this.changeBg = val;

            if(val.typeBg == 'solidColor'){
                this.tab = 0;
            }else if(val.typeBg == 'gradientTemplates') {
                this.tab = 1;
            }else{
                this.tab = 2;
            }
        }
    },

    computed: mapGetters(['getError']),
    
    methods: {

        clickTab(e: number) {
            this.tab = e;

            if( e == 0) {
                this.changeBg.typeBg = 'solidColor';

            }else if(e == 1) {
                this.changeBg.typeBg = 'gradientTemplates';
            }else{
                this.changeBg.typeBg = 'imageTemplates';
            }
        },

        updateBgColor(color: string) {

            this.$emit('changeBg', {
                'typeBg': 'solidColor',
                'bgColor': color,
            })

        },
        updateBgColorTop(color: string) {
            this.changeBg.style.gardientColor.top = color;

            this.$emit('changeBg', {
                'typeBg': 'gradientTemplates',
                'gardientColor': {
                    'top': color,
                    'bottom': this.changeBg.style.gardientColor.bottom
                },
            })
        },
        updateBgColorBottom(color: string) {
            this.changeBg.style.gardientColor.bottom = color;
            this.$emit('changeBg', {
                'typeBg': 'gradientTemplates',
                'gardientColor': {
                    'top': this.changeBg.style.gardientColor.top,
                    'bottom': color,
                },
            })
        },

        changeImageBg(img: any) {
            this.cropperShow = true;
            this.cropperImgUpload = img[0];
            // this.$emit('changeBg', {
            //     'typeBg': 'imageTemplates',
            //     'bgurl': img[0],
            // })
        },

        changeCropper(item: {coordinates: any, canvas:any}) {
            this.cropImage = item.canvas?.toDataURL("image/webp");
        },

        saveCropper() {
            this.cropperShow = false;
            this.$emit('changeBg', {
                'typeBg': 'imageTemplates',
                'bgurl': this.cropImage,
            })
        },

        cancelCropper() {
            this.cropImage = '';
            this.cropperImgUpload = '';
            // this.changeBg.style.bgurl = '';
            this.clearUploadImage = true;
            this.cropperShow = false;
        }
    },
    
});
