<template>
    <div
        class="drop-item"
        @dragover.prevent="dragOver"
        @drop.prevent="drop($event)"
    >
        <!-- <div class="drop" v-show="dropped == 2"></div> -->
       

        <!-- To inform user how to upload image -->
        <div class="drop">
            <input
                type="file"
                style="z-index: 99"
                accept="image/*"
                ref="uploadInput"
                @change="previewImgs"
                multiple
            />
            <div class="mainMessage"  v-show="Imgs.length == 0 && !previewImage">
                <h6>{{ uploadMsg ? uploadMsg : "Drop file here or&nbsp;click to upload" }}</h6>
            </div>
            <div class="preview-img " v-if="Imgs.length == 0 && previewImage">
                <div class="image-holder">
                    <img class="w-100" :src="previewImage" />
                </div>
                <button  @click="append" class="btn-lib btn-lib-secondary btn-change">Change</button>
            </div>
            <div class="preview-img " v-show="Imgs.length > 0">
                <div class="image-holder ">
                    <img class="w-100" :src="previewImage" />
                    <!-- <span class="delete" style="color: white" @click="deleteImg(--i)">delete</span> -->
                    <!-- <div class="plus" @click="append" v-if="++i == Imgs.length">+</div> -->
                </div>
                <button  @click="append" class="btn-lib btn-lib-secondary btn-change">Change</button>
                <!-- <button type="button" class="clearButton" @click="reset">{{ clearAll ? clearAll : "clear" }}</button> -->
            </div>            
        </div>
         <!-- Error Message -->
        <div v-show="error" class="error-img">
            {{ error }}
        </div>
    </div>
</template>

<script>
/* eslint-disable */

export default {
    name: "UploadImage", // vue component name
    emits: ['changeImage'],
    data() {
        return {
            error: "",
            files: [],
            dropped: 0,
            Imgs: [],
            previewImage: this.image || "",
        };
    },
    props: {
        max: Number,
        uploadMsg: String,
        maxError: String,
        fileError: String,
        clearAll: Boolean,
        image: String,
        maxSize: Number
    },
    watch: {
        clearAll(val){
            if(val) {
                this.reset();
            }
        },
        image(val) {
            this.previewImage = val;

        },
       
    },
    methods: {
        dragOver() {
            this.dropped = 2;
        },
        drop(e) {
            let status = true;
            let files = Array.from(e.dataTransfer.files);
            if (e && files) {
                files.forEach((file) => {
                    if (file.type.startsWith("image") === false) status = false;
                });
                if (status == true) {
                    // if (
                    //     this.$props.max &&
                    //     files.length + this.files.length > this.$props.max
                    // ) {
                         
                    //     this.error = this.$props.maxError
                    //         ? this.$props.maxError
                    //         : `Maximum files is ` + this.$props.max;
                    // } else {
                    //     this.files = [];
                    //     this.Imgs = [];
                    //     this.files.push(...files);
                    //     this.previewImgs();
                    // }


                    this.files = [];
                    this.Imgs = [];
                    this.files.push(...files);
                    this.previewImgs(files);
                } else {
                    this.error = this.$props.fileError
                        ? this.$props.fileError
                        : `Unsupported file type`;
                }
            }
            this.dropped = 0;
        },
        append() {
            this.$refs.uploadInput.click();
        },
        readAsDataURL(file) {

            

            return new Promise(function (resolve, reject) {
                let fr = new FileReader();
                fr.onload = function () {
                    resolve(fr.result);
                };
                fr.onerror = function () {
                    reject(fr);
                };
                fr.readAsDataURL(file);
            });
        },
        deleteImg(index) {
            this.Imgs.splice(index, 1);
            this.files.splice(index, 1);
            // this.$emit("changed", this.files);
            this.$emit("changeImage", this.Imgs);
            this.$refs.uploadInput.value = null;
        },
        previewImgs(event) {
            
            if(event?.currentTarget?.files[0]?.size > (this.$props.maxSize * 1000000) || event[0]?.size > (this.$props.maxSize * 1000000)) {
                this.error = `Image exceeds size limit: ${this.$props.maxSize}mb`

                this.reset();

            }else{
                this.error = "";

                this.Imgs = [];
                this.files = [];

                if (this.dropped == 0) {
                    this.files.push(...event.currentTarget.files)
                }else{
                    this.files.push(...event)
                }
                // this.$emit("changed", this.files);

                let readers = [];
                if (!this.files.length) return;
                for (let i = 0; i < this.files.length; i++) {
                    readers.push(this.readAsDataURL(this.files[i]));
                }
                Promise.all(readers).then((values) => {
                    this.Imgs = values;
                    this.$emit("changeImage", this.Imgs);
                });
            }


            // if (
            //     this.$props.max &&
            //     event &&
            //     event.currentTarget.files.length + this.files.length > this.$props.max
            // ) {
                

            //     this.error = this.$props.maxError
            //         ? this.$props.maxError
            //         : `Maximum files is` + this.$props.max;
            //     return;
            // }
          
        },
        reset() {
            this.$refs.uploadInput.value = null;
            this.Imgs = [];
            this.files = [];
            // this.$emit("changed", this.files); 
            this.$emit("changeImage", this.Imgs);

        },
    },
};
</script>

<style  lang="scss" scoped>

.error-img {
    // text-align: center;
    color: red;
    font-size: 15px;
    margin-top: 1rem;
}
.drop {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 13px 20px;
    min-height: 128px;
    background: #ECEEF7;
    border: 2px dashed #CBD1D9;
    border-radius: 10px;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 1;
    text-align: center;
    color: #BBBFC6;

    h6 {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        color: #BBBFC6;
        max-width: 146px;
        margin-left: auto;
        margin-right: auto;
    }

    &:hover {
        border: 2px solid #5B60CA;
        .image-holder {
             img {
                opacity: .9;
                z-index: 0;
            }
            &:before {
                content: "";
                background: #575762;
                opacity: .5;
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                z-index: 1;
                border-radius: 10px;
            }
        }

        

        .btn-change  {
            opacity: 1;
            z-index: 1;
        }
    }

    .btn-change {
        border-color: #fff;
        color: #fff;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        max-width: 115px;
        opacity: 0;
    }

    input {
        width: 100%;
        margin: auto;
        height: 100%;
        opacity: 0;
        position: absolute;
        background: red;
        display: block;

        &:hover {
            cursor: pointer;
        }

        .icon {
            width: 150px;
            margin: auto;
            display: block;
        }
    }
}
.preview-img {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    
    

     .image-holder {
        max-width: 150px;
        border-radius: 6px;
        overflow: hidden;

        img {
            object-fit: cover;
            width: 100%;
            height: 100%;
            border: 2px solid transparent;
            border-radius: 6px;
            transition: .3s;
            max-height: 200px;
        }

        .delete {
            position: absolute;
            top: 4px;
            right: 4px;
            width: 29px;
            height: 29px;
            color: #fff;
            background: red;
            border-radius: 50%;

            &:hover {
                cursor: pointer;
            }
        }
    } 
}

.clearButton {
    color: #2d3748;
    background: none;
    border: none;
    cursor: pointer;
}
</style>