<template>
    <div class="backgrounds">
        <ul class="backgrounds-nav">
            <li :class="{'active' : tab == 0}" @click="clickTab(0)">
                <div class="backgrounds-preview">
                    <div class="backgrounds-color"></div>
                </div>
                <div class="backgrounds-name">Solid color</div>
            </li>
            <li :class="{'active' : tab == 1}"  @click="clickTab(1)">
                <div class="backgrounds-preview">
                    <div class="backgrounds-gradient"></div>
                </div>
                <div class="backgrounds-name">Gradient</div>
            </li>
            <li  :class="{'active' : tab == 2}"  @click="clickTab(2)">
                <div class="backgrounds-preview" :class="{'error' : Object.keys(getError).length > 0 && getError.errors['options.bg_url']}">
                    <div><img src="@/assets/images/frame7216.png" alt=""></div>
                </div>
                <div class="backgrounds-name">Image</div>
            </li>
        </ul>

        <div class="backgrounds-content">
            <div v-if="tab == 0" class="backgrounds-item " :class="{'active' : tab == 0}">
                 <SelectColor 
                    :selectColor="changeBg.style.bgColor"
                    classCustom="border-0" 
                    title="Select background color"
                    @changeColor="updateBgColor" />
            </div>
            <div v-if="tab == 1" class="backgrounds-item " :class="{'active' : tab == 1}">
                <SelectColor 
                        :selectColor="changeBg.style.gardientColor.top"
                        classCustom="border-0" 
                        title="Top color"
                        @changeColor="updateBgColorTop" />

                <SelectColor 
                        :selectColor="changeBg.style.gardientColor.bottom"
                        classCustom="border-0" 
                        title="Bottom color"
                        @changeColor="updateBgColorBottom" />


            </div>
            <div v-if="tab == 2" class="backgrounds-item" :class="{'active' : tab == 2}">
                <div class="title">Upload background image</div>
                <p>PNG or JPG, minimum resolution 1920 x 1920 px and maximum size 5mb</p>

                <UploadImage 
                    v-show="!cropperShow"
                    :clearAll="clearUploadImage"
                    :class="{ 'rounded-xl d-inline-block error' : Object.keys(getError).length > 0 && getError.errors['options.bg_url']}" 
                    :image="changeBg.style.bgurl ? changeBg.style.bgurl : imgPreview" 
                    @changeImage="changeImageBg"
                    :maxSize="5" />

                <template v-if="cropperShow ">
                    <span  v-if="cropImage === '' && cropperShow"  class="load-cropper"><div class="lds-ring icon"><div></div><div></div><div></div><div></div></div>  Loading</span>

                    <cropper
                        class="cropper"
                        :src="cropperImgUpload"
                        ref="cropper" 
                        @change="changeCropper"
                    />
                    <div  class="cropper-footer">
                        <button @click="cancelCropper" class="link-lib">Cancel</button>
                        <button @click="saveCropper" class="link-lib">Save</button>
                    </div>
                </template>
                
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import {defineComponent} from 'vue';
import UploadImage from '../UploadImage.vue'
import SelectColor from '../SelectColor.vue';
import {mapGetters} from 'vuex';
import { Cropper } from 'vue-advanced-cropper'
import 'vue-advanced-cropper/dist/style.css';

export default defineComponent({
    name: 'AppearanceBackground',
    props: {
        dataItem: {
            type: Object
        },
        imgPreview: String,
    },
    emits: ['changeBg'],
    components:{
        UploadImage, 
        SelectColor,
        Cropper
    },

    data() {
        return {
            tab: 0,
            cropImage: '',
            cropperShow: false,
            clearUploadImage: false,

            changeBg: this.dataItem || {
                    'typeBg': 'solidColor',
                    'ref': '0regular',
                    'class': {
                        'fontSize': 'font-m',
                        'fontFamily': 'ff-AnekMalayalam',
                        
                        'btn': {
                            'type': 'btn-square',
                            'bg': 'bg-dark',
                            'color': 'text-white',
                        },

                        'socialClass': 'text-dark',
                        'titleClass': 'text-dark' ,
                        'bgClass': 'bg-white'
                    },
                    'style': {
                        'bgColor': '#ffffff' as any,
                        'gardientColor': {
                            'top': '#ffffff',
                            'bottom': '#ffffff'
                        },
                        'titleColor': '#292D34',
                        'buttonColor': '#292D34',
                        'buttonTextColor': '#ffffff',
                        'iconsColor': '#292D34',
                    }
                },
            cropperImgUpload: '',
        }
    },

    watch:{
        dataItem(val){
            this.changeBg = val;

            if(val.typeBg == 'solidColor'){
                this.tab = 0;
            }else if(val.typeBg == 'gradientTemplates') {
                this.tab = 1;
            }else{
                this.tab = 2;
            }
        }
    },

    computed: mapGetters(['getError']),
    
    methods: {

        clickTab(e: number) {
            this.tab = e;

            if( e == 0) {
                this.changeBg.typeBg = 'solidColor';

            }else if(e == 1) {
                this.changeBg.typeBg = 'gradientTemplates';
            }else{
                this.changeBg.typeBg = 'imageTemplates';
            }
        },

        updateBgColor(color: string) {

            this.$emit('changeBg', {
                'typeBg': 'solidColor',
                'bgColor': color,
            })

        },
        updateBgColorTop(color: string) {
            this.changeBg.style.gardientColor.top = color;

            this.$emit('changeBg', {
                'typeBg': 'gradientTemplates',
                'gardientColor': {
                    'top': color,
                    'bottom': this.changeBg.style.gardientColor.bottom
                },
            })
        },
        updateBgColorBottom(color: string) {
            this.changeBg.style.gardientColor.bottom = color;
            this.$emit('changeBg', {
                'typeBg': 'gradientTemplates',
                'gardientColor': {
                    'top': this.changeBg.style.gardientColor.top,
                    'bottom': color,
                },
            })
        },

        changeImageBg(img: any) {
            this.cropperShow = true;
            this.cropperImgUpload = img[0];
            // this.$emit('changeBg', {
            //     'typeBg': 'imageTemplates',
            //     'bgurl': img[0],
            // })
        },

        changeCropper(item: {coordinates: any, canvas:any}) {
            this.cropImage = item.canvas?.toDataURL("image/webp");
        },

        saveCropper() {
            this.cropperShow = false;
            this.$emit('changeBg', {
                'typeBg': 'imageTemplates',
                'bgurl': this.cropImage,
            })
        },

        cancelCropper() {
            this.cropImage = '';
            this.cropperImgUpload = '';
            // this.changeBg.style.bgurl = '';
            this.clearUploadImage = true;
            this.cropperShow = false;
        }
    },
    
});
</script>

