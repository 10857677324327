
import {defineComponent} from 'vue';
import * as icons from 'vue-tabler-icons';

export default defineComponent({
    name: 'SelectedIcon',
    components:{},
    emits: ['icon'],
    data() {
        return {
            color: 'black',
            strokeWidth: '2',
            search: '',
            iconsModal: icons,
            selectionIcon: '',
        };
    },
    computed: {
        icons() {
          return icons;
        },
        
        filteredList(): any {
          return  Object.fromEntries(Object.entries(icons).filter(([key]) => key.toLowerCase().includes(this.search.toLowerCase())));
        }
        
    },
    methods : {
        changeIcon(name: string) {
            this.selectionIcon = name;
            this.$emit('icon', name);
        }
    },
    

});
