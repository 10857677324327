
import {defineComponent} from 'vue';
import {mapGetters, mapActions} from 'vuex';
import Modal from '../common/Modal.vue';
import * as icons from 'vue-tabler-icons';

export default defineComponent({
  components: { Modal },
    name: 'ViewPage',
    props: {
        simpleLink: Object as any,
        dataTheme: Object as any,
        socialLink: Object as any,
        showShareModal: Boolean,
    },
    data() {
        return {
            data: this.dataTheme as any,
            showModal: false,
            colorIcon: this.dataTheme?.customization.currentTheme.custom.style.buttonTextColor,
            strokeWidth: '2',
            copyText: 'Copy',
            sharing: {
                title: 'Say hi to Vite! A brand new, extremely fast development setup for Vue.',
                description: 'This week, I’d like to introduce you to "Vite", which means "Fast". It’s a brand new development setup created by Evan You.',
                quote: 'The hot reload is so fast it\'s near instant. - Evan You',
                hashtags: 'vuejs,vite,javascript',
                twitterUser: 'youyuxi'
            },
            networks: [
                { network: 'facebook', name: 'Share on Facebook', icon: '#fb', color: '#1877F2' },
                { network: 'twitter', name: 'Share on Twitter', icon: '#twitter', color: '#1DA1F2' },
                { network: 'linkedin', name: 'Share on LinkedIn', icon: '#linkedin', color: '#0A66C2' },
                { network: 'telegram', name: 'Share via Telegram', icon: '#telegram', color: '#2AABEE ' },
                { network: 'email', name: 'Share via Email', icon: '#email', color: '#111928' },
                // { network: 'messenger', name: 'Messenger', icon: '#messenger', color: '#0084ff' },
                // { network: 'whatsapp', name: 'Whatsapp', icon: '#whatsapp', color: '#25d366' },
            ]
        }
    },

    watch: {
        dataTheme(val) {
            this.data = val;
        }
    },

    methods: {
        ...mapActions(['updateLinkAnalitic']),

        closeModal(e: boolean) {
            this.showModal = e;
        },
        async copyLink() {
            this.copyText = 'Сopied';

            new Promise( (resolve)=>{setTimeout(resolve, 1000) } )
            .then( ()=> {
                this.copyText = 'Copy';
            });
        },

        getUrlDomain(url: any) {
            if(url.includes('http')) {
                let hostUrl = new URL(String(url));
            
                return  this.getDomain.indexOf('.'+(hostUrl.host.split('.').filter(Boolean))[hostUrl.host.split('.').filter(Boolean).length-1]) != -1;
            }

            return false;

            
        }
    },
    computed : {
        ...mapGetters(['getSocialsData', 'getDomain']),
        
        icons() {
            return icons;
        },
        
    },
});
