<template>
    <div class="dropdown" v-click-outside="vcoConfig">
        <button class="dropdown-toggle" @click="show = !show">
            <span>
                {{selectedValue || getConstants.common.domain_url_static.replace('https://', '').replace('http://', '')}}
            </span>
        </button>
        <div class="dropdown-menu" :class="{'active': show}">
            
            <template v-if="dropdownType == 'domain'">
                <a @click="changeType(getConstants.common.domain_url_static.replace('https://', '').replace('http://', ''))" href="#" class="dropdown-item">  
                    {{getConstants.common?.domain_url_static.replace('https://', '').replace('http://', '')}}
                </a>
                <template  v-for="item in data" :key="item.id">
                    <a @click="changeType(item.domain)" href="#" class="dropdown-item" v-if="item.validated === 1">  
                        {{item.domain}}
                    </a>
                </template>
               
            </template>
            <template v-else>
                <a @click="changeType(item)" href="#" class="dropdown-item" v-for="item in data" :key="item">  
                    {{item}}
                </a>  
            </template>
        </div>
    </div>
</template>

<script lang="ts">
import {defineComponent} from 'vue';
import {mapGetters} from 'vuex';

export default defineComponent({
    name: 'Dropdown',
    components:{},
    props: {
        selected: {
            type: String,
            default: ''
        },
        dropdownType: String,
        dataDropdown: {} as any
    },
    emits: ['changeValue'],
    data() {
        return {
            show: false,
            selectedValue: this.selected ? this.selected : '',
            data: this.dataDropdown ? this.dataDropdown : [],
            vcoConfig: {
                middleware: this.middleware,
                events: ['click'],
                // Note: The default value is true, but in case you want to activate / deactivate
                //       this directive dynamically use this attribute.
                isActive: true,
                // Note: The default value is true. See "Detecting Iframe Clicks" section
                //       to understand why this behaviour is behind a flag.
                detectIFrame: true,
                // Note: The default value is false. Sets the capture option for EventTarget addEventListener method.
                //       Could be useful if some event's handler calls stopPropagation method preventing event bubbling.
                capture: true
            }
        }
    },

    watch: {
        selected(val) {
            this.selectedValue = val;
        },
        dataDropdown(val) {
            this.data = val;
        },
    },
    
    computed: mapGetters(['getConstants']),

    methods: {
        changeType(item: string) {
            this.$emit('changeValue', item);
            this.selectedValue = item;
            this.show = false;
        },
        
        middleware (event:any) {
            if(event) {
                this.show = false;
            }
        },
    },

    created() {
        if(this.selectedValue == '') {
            this.$emit('changeValue', this.getConstants.common.domain_url_static.replace('https://', '').replace('http://', ''));
        }
    },
    
});
</script>

<style lang="scss">
   .dropdown {
        position: relative;

        &-menu {
            min-width: 258px;

            &.active {
                top: calc(100% + 9px);
                left: -18px;
            }

        }
   }
</style>