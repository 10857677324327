<template>
    <div class="analitics-container">
        <h6 class="h6-lib ff-Poppins font-weight-600 normal-margin">{{title}}</h6>
        <div class="referrers-items">

            <div v-for="item in dataChart" :key="item" class="analitics-item referrers-item">
                <div class="analitics-name" v-tippy="`${item.title}`">{{item.title}}</div>
                <div class="analitics-progress"><span  :style="`background: ${progressColor}; width: ${unique ? item.unique_to_total_percent : item.total_percent}%`"></span></div>
                <div class="analitics-ctr">
                    <div class="ctr-title">
                        <template v-if="unique">{{item.unique_to_total_percent}}</template>
                        <template v-else>{{item.total_percent}}</template>%
                    </div>
                    <div class="ctr-rate text-gray">
                        (<template v-if="unique">{{item.unique_value}}</template>
                        <template v-else>{{item.total_value}}</template>)
                    </div>
                </div>
            </div>
            <img v-if="Object.values(dataChart).length === 0" src="@/assets/images/no-data.svg" alt="Empty data" class="w-100 no-data" />
        </div>
    </div>
</template>

<script lang="ts">
import {defineComponent} from 'vue';

export default defineComponent({
    name: 'AnaliticsTopReferrers',
    components:{},
    props: ['title', 'progressColor', 'data', 'chartUnique'],
    
    data() {
        return {
            dataChart: this.data || [],
            unique: this.chartUnique,
            loaded: false,
        }
    },
    watch: {
        data(val) {
            this.dataChart = val;
        },

        chartUnique(val){
            this.unique = val;
        }
    }
});
</script>
