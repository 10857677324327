<template>
        <Skeleton v-if="loading" style="min-height:100vh; display: block;"/>
        <div v-else class="view-fullpage">
            <ViewPage :dataTheme="allData" :simpleLink="simpleLinksDb" :socialLink="socialLinksDb" :showShareModal="true"  />
        </div>
</template>

<script lang="ts">
import {defineComponent} from 'vue';
import {useRoute} from 'vue-router';
import {mapGetters, mapActions} from 'vuex';
import ViewPage from '@/components/layout/ViewPage.vue';
import Skeleton from '@/components/common/Skeleton.vue';

export default defineComponent({
    name: "UserPage",
    components: { ViewPage, Skeleton },
    data() {
        return {
            slugName: "" as any,
            loading: true,
            socialLinksDb: {} as any,
            simpleLinksDb: {} as any,
        }
    },
    watch: {
        allData(val) {
            this.simpleLinksDb = val.pp_inner_links.filter((link: { type: string; }) => {return link.type === 'simple'}).sort(function (a:any, b:any){
                return a.order-b.order
            })
            this.socialLinksDb =  val.pp_inner_links.filter((link: { type: string; }) => {return link.type === 'social'}).sort(function (a:any, b:any){
                return a.order-b.order
            })

            this.loading = false;

        }
    },
    computed: mapGetters(['allData']),

    methods: {
        ...mapActions(['slugPage']),
    },
    
    created() {
        const route = useRoute();
        this.slugName = route.params.slug;
        this.slugPage(this.slugName)
    }
});
</script>

<style scoped lang="scss">

</style>