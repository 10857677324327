<template>
    <div class="appearance" ref="appearance">
        <h5 class="h5-lib ff-Poppins font-weight-600">Appearance</h5>
        <div class="tabnav appearance-nav">
            <button @click="currentTab = 0" :class="{'active' : currentTab == 0}" class="tabnav-item">Themes</button>
            <button @click="currentTab = 1" :class="{'active' : currentTab == 1, 'error' : Object.keys(getError).length > 0 && getError.errors['options.bg_url']}" class="tabnav-item">Custom appearance</button>
        </div>

        <div class="tab-item" :class="{'active' : currentTab == 0}">
            <p>Customize your PixelLife profile the way you want. Change the background with different colors or images. Choose a trendy template. Or, add your personal touch and make it unique. </p>

            <div class="templates" >
                <!-- <div :style="{ 'top': activeElement.top + 'px', 'left' : activeElement.left + 'px', 'width' : activeElement.width +'px', 'height': activeElement.height+'px' }" class="templates-active templates-item" ref="templatesActive"></div> -->

                <div class="templates-row" v-for="(item, index) in dataTheme" :key="index">
                    <h6 class="h6-lib ff-Poppins font-weight-600 templates-title">{{index.replace(/([A-Z])/g, ' $1')}}</h6>
                    
                    <div v-if="index == 'solidColor'" class="templates-item" @click="currentTab = 1">
                        <div class="custom-theme"></div>
                        <div class="templates-name">Custom</div>
                    </div>
                    <label  v-for="(value, i) in item" :key="value.cssClass" :for="`template_${index}${i}`" class="templates-item" :class="[value.cssClass, {'active': styleTheme.refTheme == (i+index)}]">
                        <div :ref="el => { refsTheme[i+index] = el }"  class="templates-preview"></div>
                        <input :ref="el => { refsInput[i+index] = el }"  @change="changeTheme(i+index, value)" :id="`template_${index}${i}`" name="template" type="radio" :value="value.cssClass" >
                        <div class="templates-name">{{value.title}}</div>
                    </label>
                </div>
                
            </div>
        </div>

        <div class="tab-item" :class="{'active' : currentTab == 1}">
            <p>Customize your PixelPass profile the way you want. Change the background with different colors or images. Choose a trendy template. Or, add your personal touch and make it unique. </p>

            <SelectColor 
                :selectColor="styleTheme.style.titleColor"
                title="Title text color:"
                classCustom="border-0" 
                @changeColor="updateTitleColor" />

            <SelectColor 
                :selectColor="styleTheme.style.buttonTextColor"
                classCustom="border-0"
                title="Button font color:"
                @changeColor="updateFontsColor" />

            <SelectColor 
                :selectColor="styleTheme.style.buttonColor"
                classCustom="border-0" 
                title="Button color"
                @changeColor="updateButtonColor" /> 

            <SelectColor 
                :selectColor="styleTheme.style.iconsColor"
                title="Social links color:"
                @changeColor="updateSocialColor"
                classCustom="border-0"  />

            <!-- <div class="hide-logo">
                <div class="info">
                    <div class="title">
                        Hide the PixelLife logo
                        <span class="lock-article">
                            <span class="lock-svg"></span>
                            <span class="lock-text">Upgrade to unlock</span>
                        </span>
                    </div>
                    <p>Remove the PixelLife watermark and get access to all premium content and pro tools.</p>
                </div>
                <label for="checkbox_h-sw" class="switch-lib">
                    <input id="checkbox_h-sw" type="checkbox" name="deeplinks" value="1" class="switch-input" disabled>
                    <span class="switch-lib-slider"></span>
                </label>
            </div> -->
        </div>
    </div>

    <div class="appearance tab-item" :class="{'active' : currentTab == 1, 'error' : Object.keys(getError).length > 0 && getError.errors['options.bg_url']}">
        <Accordion :show="true" @update:accordion="updateAccordion" title="Custom background" subtitle="Choose a background from our library or upload your own image.">
            <AppearanceBackground
                :dataItem="styleTheme"
                :imgPreview="bgImagePreview"
                @changeBg="updateBg"
            />
        </Accordion>
    </div>

    <div class="appearance tab-item" :class="{'active' : currentTab == 1}">
        <Accordion :show="true" @update:accordion="updateAccordion"  title="Custom fonts" subtitle="Create your own unique font with a custom design and color.">
            <SelectFontSize 
                :selected="styleTheme.class.fontSize"
                @changeFontSize="updateFontSize" />

            

            <SelectFontFamily 
                :selected="styleTheme.class.fontFamily"
                @changeFontFamily="updateFontFamily" 
                :updateSidebar="updateItem" />
        </Accordion>

    </div>

    <div class="appearance tab-item" :class="{'active' : currentTab == 1}">

        <Accordion :show="true" 
            @update:accordion="updateAccordion" 
            title="Custom buttons" 
            subtitle="Personalize your links with a unique shape and look."
            :lock="false">

            <AppearanceTypeButton 
                @changeButton="updateButton" 
                :activeType="styleTheme.refBtn" 
                :updateSize="updateItem"
                 />

        </Accordion>
    </div>

</template>

<script lang="ts"> 
import {defineComponent, ref} from 'vue';
import Accordion from '../Accordion.vue';
import AppearanceTypeButton from './AppearanceTypeButton.vue';
import SelectColor from '../SelectColor.vue';
import SelectFontSize from '../SelectFontSize.vue';
import SelectFontFamily from '../SelectFontFamily.vue';
import AppearanceBackground from './AppearanceBackground.vue';
import {mapGetters} from 'vuex';

export default defineComponent({
    name: 'AppearanceTheme',
    components:{ 
        Accordion, 
        AppearanceTypeButton, 
        SelectColor, 
        SelectFontSize, 
        SelectFontFamily, 
        AppearanceBackground 
        },
    emits: [
        'changeTheme', 
        'changeButton', 
        'changeCustom', 
        'changeButtonColor', 
        'changeTitleColor', 
        'changeSocialColor',
        'changeFontSize',
        'changeFontColor',
        'changeFontFamily'
        ],
    props: {
        updateSidebar: Boolean,
        dataCurrent: {} as any,
        clickTab: Number,
        bgImagePreview: String,
    },
    data() {
        return {
            activeElement : {
                top: 0,
                left: 0,
                height: 0,
                width: 0,
                index: this.dataCurrent.refTheme as any,
            },
            currentTab: 0,
            styleTheme: this.dataCurrent ? this.dataCurrent : {
                    'typeBg': 'solidColor',
                    'refBtn': '0shape',
                    'refTheme': '0solidColor',
                    'class': {
                        'fontSize': 'font-m',
                        'fontFamily': 'ff-AnekMalayalam',
                        
                        'btn': {
                            'name': 'btn-square',
                            'bg': 'bg-dark',
                            'color': 'text-white',
                        },

                        'socialClass': 'text-dark',
                        'titleClass': 'text-dark' ,
                        'bgClass': 'bg-white'
                    },
                    'style': {
                        'bgColor': '#ffffff',
                        'gardientColor': {
                            'top': '#ffffff',
                            'bottom': '#ffffff'
                        },
                        'titleColor': '#292D34',
                        'buttonColor': '#292D34',
                        'buttonTextColor': '#ffffff',
                        'iconsColor': '#292D34',
                    }
                }
            ,
            updateItem: '' as any,
            accordionStatus: '' as any
        }
    },
    watch:{
        updateSidebar(val){
            this.updateItem = val;
        },
        updateItem() {
            new Promise( (resolve)=>{setTimeout(resolve, 300) } )
            .then( ()=> {
               this.resizeWindowThemes();
            });
            
        },

        dataCurrent(val) {
            this.styleTheme = val;
            this.activeElement.index = val.refTheme;
            this.resizeWindowThemes();
        },

        clickTab() {
            new Promise( (resolve)=>{setTimeout(resolve, 300) } )
            .then( ()=> {
               this.resizeWindowThemes();
            });
        }
       
    },
    computed: mapGetters(['getError']),
    methods: {

        updateBg(item: any) {
            this.$emit('changeCustom',item)
        },

        changeTheme(myref: any, item: any) {
            this.$emit('changeTheme',item)
            this.styleTheme = item.custom;
            this.activeElement.index = myref;
            this.resizeWindowThemes();
        },


        resizeWindowThemes() {
            if((this.refsTheme[this.activeElement.index] as HTMLInputElement)) {
                // let top = (this.refsTheme[this.activeElement.index] as HTMLInputElement).getBoundingClientRect().top;
                // let left = (this.refsTheme[this.activeElement.index] as HTMLInputElement).getBoundingClientRect().left;
                // let height = (this.refsTheme[this.activeElement.index] as HTMLInputElement).getBoundingClientRect().height;
                // let width = (this.refsTheme[this.activeElement.index] as HTMLInputElement).getBoundingClientRect().width;

                // this.activeElement.top = top + window.pageYOffset ;
                // this.activeElement.left =  left + window.pageXOffset;
                // this.activeElement.height = height;
                // this.activeElement.width = width;

                (this.refsInput[this.activeElement.index] as HTMLInputElement).checked=true
            }

            
        },

        updateAccordion(e:boolean) {
           if(this.updateItem == e) {
            this.updateItem = !e;
           }else{
            this.updateItem = e;
           }
        },

        updateButton( item: any) {
            this.$emit('changeButton',item)
        },

        updateButtonColor(color: string) {
            this.$emit('changeButtonColor',color)
        },

        updateTitleColor(color: string) {
            this.$emit('changeTitleColor',color)
        },

        updateSocialColor(color: string) {
            this.$emit('changeSocialColor',color)
        },

        updateFontsColor(color: string) {
            this.$emit('changeFontColor',color)
        },

        updateFontSize(item: {'name': string, 'className': string}) {
            this.$emit('changeFontSize',item)
        },

        updateFontFamily(item: {'name': string, 'className': string}) {
            this.$emit('changeFontFamily', item.className)
        }

        
    },

    created() {
        window.addEventListener("resize", this.resizeWindowThemes);

    },

    // mounted() {
    //     this.resizeWindowThemes();
    // },

    setup() {

        const refsTheme = ref([]);
        const refsInput = ref([]);
        

        const dataTheme = {
            'solidColor' : [
                {
                    'title' : 'Fresh White',
                    'cssClass' : 'fresh-white',
                    'custom': {
                        'typeBg': 'solidColor',
                        'refBtn': '0shape',
                        'refTheme': '0solidColor',

                        'class': {
                            'fontSize': 'font-m',
                            'fontFamily': 'ff-AnekMalayalam',
                            
                            'btn': {
                                'name': 'btn-square',
                                'bg': 'bg-dark',
                                'color': 'text-white',
                            },

                            'socialClass': 'text-dark',
                            'titleClass': 'text-dark' ,
                            'bgClass': 'bg-white'
                        },
                        'style': {
                            'bgColor': '#ffffff',
                            'gardientColor': {
                                'top': '#ffffff',
                                'bottom': '#ffffff'
                            },
                            'titleColor': '#292D34',
                            'buttonColor': '#292D34',
                            'buttonTextColor': '#ffffff',
                            'iconsColor': '#292D34',
                        }
                    }
                },
                {
                    'title' : 'Mint',
                    'cssClass' : 'mint',
                    'custom': {
                        'typeBg': 'solidColor',
                        'refBtn': '2shape',
                        'refTheme': '1solidColor',
                        'class': {
                            'fontSize': 'font-m',
                            'fontFamily': 'ff-Inter',
                            
                            'btn': {
                                'name': 'btn-rounded',
                                'bg': 'bg-mintdark',
                                'color': 'text-mint',
                            },

                            'socialClass': 'text-mint',
                            'titleClass': 'text-mint' ,
                            'bgClass': 'bg-mint'
                        },
                        'style': {
                            'bgColor': '#48CCB4',
                            'gardientColor': {
                                'top': '#ffffff',
                                'bottom': '#ffffff'
                            },
                            'titleColor': '#DEFFBD',
                            'buttonColor': '#325864',
                            'buttonTextColor': '#DEFFBD',
                            'iconsColor': '#DEFFBD',
                        }
                    }
                },
                {
                    'title' : 'Coffee',
                    'cssClass' : 'coffee',
                    'custom': {
                        'typeBg': 'solidColor',
                        'refBtn': '2shape',
                        'refTheme': '2solidColor',
                        'class': {
                            'fontSize': 'font-m',
                            'fontFamily': 'ff-Montserrat',
                            
                            'btn': {
                                'name': 'btn-rounded',
                                'bg': 'bg-coffee',
                                'color': 'text-white',
                            },

                            'socialClass': 'text-coffee',
                            'titleClass': 'text-coffee' ,
                            'bgClass': 'bg-coffelight'
                        },
                        'style': {
                            'bgColor': '#F0ECDC',
                            'gardientColor': {
                                'top': '#ffffff',
                                'bottom': '#ffffff'
                            },
                            'titleColor': '#66411F',
                            'buttonColor': '#66411F',
                            'buttonTextColor': '#ffffff',
                            'iconsColor': '#66411F',
                        }
                    }
                },
                {
                    'title' : 'White Italic',
                    'cssClass' : 'white-italic',
                    'custom': {
                        'typeBg': 'solidColor',
                        'refBtn': '2softShadow',
                        'refTheme': '3solidColor',
                        'class': {
                            'fontSize': 'font-s',
                            'fontFamily': 'ff-Merriweather',
                            'fontStyle': 'italic',
                            
                            'btn': {
                                'name': 'btn-rounded-outline soft-shadow',
                                'bg': 'bg-white',
                                'color': 'text-dark'
                            },

                            'socialClass': 'text-dark',
                            'titleClass': 'text-dark' ,
                            'bgClass': 'bg-white'
                        },
                        'style': {
                            'bgColor': '#ffffff',
                            'gardientColor': {
                                'top': '#ffffff',
                                'bottom': '#ffffff'
                            },
                            'titleColor': '#292D34',
                            'buttonColor': '#292D34',
                            'buttonTextColor': '#292D34',
                            'iconsColor': '#292D34',
                        }
                    }
                },
                {
                    'title' : 'Raspberry',
                    'cssClass' : 'raspberry',
                    'custom': {
                        'typeBg': 'solidColor',
                        'refBtn': '2shape',
                        'refTheme': '4solidColor',
                        'class': {
                            'fontSize': 'font-m',
                            'fontFamily': 'ff-Montserrat',
                            
                            'btn': {
                                'name': 'btn-square',
                                'bg': 'bg-white',
                                'color': 'text-dark'
                            },

                            'socialClass': 'text-white',
                            'titleClass': 'text-white' ,
                            'bgClass': 'bg-raspberry'
                        },
                        'style': {
                            'bgColor': '#FF005C',
                            'gardientColor': {
                                'top': '#ffffff',
                                'bottom': '#ffffff'
                            },
                            'titleColor': '#ffffff',
                            'buttonColor': '#292D34',
                            'buttonTextColor': '#ffffff',
                            'iconsColor': '#ffffff',
                        }
                    }
                },
                {
                    'title' : 'Extreme',
                    'cssClass' : 'extreme',
                    'custom': {
                        'typeBg': 'solidColor',
                        'refBtn': '2shape',
                        'refTheme': '5solidColor',
                        'class': {
                            'fontSize': 'font-s',
                            'fontFamily': 'ff-Montserrat',
                            
                            'btn': {
                                'name': 'btn-rounded',
                                'bg': 'bg-black',
                                'color': 'text-white'
                            },

                            'socialClass': 'text-black',
                            'titleClass': 'text-black' ,
                            'bgClass': 'bg-yellow'
                        },
                        'style': {
                            'bgColor': '#EBFF00',
                            'gardientColor': {
                                'top': '#ffffff',
                                'bottom': '#ffffff'
                            },
                            'titleColor': '#000000',
                            'buttonColor': '#000000',
                            'buttonTextColor': '#ffffff',
                            'iconsColor': '#000000',
                        }
                    }
                },
                {
                    'title' : 'Light Blue',
                    'cssClass' : 'light-blue',
                    'custom': {
                        'typeBg': 'solidColor',
                        'refBtn': '0shape',
                        'refTheme': '6solidColor',
                        'class': {
                            'fontSize': 'font-s',
                            'fontFamily': 'ff-Roboto',
                            
                            'btn': {
                                'name': 'btn-square',
                                'bg': 'bg-white',
                                'color': 'text-black'
                            },

                            'socialClass': 'text-dark',
                            'titleClass': 'text-white' ,
                            'bgClass': 'bg-lightblue'
                        },
                        'style': {
                            'bgColor': '#0085FF',
                            'gardientColor': {
                                'top': '#ffffff',
                                'bottom': '#ffffff'
                            },
                            'titleColor': '#ffffff',
                            'buttonColor': '#ffffff',
                            'buttonTextColor': '#000000',
                            'iconsColor': '#ffffff',
                        }
                    }
                },
                {
                    'title' : 'Super Black',
                    'cssClass' : 'super-black',
                    'custom': {
                        'typeBg': 'solidColor',
                        'refBtn': '2outline',
                        'refTheme': '7solidColor',
                        'class': {
                            'fontSize': 'font-s',
                            'fontFamily': 'ff-Merriweather',
                            
                            'btn': {
                                'name': 'btn-rounded-outline',
                                'bg': 'bg-black',
                                'color': 'text-white'
                            },

                            'socialClass': 'text-white',
                            'titleClass': 'text-white' ,
                            'bgClass': 'bg-black'
                        },
                        'style': {
                            'bgColor': '#000000',
                                'gardientColor': {
                                'top': '#ffffff',
                                'bottom': '#ffffff'
                            },
                            'titleColor': '#ffffff',
                            'buttonColor': '#ffffff',
                            'buttonTextColor': '#ffffff',
                            'iconsColor': '#ffffff',
                        }
                    }
                }
            ],
            'gradientTemplates' : [
                {
                    'title' : 'Sunset',
                    'cssClass' : 'sunset',
                    'custom': {
                        'typeBg': 'gradientTemplates',
                        'refBtn': '1shape',
                        'refTheme': '0gradientTemplates',
                        'class': {
                            'fontSize': 'font-l',
                            'fontFamily': 'ff-Roboto',
                            
                            'btn': {
                                'name': 'btn-regular',
                                'bg': 'bg-white',
                                'color': 'text-dark'
                            },

                            'socialClass': 'text-white',
                            'titleClass': 'text-white' ,
                            'bgClass': 'bg-sunset'
                        },
                        'style': {
                            'bgColor': '#ffffff',
                            'gardientColor': {
                                'top': '#DE5697',
                                'bottom': '#6E56DF'
                            },
                            'titleColor': '#ffffff',
                            'buttonColor': '#ffffff',
                            'buttonTextColor': '#292D34',
                            'iconsColor': '#ffffff',
                        }
                    }
                },
                {
                    'title' : 'Hot Gradient',
                    'cssClass' : 'hot-gradient',
                    'custom': {
                        'typeBg': 'gradientTemplates',
                        'refBtn': '1shape',
                        'refTheme': '1gradientTemplates',
                        'class': {
                            'fontSize': 'font-xl',
                            'fontFamily': 'ff-BebasNeue',
                            
                            'btn': {
                                'name': 'btn-regular',
                                'bg': 'bg-white',
                                'color': 'text-dark'
                            },

                            'socialClass': 'text-white',
                            'titleClass': 'text-white' ,
                            'bgClass': 'bg-hot'
                        },
                        'style': {
                            'bgColor': '#ffffff',
                            'gardientColor': {
                                'top': '#FF5A5A',
                                'bottom': '#C900A8'
                            },
                            'titleColor': '#ffffff',
                            'buttonColor': '#ffffff',
                            'buttonTextColor': '#292D34',
                            'iconsColor': '#ffffff',
                        }
                    }
                },
                {
                    'title' : 'Light Turquoise ',
                    'cssClass' : 'light-turquoise',
                    'custom': {
                        'typeBg': 'gradientTemplates',
                        'refBtn': '2shadow',
                        'refTheme': '2gradientTemplates',
                        'class': {
                            'fontSize': 'font-s',
                            'fontFamily': 'ff-Inter',
                            
                            'btn': {
                                'name': 'btn-rounded shadow',
                                'bg': 'bg-turquoise',
                                'color': 'text-white'
                            },

                            'socialClass': 'text-turquoise',
                            'titleClass': 'text-turquoise' ,
                            'bgClass': 'bg-turquoiselight'
                        },
                        'style': {
                            'bgColor': '#ffffff',
                            'gardientColor': {
                                'top': '#FFF5FB',
                                'bottom': '#EDFCFF'
                            },
                            'titleColor': '#8D779A',
                            'buttonColor': '#8D779A',
                            'buttonTextColor': '#ffffff',
                            'iconsColor': '#8D779A',
                        }
                    }
                },
                {
                    'title' : 'Grey Caramel',
                    'cssClass' : 'grey-caramel',
                    'custom': {
                        'typeBg': 'gradientTemplates',
                        'refBtn': '2shadow',
                        'refTheme': '3gradientTemplates',
                        'class': {
                            'fontSize': 'font-s',
                            'fontFamily': 'ff-Montserrat',
                            
                            'btn': {
                                'name': 'btn-rounded shadow',
                                'bg': 'bg-greycaramel',
                                'color': 'text-darkgrey'
                            },

                            'socialClass': 'text-greycaramel',
                            'titleClass': 'text-greycaramel' ,
                            'bgClass': 'bg-greycaramel'
                        },
                        'style': {
                            'bgColor': '#ffffff',
                            'gardientColor': {
                                'top': '#CBD1B0',
                                'bottom': '#92A0BA'
                            },
                            'titleColor': '#FAFFD8',
                            'buttonColor': '#FAFFD8',
                            'buttonTextColor': '#1B304E',
                            'iconsColor': '#FAFFD8',
                        }
                    }
                },
                {
                    'title' : 'Grey Italic',
                    'cssClass' : 'grey-italic',
                    'custom': {
                        'typeBg': 'gradientTemplates',
                        'refBtn': '2outline',
                        'refTheme': '4gradientTemplates',
                        'class': {
                            'fontSize': 'font-s',
                            'fontFamily': 'ff-Merriweather',
                            
                            'btn': {
                                'name': 'btn-rounded-outline',
                                'bg': 'bg-none',
                                'color': 'text-greyitalic'
                            },

                            'socialClass': 'text-greyitalic',
                            'titleClass': 'text-greyitalic' ,
                            'bgClass': 'bg-greyitalic'
                        },
                        'style': {
                            'bgColor': '#ffffff',
                            'gardientColor': {
                                'top': '#FFFFFF',
                                'bottom': '#DEE4EC'
                            },
                            'titleColor': '#555E6F',
                            'buttonColor': '#555E6F',
                            'buttonTextColor': '#555E6F',
                            'iconsColor': '#555E6F',
                        }
                    }
                },
                {
                    'title' : 'Light Green',
                    'cssClass' : 'light-green',
                    'custom': {
                        'typeBg': 'gradientTemplates',
                        'refBtn': '2shadow',
                        'refTheme': '5gradientTemplates',
                        'class': {
                            'fontSize': 'font-m',
                            'fontFamily': 'ff-Roboto',
                            
                            'btn': {
                                'name': 'btn-rounded shadow',
                                'bg': 'bg-lightgreen',
                                'color': 'text-lightgreen'
                            },

                            'socialClass': 'text-lightgreen',
                            'titleClass': 'text-lightgreen' ,
                            'bgClass': 'bg-lightgreengradient'
                        },
                        'style': {
                            'bgColor': '#ffffff',
                            'gardientColor': {
                                'top': '#ADE367',
                                'bottom': '#51BF9E'
                            },
                            'titleColor': '#2B5173',
                            'buttonColor': '#DEFFB4',
                            'buttonTextColor': '#2B5173',
                            'iconsColor': '#2B5173',
                        }
                    }
                },
                {
                    'title' : 'Glam',
                    'cssClass' : 'glam',
                    'custom': {
                        'typeBg': 'gradientTemplates',
                        'refBtn': '2shape',
                        'refTheme': '6gradientTemplates',
                        'class': {
                            'fontSize': 'font-m',
                            'fontFamily': 'ff-Montserrat',
                            
                            'btn': {
                                'name': 'btn-square',
                                'bg': 'bg-glam',
                                'color': 'text-white'
                            },

                            'socialClass': 'text-glam',
                            'titleClass': 'text-glam' ,
                            'bgClass': 'bg-glamgradient'
                        },
                        'style': {
                            'bgColor': '#ffffff',
                            'gardientColor': {
                                'top': '#A7EEEA',
                                'bottom': '#F17BDE'
                            },
                            'titleColor': '#543567',
                            'buttonColor': '#543567',
                            'buttonTextColor': '#ffffff',
                            'iconsColor': '#543567',
                        }
                    }
                },
                {
                    'title' : 'Deep Blue',
                    'cssClass' : 'deep-blue',
                    'custom': {
                        'typeBg': 'gradientTemplates',
                        'refBtn': '1shadow',
                        'refTheme': '7gradientTemplates',
                        'class': {
                            'fontSize': 'font-m',
                            'fontFamily': 'ff-Inter',
                            
                            'btn': {
                                'name': 'btn-regular shadow',
                                'bg': 'bg-white',
                                'color': 'text-deepblue'
                            },

                            'socialClass': 'text-white',
                            'titleClass': 'text-white' ,
                            'bgClass': 'bg-deepblue'
                        },
                        'style': {
                            'bgColor': '#ffffff',
                            'gardientColor': {
                                'top': '#4FBDFA',
                                'bottom': '#7E42FF'
                            },
                            'titleColor': '#ffffff',
                            'buttonColor': '#ffffff',
                            'buttonTextColor': '#273E5F',
                            'iconsColor': '#ffffff',
                        }
                    }
                }
            ],
            'imageTemplates' : [
                {
                    'title' : 'Pink Beach Sand',
                    'cssClass' : 'pink-beach-sand',
                    'custom': {
                        'typeBg': 'imageTemplates',
                        'refBtn': '2shape',
                        'refTheme': '0imageTemplates',
                        'class': {
                            'fontSize': 'font-s',
                            'fontFamily': 'ff-Roboto',
                            
                            'btn': {
                                'name': 'btn-rounded',
                                'bg': 'bg-pinkbeach',
                                'color': 'text-dark'
                            },

                            'socialClass': 'text-pinkbeach',
                            'titleClass': 'text-pinkbeach' ,
                            'bgClass': 'pink-beach-sand',
                            
                        },
                        'style': {
                            'bgurl': '/img/pink-beach-sand-full.webp',
                            'gardientColor': {
                                'top': '#ffffff',
                                'bottom': '#ffffff'
                            },
                            'titleColor': '#FFF5C6',
                            'buttonColor': '#FFF5C6',
                            'buttonTextColor': '#292D34',
                            'iconsColor': '#FFF5C6',
                        }
                    }
                },
                {
                    'title' : 'Purple Paper',
                    'cssClass' : 'purple-paper',
                    'custom': {
                        'typeBg': 'imageTemplates',
                        'refBtn': '1shape',
                        'refTheme': '1imageTemplates',
                        'class': {
                            'fontSize': 'font-xs',
                            'fontFamily': 'ff-BakbakOne',
                            
                            'btn': {
                                'name': 'btn-regular',
                                'bg': 'bg-purplepaper',
                                'color': 'text-purplepaper'
                            },

                            'socialClass': 'text-dark',
                            'titleClass': 'text-white' ,
                            'bgClass': 'purple-paper'
                        },
                        'style': {
                            'bgurl': '/img/purple-paper-full.webp',
                            'gardientColor': {
                                'top': '#ffffff',
                                'bottom': '#ffffff'
                            },
                            'titleColor': '#ffffff',
                            'buttonColor': '#DFFBFF',
                            'buttonTextColor': '#4C5B74',
                            'iconsColor': '#ffffff',
                        }
                    }
                },
                {
                    'title' : '3D Copper Paper',
                    'cssClass' : 'copper-paper',
                    'custom': {
                        'typeBg': 'imageTemplates',
                        'refBtn': '1shadow',
                        'refTheme': '2imageTemplates',
                        'class': {
                            'fontSize': 'font-s',
                            'fontFamily': 'ff-Inter',
                            
                            'btn': {
                                'name': 'btn-regular shadow',
                                'bg': 'bg-white',
                                'color': 'text-copperpaper'
                            },

                            'socialClass': 'text-copperpaper',
                            'titleClass': 'text-copperpaper' ,
                            'bgClass': 'copper-paper'
                        },
                        'style': {
                            'bgurl': '/img/3d-copper-paper-full.webp',
                            'gardientColor': {
                                'top': '#ffffff',
                                'bottom': '#ffffff'
                            },
                            'titleColor': '#634629',
                            'buttonColor': '#ffffff',
                            'buttonTextColor': '#634629',
                            'iconsColor': '#634629',
                        }
                    }
                },
                {
                    'title' : 'Eco Green',
                    'cssClass' : 'eco-green',
                    'custom': {
                        'typeBg': 'imageTemplates',
                        'refBtn': '0shape',
                        'refTheme': '3imageTemplates',
                        'class': {
                            'fontSize': 'font-s',
                            'fontFamily': 'ff-Roboto',
                            
                            'btn': {
                                'name': 'btn-square',
                                'bg': 'bg-white',
                                'color': 'text-ecogreen'
                            },

                            'socialClass': 'text-white',
                            'titleClass': 'text-white' ,
                            'bgClass': 'bg-white'
                        },
                        'style': {
                            'bgurl': '/img/eco-green-full.webp',
                            'gardientColor': {
                                'top': '#ffffff',
                                'bottom': '#ffffff'
                            },
                            'titleColor': '#ffffff',
                            'buttonColor': '#ffffff',
                            'buttonTextColor': '#035A00',
                            'iconsColor': '#ffffff',
                        }
                    }
                },
                {
                    'title' : 'Warm Wood',
                    'cssClass' : 'warm-wood',
                    'custom': {
                        'typeBg': 'imageTemplates',
                        'refBtn': '1shadow',
                        'refTheme': '4imageTemplates',
                        'class': {
                            'fontSize': 'font-s',
                            'fontFamily': 'ff-Roboto',
                            
                            'btn': {
                                'name': 'btn-regular shadow',
                                'bg': 'bg-dark',
                                'color': 'text-white'
                            },

                            'socialClass': 'text-dark',
                            'titleClass': 'text-dark' ,
                            'bgClass': 'bg-white'
                        },
                        'style': {
                            'bgurl': '/img/warm-wood-full.webp',
                            'gardientColor': {
                                'top': '#ffffff',
                                'bottom': '#ffffff'
                            },
                            'titleColor': '#292D34',
                            'buttonColor': '#292D34',
                            'buttonTextColor': '#ffffff',
                            'iconsColor': '#292D34',
                        }
                    }
                },
                {
                    'title' : 'Copper',
                    'cssClass' : 'copper',
                    'custom': {
                        'typeBg': 'imageTemplates',
                        'refBtn': '1shadow',
                        'refTheme': '5imageTemplates',
                        'class': {
                            'fontSize': 'font-m',
                            'fontFamily': 'ff-Roboto',
                            
                            'btn': {
                                'name': 'btn-regular shadow',
                                'bg': 'bg-white',
                                'color': 'text-copper'
                            },

                            'socialClass': 'text-white',
                            'titleClass': 'text-white' ,
                            'bgClass': 'bg-white'
                        },
                        'style': {
                            'bgurl': '/img/copper-full.webp',
                            'gardientColor': {
                                'top': '#ffffff',
                                'bottom': '#ffffff'
                            },
                            'titleColor': '#ffffff',
                            'buttonColor': '#ffffff',
                            'buttonTextColor': '#02171D',
                            'iconsColor': '#ffffff',
                        }
                    }
                },
                {
                    'title' : 'Blue Sand',
                    'cssClass' : 'blue-sand',
                    'custom': {
                        'typeBg': 'imageTemplates',
                        'refBtn': '2shadow',
                        'refTheme': '6imageTemplates',
                        'class': {
                            'fontSize': 'font-s',
                            'fontFamily': 'ff-Roboto',
                            
                            'btn': {
                                'name': 'btn-rounded shadow',
                                'bg': 'bg-white',
                                'color': 'text-bluesand'
                            },

                            'socialClass': 'text-white',
                            'titleClass': 'text-white' ,
                            'bgClass': 'bg-white'
                        },
                        'style': {
                            'bgurl': '/img/blue-sand-full.webp',
                            'gardientColor': {
                                'top': '#ffffff',
                                'bottom': '#ffffff'
                            },
                            'titleColor': '#ffffff',
                            'buttonColor': '#ffffff',
                            'buttonTextColor': '#3769AA',
                            'iconsColor': '#ffffff',
                        }
                    }
                },
                {
                    'title' : 'Aurora Borealis',
                    'cssClass' : 'aurora-borealis',
                    'custom': {
                        'typeBg': 'imageTemplates',
                        'refBtn': '2shadow',
                        'refTheme': '7imageTemplates',
                        'class': {
                            'fontSize': 'font-s',
                            'fontFamily': 'ff-AnekMalayalam',
                            
                            'btn': {
                                'name': 'btn-rounded shadow',
                                'bg': 'bg-white',
                                'color': 'text-auroraborealis'
                            },

                            'socialClass': 'text-white',
                            'titleClass': 'text-white' ,
                            'bgClass': 'bg-white'
                        },
                        'style': {
                            'bgurl': '/img/aurora-borealis-full.webp',
                            'gardientColor': {
                                'top': '#ffffff',
                                'bottom': '#ffffff'
                            },
                            'titleColor': '#ffffff',
                            'buttonColor': '#ffffff',
                            'buttonTextColor': '#02171D',
                            'iconsColor': '#ffffff',
                        }
                    }
                }
            ]
        }

        return {
            dataTheme,
            refsTheme,
            refsInput
        }
    }
});
</script>