
import {defineComponent} from 'vue';

export default defineComponent({
    name: 'ShortenForm',
    components:{
    },
    data() {
    return {
      focused: false,
    }
  }
});
