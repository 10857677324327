
import {defineComponent} from 'vue';

export default defineComponent({
    name: 'Accordion',
    components:{},
    emits: ['update:accordion'],
    props: {
        'title' : String,
        'subtitle' : String,
        'show' : {
            type: Boolean,
            default: false
        },
        'lock': {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            showAccordion : this.show ? this.show : false,
        }
    },
    watch: {
        showAccordion(e) {
            this.$emit('update:accordion',e)
        }
    }
});
