<template>
  <form action="https://app.pixelfy.me/tracking-links/any-url" class="shorten-form needs-validation " method="GET" novalidate="">
      <input type="hidden" name="fast" value="1">
      <input class="shorten-input" type="text" name="url" required @focus="focused = true" @blur="focused = false">
      <span class="shorten-placeholder" v-show="!focused">Fast shortener  <span>— Enter URL here</span></span>
      <button class="btn-lib btn-lib-primary shorten-btn" type="submit">Shorten</button>
  </form>
</template>

<script lang="ts">
import {defineComponent} from 'vue';

export default defineComponent({
    name: 'ShortenForm',
    components:{
    },
    data() {
    return {
      focused: false,
    }
  }
});
</script>

<style lang="scss">
.shorten {
  &-form {
    background: 0 0;
    border: 1px solid #C4D1DE;
    border-radius: 6px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 424px;
    width: 100%;
    margin-left: 66px !important;
  }

  &-input {
    border: 0;
    min-height: 42px;
    background: 0 0;
    padding: 10px 20px;
    width: calc(100% - 95px);
    position: relative;
    z-index: 1;
  }

  &-placeholder {
    position: absolute;
    left: 20px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #292D34;
    z-index: 0;
    span {
      color: #898C91;
    }
  }

  &-btn {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }
}

@media screen and (max-width: 991px) {
  .shorten-form {
    display: none;
  }
}
</style>