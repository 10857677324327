<template>
    <div class="analitics-container">
        <div class="analitics-legend">
            <div class="legend-item">
                <div class="title"  v-tippy="`The total number of views your PixelID has.`">
                    <div class="color-view"></div>Page views
                </div>
                <div class="numbers">
                    <div class="numbers-count">
                        <template v-if="unique !== 1">
                            {{dataInfo.page.clicks_total_count}}
                        </template>
                        <template v-else>
                            {{dataInfo.page.clicks_unique_count}}
                        </template>
                    </div>

                    <template v-if="unique !== 1">
                        <div v-tippy="`${dataInfo.page.clicks_total_trend_percent}% views to previous period`" class="numbers-rate" :class="[{'text-gray': dataInfo.page.clicks_total_trend_percent == 0}, {'text-red': dataInfo.page.clicks_total_trend_percent < 0}] ">
                            <svg class="icon" v-if="dataInfo.page.clicks_total_trend_percent != 0">
                                <use v-if="dataInfo.page.clicks_total_trend_percent > 0" xlink:href="#arrow-up"></use>
                                <use v-if="dataInfo.page.clicks_total_trend_percent < 0" xlink:href="#arrow-down"></use>
                            </svg>
                            <template v-if="dataInfo.page.clicks_total_trend_percent > 0">+</template>{{dataInfo.page.clicks_total_trend_percent}}%
                        </div>
                    </template>
                    <template  v-else>
                        <div v-tippy="`${dataInfo.page.clicks_unique_trend_percent}% views to previous period`" class="numbers-rate" :class="[{'text-gray': dataInfo.page.clicks_unique_trend_percent == 0}, {'text-red': dataInfo.page.clicks_unique_trend_percent < 0}] ">
                            <svg class="icon" v-if="dataInfo.page.clicks_unique_trend_percent != 0">
                                <use v-if="dataInfo.page.clicks_unique_trend_percent > 0" xlink:href="#arrow-up"></use>
                                <use v-if="dataInfo.page.clicks_unique_trend_percent < 0" xlink:href="#arrow-down"></use>
                            </svg>
                            <template v-if="dataInfo.page.clicks_unique_trend_percent > 0">+</template>
                            {{dataInfo.page.clicks_unique_trend_percent}}%
                        </div>
                    </template>
                    
                </div>
            </div>
            <div class="legend-item">
                <div class="title" v-tippy="`How many times your PixelID links have been clicked on.`">
                    <div class="color-clicks--b"></div>Links clicks
                </div>
                <div class="numbers">
                    <div class="numbers-count">
                        <template v-if="unique !== 1">
                            {{dataInfo.simple.clicks_total_count}}
                        </template>
                        <template v-else>
                            {{dataInfo.simple.clicks_unique_count}}
                        </template>
                    </div>
                    <template v-if="unique !== 1">
                        <div v-tippy="`${dataInfo.simple.clicks_total_trend_percent}% links clicks to previous period`" class="numbers-rate" :class="[{'text-gray': dataInfo.simple.clicks_total_trend_percent == 0}, {'text-red': dataInfo.simple.clicks_total_trend_percent < 0}] ">
                            <svg class="icon" v-if="dataInfo.simple.clicks_total_trend_percent != 0">
                                <use v-if="dataInfo.simple.clicks_total_trend_percent > 0" xlink:href="#arrow-up"></use>
                                <use v-if="dataInfo.simple.clicks_total_trend_percent < 0" xlink:href="#arrow-down"></use>
                            </svg>
                            <template v-if="dataInfo.simple.clicks_total_trend_percent > 0">+</template>{{dataInfo.simple.clicks_total_trend_percent}}%
                        </div>
                    </template>
                    <template v-else>
                        <div v-tippy="`${dataInfo.simple.clicks_unique_trend_percent}% links clicks to previous period`" class="numbers-rate" :class="[{'text-gray': dataInfo.simple.clicks_unique_trend_percent == 0}, {'text-red': dataInfo.simple.clicks_unique_trend_percent < 0}] ">
                            <svg class="icon" v-if="dataInfo.simple.clicks_unique_trend_percent != 0">
                                <use v-if="dataInfo.simple.clicks_unique_trend_percent > 0" xlink:href="#arrow-up"></use>
                                <use v-if="dataInfo.simple.clicks_unique_trend_percent < 0" xlink:href="#arrow-down"></use>
                            </svg>
                            <template v-if="dataInfo.simple.clicks_unique_trend_percent > 0">+</template>{{dataInfo.simple.clicks_unique_trend_percent}}%
                        </div>
                    </template>
                    
                </div>
            </div>
            <div class="legend-item">
                <div class="title" v-tippy="`How many times your social icons have been clicked on.`">
                    <div class="color-clicks"></div>Social clicks
                </div>
                <div class="numbers">
                    <div class="numbers-count">
                        <template v-if="unique !== 1">
                            {{dataInfo.social.clicks_total_count}}
                        </template>
                        <template v-else>
                            {{dataInfo.social.clicks_unique_count}}
                        </template>
                    </div>
                    <template v-if="unique !== 1">
                        <div v-tippy="`${dataInfo.social.clicks_total_trend_percent}% social links clicks to previous period`" class="numbers-rate" :class="[{'text-gray': dataInfo.social.clicks_total_trend_percent == 0}, {'text-red': dataInfo.social.clicks_total_trend_percent < 0}] ">
                            <svg class="icon" v-if="dataInfo.social.clicks_total_trend_percent != 0">
                                <use v-if="dataInfo.social.clicks_total_trend_percent > 0" xlink:href="#arrow-up"></use>
                                <use v-if="dataInfo.social.clicks_total_trend_percent < 0" xlink:href="#arrow-down"></use>
                            </svg>
                            <template v-if="dataInfo.social.clicks_total_trend_percent > 0">+</template>{{dataInfo.social.clicks_total_trend_percent}}%
                        </div>
                    </template>
                    <template v-else>
                        <div v-tippy="`${dataInfo.social.clicks_unique_trend_percent}% social links clicks to previous period`" class="numbers-rate" :class="[{'text-gray': dataInfo.social.clicks_unique_trend_percent == 0}, {'text-red': dataInfo.social.clicks_unique_trend_percent < 0}] ">
                            <svg class="icon" v-if="dataInfo.social.clicks_unique_trend_percent != 0">
                                <use v-if="dataInfo.social.clicks_unique_trend_percent > 0" xlink:href="#arrow-up"></use>
                                <use v-if="dataInfo.social.clicks_unique_trend_percent < 0" xlink:href="#arrow-down"></use>
                            </svg>
                            <template v-if="dataInfo.social.clicks_unique_trend_percent > 0">+</template>{{dataInfo.social.clicks_unique_trend_percent}}%
                        </div>
                    </template>
                </div>
            </div>
            <div class="legend-item" v-tippy="`The number of clicks that your link receives divided by the number of times it is shown.`">
                <div class="title">CTR</div>
                <div class="numbers">
                    <div class="numbers-count" :class="[{'text-gray': dataInfo.ctr == 0}, {'text-red': dataInfo.ctr < 0}]" >{{dataInfo.ctr}}%</div>
                </div>
            </div>
        </div>
        <div class="analitics-graph">
            <div v-if="loaded" class="chartdiv" ref="chartdiv" id="chartdiv"></div>
            <Skeleton v-if="!loaded" class="chartdiv d-block"></Skeleton>

            <img v-if="Object.values(dataChart).length === 0" src="@/assets/images/no-data.svg" alt="Empty data" class="w-100 no-data">
        </div>
    </div>
</template>

<script lang="ts">
import {defineComponent} from 'vue';
import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import Skeleton from '../Skeleton.vue';


export default defineComponent({
    name: 'AnaliticsLineChart',
    components:{ Skeleton },
    props: ['chart', 'chartInfo', 'chartUnique'],
    data() {
        return {
            root: '' as any,
            dataChartFull: this.chart[0] || [],
            dataInfo: this.chartInfo[0] || [],
            dataChart: [] as any,
            unique: this.chartUnique,
            loaded: false,
        }
    },
    watch: {
        chart(val) {
            this.dataChartFull = val[0];
            this.reloadChart();

        },
        chartInfo(val) {
            this.loaded = false;
            this.dataInfo = val[0];
        },
        
        chartUnique(val){
            this.unique = val;
            this.reloadChart();
        }
        
    },
    methods: {
        reloadChart(){
            this.loaded = false;
            this.root.dispose();

            this.dataChart = [];

            if(this.unique === 1) {
                Object.values(this.dataChartFull.page).forEach((element:any, index) => {
                    this.dataChart.push({
                        'date': element.date,
                        'page': this.dataChartFull.page[index].unique,
                        'simple': this.dataChartFull.simple[index].unique,
                        'social': this.dataChartFull.social[index].unique,
                    })
                });
            }else{
                Object.values(this.dataChartFull.page).forEach((element:any, index) => {
                    this.dataChart.push({
                        'date': element.date,
                        'page': this.dataChartFull.page[index].total,
                        'simple': this.dataChartFull.simple[index].total,
                        'social': this.dataChartFull.social[index].total,
                    })
                });
            }

            setTimeout(() =>{
                this.loaded = true;
                setTimeout(() =>{
                    this.loadChart(this.dataChart);
                }, 200)
            }, 800)
        },

       loadChart($data: any)  {

            let root = am5.Root.new((this.$refs.chartdiv as HTMLInputElement));

            // Set themes
            // https://www.amcharts.com/docs/v5/concepts/themes/
            root.setThemes([
                am5themes_Animated.new(root)
            ]);
            // Create chart
            // https://www.amcharts.com/docs/v5/charts/xy-chart/
            var chart = root.container.children.push(am5xy.XYChart.new(root, {
                panX: false,
                panY: false,
                pinchZoomX: false
            }));
            // The data

            let data = $data;

             // Create axes
            // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
            let xAxis = chart.xAxes.push(am5xy.CategoryAxis.new(root, {
                categoryField: "date",
                startLocation: 0.5,
                endLocation: 0.5,
                renderer: am5xy.AxisRendererX.new(root, {}),
                tooltip: am5.Tooltip.new(root, {})
            }));

            xAxis.data.setAll(data);
                
                // Add cursor
                // https://www.amcharts.com/docs/v5/charts/xy-chart/cursor/
                let cursor = chart.set("cursor", am5xy.XYCursor.new(root, {
                    behavior: "none"
                }));

                cursor.lineY.set("visible", false);

               

                let yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
                    renderer: am5xy.AxisRendererY.new(root, {})
                }));

                // Add series
                // https://www.amcharts.com/docs/v5/charts/xy-chart/series/

                function createSeries(name:any, field:any, fieldColor:any) {
                    let series = chart.series.push(am5xy.LineSeries.new(root, {
                        name: name,
                        xAxis: xAxis,
                        yAxis: yAxis,
                        stacked:true,
                        valueYField: field,
                        fill: fieldColor,
                        categoryXField: "date",
                        tooltip: am5.Tooltip.new(root, {
                        pointerOrientation: "horizontal",
                        labelText: "[bold]{name}[/]\n{categoryX}: {valueY}"
                        })
                    }));

                    series.fills.template.setAll({
                        fillOpacity: 0.7,
                        visible: true
                    });

                    series.data.setAll(data);
                    series.appear(1000);
                }

                createSeries("Page views", "page", am5.color(0x82A9F1));
                createSeries("Links clicks", "simple", am5.color(0x7C66DC));
                createSeries("Social clicks", "social", am5.color(0xBE4CB8) );


                chart.appear(1000, 100);


            this.root = root;


       }
    },
    mounted() {
        // < !--Chart code-- >
        am5.addLicense("AM5C335843595");
        am5.addLicense("AM5M335847822");
        this.loadChart(this.dataChart);

    },
    created() {
        this.dataChart = [] as any;

        Object.values(this.dataChartFull?.page).forEach((element:any, index) => {
            this.dataChart.push({
                'date': element.date,
                'page': this.dataChartFull.page[index].unique,
                'simple': this.dataChartFull.simple[index].unique,
                'social': this.dataChartFull.social[index].unique,
            })
        });

        this.loaded = true;
    }
      
});
</script>

<style lang="scss">
  .chartdiv {
    min-height: 450px;
    margin: 0 -20px;
    width: calc(100% + 40px);
  }
</style>