<template>
    <draggable class="dragArea" handle=".drag-drop" v-model="linksItems" @change="draggableChange" v-bind="dragOptions"  @start="isDragging=true" @end="isDragging=false">
        <LinkButtonsItem v-for="(link, index) in linksItems"  :key="link.id"
            :data="link" 
            :index="index"
            @openModal = openModal(index)
            @setLinkName="setLinkName"
            @setLinkUrl="setLinkUrl"
            @publishEvent="publishEvent"
            @deleteEvent="deleteEvent" 
            />
    </draggable>
    <button @click="addEvent" class="btn-lib btn-lib-primary">+ Add link</button>

    <Modal
        :close-overflow="false"
        :show="showModal" 
        @close="closeModal" 
        @save="saveModal" 
        modalTitle="Select icon for this button"
        :classBody="'selIcon'">
        <div  class="btn-preview">
            <component v-if="icons[modalIcon]"
                    :class="{'selected-icon' : modalIcon !== 'PhotoIcon'}"
                    size="24"
                    :stroke-width="strokeWidth"
                    :is="icons[modalIcon]"
                    :style="{ color }"
                />
                <img v-else :src="modalIcon" alt="">
            <div class="text">{{linksItems[selectIndex]?.title || 'Link title'}}</div></div>
        <div class="tabnav">
            <button @click="tabModal = 0" class="tabnav-item" :class="{'active' : tabModal === 0}">Select from library</button>
            <button @click="tabModal = 1" class="tabnav-item" :class="{'active' : tabModal === 1}">Upload my own icon</button>
        </div>
        <div v-show="tabModal == 0">
            <SelectedIcon @icon="getIcon" />
        </div>
        <div v-show="tabModal == 1">
            <div class="drop-container text-left">
                <div class="drop-item">
                    <h6 class="title">Upload your icon here</h6>
                    <p class="subtitle">PNG, JPG, SVG, or GIF, minimum resolution 48x48px</p>
                    <div >
                        <UploadImage
                            v-show="cropImage == ''"
                            :clearAll="showModal" 
                            :max="1"  
                            @changeImage="changeImage"
                            :maxSize="1" /> 

                        <cropper
                            class="cropper"
                            :src="modalIcon"
                            ref="cropper" 
                            @change="changeCropper"
                        />
                    </div>
                    
                </div>
            </div>

            
        </div>
    </Modal>
</template>

<script lang="ts">
import * as icons from 'vue-tabler-icons';
import { defineComponent} from 'vue';
import { VueDraggableNext } from 'vue-draggable-next';
import Modal from '../Modal.vue';
import SelectedIcon from './SelectedIcon.vue';
import UploadImage from '@/components/common//UploadImage.vue';
import LinkButtonsItem from './LinkButtonsItem.vue';
import {mapGetters} from 'vuex';
import { Cropper } from 'vue-advanced-cropper'
import 'vue-advanced-cropper/dist/style.css';

export interface LinkItem {
  id: number;
  type: string;
  options: {
    favicon: any;
  };
  title: string;
  url: string;
  status: string;
  order: number;
}

export default defineComponent({
    name: 'LinkButtons',
    components:{
        draggable: VueDraggableNext,
        Modal,
        SelectedIcon,
        UploadImage,
        LinkButtonsItem,
        Cropper
    },
    emits: ['links'],
    props: {
        data: {
            type: Object as any,
            required: true
        }
    },

    data() {
        return {
            enabled: true,
            linksItems: this.data as Array<LinkItem>,
            showModal: false,
            tabModal: 0,
            color: '#5B60CA',
            strokeWidth: '2',
            modalIcon: 'PhotoIcon',
            limit: 32,
            selectIndex: 0,
            backValue: {} as any,
            editable: true,
            isDragging: false,
            delayedDragging: false,
            cropImage: '',

        }
    },

    watch: {
        data(val) {
            this.linksItems = val;
        },
        isDragging(newValue) {
            if (newValue) {
                this.delayedDragging = true;
                return;
            }
            this.$nextTick(() => {
                this.delayedDragging = false;
            });
        }
    },

    methods : {
        addEvent () {
            let item = {
                "id": (new Date()).getTime(),
                "pp_tr_link_id": (new Date()).getTime(),
                "url": "",
                "type": this.getConstants.pp_inner_links.type[0],
                "title": "",
                "options": {
                    'favicon': "PhotoIcon"
                },
                "order": this.linksItems.length,
                "status": this.getConstants.common.status[0]
            };

            this.linksItems.push(item)
            this.saveData(this.linksItems)

        },

        publishEvent(event: {'item': any, 'index': number}) {
            this.linksItems[event.index].status = event.item.status;
            this.saveData(this.linksItems)
        },

        setLinkUrl(event: {'item': any, 'index': number}) {
            this.linksItems[event.index].url = event.item.url;

            this.saveData(this.linksItems)

        },        

        setLinkName(event: {'item': any, 'index': number}) {
            this.linksItems[event.index].title = event.item.title;

            this.saveData(this.linksItems)

        },
     
        deleteEvent: function(event: {'item': any, 'index': number}) {
            this.linksItems.splice(this.linksItems.indexOf(event.item), 1);
            this.saveData(this.linksItems)
        },

        draggableChange() {
             for (let i = 0; i < this.linksItems.length; i++) {
                this.linksItems[i].order = i;
            }
            
            this.saveData(this.linksItems)
        },

        closeModal(status: boolean) {
            this.showModal = status;
            this.modalIcon = 'PhotoIcon';
        },

        saveModal() {
            if(this.cropImage !='') {
                this.modalIcon = this.cropImage;
            }
            this.linksItems[this.selectIndex].options.favicon = this.modalIcon;
            this.saveData(this.linksItems)
        },

        getIcon(icon: string) {
            this.modalIcon = icon;
        },

        changeImage(event: string) {
            if(event[0]) {
                this.modalIcon = event[0];
            }
        },


        openModal(index: number) {
            this.showModal = !this.showModal;
            this.selectIndex = index;
            this.cropImage = '';
            this.modalIcon = 'PhotoIcon';

        },

        saveData(links:  Array<LinkItem>) {
            this.$emit('links', links)
        },

        changeCropper(item: {coordinates: any, canvas:any}) {
            this.cropImage = item.canvas?.toDataURL("image/webp");
        },
    },
    computed : {
        ...mapGetters(['getConstants']),

        icons() {
            return icons;
        },

        dragOptions() {
            return {
                animation: 300,
                ghostClass: "ghost"
            };
        },
    }
});
</script>

<style lang="scss">
    .back-item {
        font-family: 'Lato';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 28px;
        color: #292D34;
        margin-bottom: 1rem;
    }
    
    .selected-icon {
        color: #000000 !important;
    }

    .btn-preview {
        .text {
            overflow-wrap: break-word;
            display: inline-block;
            max-width: calc(100% - 34px);
        }
    }

</style>