<template>
    <div class="sidebar " :class="{'active': show}">
        <div class="sidebar-nav">
            <div v-if="loading" style="overflow: hidden; height: 100%;">
                <Skeleton  style="height: 100%;position:relative; width: 100%; display: inline-block;"/>
            </div>
            <template v-if="!loading">
                <template v-for="(items, title) in menuItem" :key="items">
                    <h6 v-if="String(title) !== 'main'" :class="{'hidden': !show}" class="sidebar-title">{{String(title).replace(/[_]/g, ' ')}}</h6>
                    <ul class="sidebar-menu">
                        <li v-for="(item, index) in items" :key="item">
                            <a :class="{'active': item.title == 'Pixel ID'}" :href="item.url">
                                <span class="icon">
                                    <svg class="icon-menu">
                                        <use :xlink:href="`#ic-${title}-${index}`"></use>
                                    </svg>
                                </span>
                                {{item.title}} 

                                <span v-if="item.title == 'Pixel ID'" class="sidebar-tag">Beta</span>

                                <span v-if="String(title) =='main' && index === 1 && getMainMenu.campaigns > 0" class="count-text">{{getMainMenu.campaigns}}</span>
                                <span v-else-if="String(title) =='main' && index === 2 && getMainMenu.links > 0" class="count-text">{{getMainMenu.links}}</span>
                            </a>
                        </li>
                    </ul>
                </template>
            </template>
        </div>
    
        <a :class="{'opacity-0': !show}" href="https://pixelfy.canny.io/feature-request" class="feature-link">Suggest a feature <svg width="22" height="22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M10 6H5a1 1 0 0 0-1 1v10a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1v-5h-1.5v4.5h-9v-9H10V6Z" fill="currentColor"></path>
            <path d="m9.886 12.381.071.071.07-.07 5.977-5.976v3.051a.6.6 0 1 0 1.2 0v-4.5a.6.6 0 0 0-.6-.6h-4.5a.6.6 0 1 0 0 1.2h3.051l-5.976 5.976-.07.07.07.071.707.707Z" fill="currentColor" stroke="currentColor" stroke-width=".2"></path>
            </svg></a>

        <button @click="menu = !menu" class="sidebar-minimizer" type="button">
            <span>Minimise menu</span>
        </button>
    </div>
</template>

<script lang="ts">
import {defineComponent} from 'vue';
import {mapGetters} from 'vuex';
import Skeleton from '../common/Skeleton.vue';

export default defineComponent({
    name: 'Sidebar',
    components:{
    Skeleton
},
    props:{
        show: Boolean,
    },
    emits: ['update:show'],
    data(){
        return {
            menu: true,
            cl: 'active',
            current: ['sub0'],
            open: ['sub1','sub2'],
            widthWindow: window.innerWidth,
            menuItem: [] as any,
            loading: true,
        }
    },
    watch:{
        show(val){
            this.menu = val
        },
        menu(val){
            this.$emit('update:show',val)
            new Promise( (resolve)=>{setTimeout(resolve, 200) } )
            .then( ()=> {
                this.cl = this.menu ? 'show' : ''
                document.body.style.overflow= this.menu ? 'show' : ''
            });
        },
        getMainMenu(val) {
            this.menuItem = val.menu
            this.loading = false;
        }

    },

    computed: mapGetters(['getMainMenu']),
    methods: {

        myEventHandler() {
            // your code for handling resize...
            let widthWindow = window.innerWidth;
            if(widthWindow < 1400) {
                this.menu = false;
            }else{
                this.menu = true;
            }

        }
    },

    created() {

        window.addEventListener("resize", this.myEventHandler);

        if(this.widthWindow < 1400) {
            this.menu = false;
        }
    },

    unmounted() {
        window.removeEventListener("resize", this.myEventHandler);
    },
});
</script>

<style lang="scss">
    .sidebar {
        position: fixed;
        left: 0;
        height: calc(100% - 78px);
        top: 78px;
        background: #fff;
        overflow: visible;
        width: 100%;
        max-width: 80px;
        transition: .3s;
        z-index: 5;

        &.active {
            max-width: 260px;
            .sidebar {
                &-nav {
                    overflow-y: auto;
                }

                &-menu {
                    li {
                        width: inherit;
                    }
                }

                &-minimizer {

                    span {
                        display: block;
                    }
                    
                    &::before {
                        transform: translate(-50%, -50%);
                    }
                }
            }

            +main {
                margin-left: 260px;

                ~ footer {
                    margin-left: 260px;
                }
            }
        }

        &-nav {
            padding: 10px 20px;
            height: calc(100% - 105px);
            width: 100%;
            overflow: visible;

            .icon-menu {
                width: 24px;
                height: 24px;
                margin-right: 12px;
            }
        }

        &-menu {
            li {
                margin-bottom: 8px;
                width: 40px;
                overflow: hidden;

                &:hover {
                    overflow: visible;
                    width: 250px;
                    a {
                        color: #292D34;
                        background: #EAEFEF;
                    }
                }

                a {
                    padding: 8px;
                    display: flex;
                    align-items: center;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 15px;
                    line-height: 150%;
                    color: #6B7280;
                    width: 100%;
                    white-space: nowrap;
                    transition: .3s;
                    border-radius: 8px;
                    text-decoration: none;

                    &:hover, &.active {
                       color: #292D34;
                        background: #EAEFEF;
                    }

                    .count-text {
                        margin-left: auto;
                    }
                }
            }
        }

        &-title {
            font-style: normal;
            font-weight: 700;
            font-size: 14px;
            line-height: 28px;
            letter-spacing: 1px;
            text-transform: uppercase;
            color: #292D34;
            padding-left: 8px;
            margin-top: 24px;
            margin-bottom: 8px;
            white-space: nowrap;

            &.hidden {
                margin: 29.5px 0;
                overflow: hidden;
                height: 1px;
                width: 100%;
                text-indent: -1000;
                border-top: 1px solid #CDD9E1;
            }
        }

        .feature-link {
            font-style: normal;
            font-weight: bold;
            font-size: 16px;
            line-height: 24px;
            color: #292D34;
            display: flex;
            align-items: center;
            margin-bottom: 24px;
            margin-top: 24px;
            text-decoration: none;
            margin: 0 1.25rem;
            white-space: nowrap;

            svg {
                margin-left: 4px;
            }

            &:hover {
                text-decoration: underline;
                color: #292D34;
            }
        }

        &-minimizer {
            background: #FFFFFF;
            padding: 17px 0;
            position: relative;
            width: 100%;
            border: 0;
            height: 58px;
            cursor: pointer;
            margin-top: 24px;
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            color: #6B7280;
            padding-left: 54px;
            text-align: left;

            span {
                display: none;
                white-space: nowrap;
            }


            &:before {
                content: "";
                background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M16 4L8 12L16 20' stroke='%236B7280' stroke-width='2'/%3E%3C/svg%3E%0A");
                width: 24px;
                height: 24px;
                position: absolute;
                top: 50%;
                left: 29.5px;
                transform:  translateY(-50%) rotate(180deg);
                background-size: 100%;
            }

            &:hover {
                background: #EAEFEF;
                color: #292D34;

                &:before {
                    background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M16 4L8 12L16 20' stroke='%236B7280' stroke-width='2'/%3E%3C/svg%3E%0A");
                }
            }
        }
        +main {
            margin-left: 80px;

            ~footer {
                margin-left: 80px;
            }
        }
    }

    @media screen and (max-width: 991px) {
       .sidebar {
            left: -260px;
            width: 260px;
            &.active {
                left: 0;
                z-index: 11;
                    
                +main {
                    margin-left: 0;

                    &:before {
                        content: "";
                        position: fixed;
                        top: 0;
                        left: 0;
                        bottom: 0;
                        right: 0;
                        z-index: 10;
                        width: 100%;
                        height: 100%;
                        animation: opacity 0.25s;
                        -webkit-backdrop-filter: blur(44px);
                        backdrop-filter: blur(44px);
                        background: #292D34;
                        opacity: .8;
                    }

                    ~footer {
                        margin-left: 0;
                    }
                }
            }
            +main {
                margin-left: 0;
                ~ footer {
                    margin-left: 0;
                }
            }

            &-toggler {
                width: 22px !important;
                padding: 0 !important;
            }
       }
    }
</style>