<template>
    <div class="dropdown select-lib" v-click-outside="vcoConfig">
        <span class="d-block"  @click="show = !show">
           {{selectedValue.title}}
        </span>
        <div class="dropdown-menu" :class="{'active': show}">
            <a @click="change(item)" href="#" class="dropdown-item" v-for="item in data" :key="item">  
                {{item.title}}
            </a>  
        </div>
    </div>
</template>

<script lang="ts">
import {defineComponent} from 'vue';

export default defineComponent({
    name: 'Select',
    components:{},
    props:['dataSelect', 'selectedIndex'],
    emits: ['change'],
    data() {
        return {
            show: false,
            selectedValue: this.dataSelect[this.selectedIndex] ? this.dataSelect[this.selectedIndex] : this.dataSelect[0],
            data: this.dataSelect ? this.dataSelect : [],
            vcoConfig: {
                middleware: this.middleware,
                events: ['click'],
                // Note: The default value is true, but in case you want to activate / deactivate
                //       this directive dynamically use this attribute.
                isActive: true,
                // Note: The default value is true. See "Detecting Iframe Clicks" section
                //       to understand why this behaviour is behind a flag.
                detectIFrame: true,
                // Note: The default value is false. Sets the capture option for EventTarget addEventListener method.
                //       Could be useful if some event's handler calls stopPropagation method preventing event bubbling.
                capture: true
            }
        }
    },

    watch: {
        selectedIndex(val) {
            this.selectedValue = val;
        },
        dataSelect(val) {
            this.data = val;
        },
    },
    

    methods: {
        change(item: any) {
            this.$emit('change', item);
            this.selectedValue = item;
            this.show = false;
        },
        
        middleware (event:any) {
            if(event) {
                this.show = false;
            }
        },
    },
    
});
</script>

<style lang="scss">
    .select-lib {
        min-width: 300px;
        span {
            cursor: pointer;
        }
        .dropdown-menu {
            width: 100%;
            &.active {
                top: calc(100% + 2px);
                left: 0;
            }
            .dropdown-item {
                font-size: 16px;
                color: #292D34;
                font-weight: normal;
            }
        }
    }
</style>