
import {defineComponent} from 'vue';


export default defineComponent({
    name: 'Modal',
    components:{},
    props: {
        modalTitle: String, 
        qrLink: String,
        show: {
            type: Boolean,
            default: false,
        },
        type: String,
        classBody: String,
        closeOverflow: {
            type: Boolean,
            default: true
        },
    },
    emits: ['close', 'save'],

    data() {
        return {
            modal: false,
            classModal: this.classBody ,
            copiedLink: false,
            vcoConfig: {
                middleware: this.middleware,
                events: ['click'],
                // Note: The default value is true, but in case you want to activate / deactivate
                //       this directive dynamically use this attribute.
                isActive: true,
                // Note: The default value is true. See "Detecting Iframe Clicks" section
                //       to understand why this behaviour is behind a flag.
                detectIFrame: true,
                // Note: The default value is false. Sets the capture option for EventTarget addEventListener method.
                //       Could be useful if some event's handler calls stopPropagation method preventing event bubbling.
                capture: true
            }
        }
    },
    watch:{
        show(val){
            this.modal = val
        },
        modal(val){
            this.$emit('close',val)
        }
    },
    methods: {
        saveSetting() {
            this.$emit('save', 'success');
            this.modal = false;
        },
        onClose() {
            this.modal = false;
        },

        middleware (event:any) {

            if(event.target.className !== this.classModal && event.target.className == 'modal' && this.closeOverflow) {
                this.modal = false;
                this.$emit('close',this.modal)
            }
        },

        async copyLink() {
            this.copiedLink = true;
            new Promise( (resolve)=>{setTimeout(resolve, 2000) } )
                .then( ()=> {
                this.copiedLink = false;
            });
        },

        keydownEsc(event:any) {
            if (event.code == 'Escape') {
                this.modal = false;
                this.$emit('close',this.modal)
            }
        }
    },

    mounted() {
        document.addEventListener('keydown', this.keydownEsc);
        
    }
});
