<template>
    <!-- <div :style="{ 'top': activeElement.top + 'px', 'left' : activeElement.left + 'px', 'width' : activeElement.width +'px', 'height': activeElement.height+'px' }" class="font-active"></div> -->
    <div class="fonts" v-for="(item, index) in family" :key="index">
        <div class="title">{{index.replace(/([A-Z])/g, ' $1')}}</div>

        <div class="fonts-items">

            <div  v-for="(value, i) in item" :key="value.className"
            @click="selectFamily(i+index, value)"
             class="exemple" :class="[{'active' : classNameFont === value.className},value.className]" :ref="el => { refs[i+index] = el }" >
                <div class="exemple-font">Aa</div>
                <div class="exemple-name">{{value.name}}</div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import {defineComponent, ref} from 'vue';

export default defineComponent({
    name: 'SelectFontFamily',
    components:{},
    props: {
        selected: String,
        updateSidebar: Boolean
    },
    emits: ['changeFontFamily'],
    data() {
        return {
            classNameFont: this.selected ? this.selected : '',
            activeElement : {
                top: 0,
                left: 0,
                height: 0,
                width: 0,
                index: '0classic' as any,
            },
            updateFonts: Boolean
        }
    },
    methods: {
        selectFamily(myref:any, item: {'name': string, 'className': string}) {
            this.classNameFont = item.className;
            this.$emit('changeFontFamily',item)
            this.activeElement.index = myref;
            // this.resizeWindowFonts();
        },

        resizeWindowFonts() {
            let top = (this.refs[this.activeElement.index] as HTMLInputElement).getBoundingClientRect().top;
            let left = (this.refs[this.activeElement.index] as HTMLInputElement).getBoundingClientRect().left;
            let height = (this.refs[this.activeElement.index] as HTMLInputElement).getBoundingClientRect().height;
            let width = (this.refs[this.activeElement.index] as HTMLInputElement).getBoundingClientRect().width;
            
            this.activeElement.top = top + window.pageYOffset ;
            this.activeElement.left =  left + window.pageXOffset;
            this.activeElement.height = height;
            this.activeElement.width = width;
        }


    },

    watch: {
        updateSidebar(val){
            this.updateFonts = val;
        },

        updateFonts() {
            new Promise( (resolve)=>{setTimeout(resolve, 300) } )
            .then( ()=> {
            //    this.resizeWindowFonts();
            });
            
        },
        selected(val) {
            this.classNameFont = val;
        }
    },

    created() {
        // window.addEventListener("resize", this.resizeWindowFonts);
    },
    
    setup() {
        const family = {
            'classic' : [
                {
                    'name' : 'Roboto Slab',
                    'className' : 'ff-RobotoSlab'
                },
                {
                    'name' : 'Merriweather',
                    'className' : 'ff-Merriweather'
                },
                {
                    'name' : 'Lora',
                    'className' : 'ff-Lora'
                },
                {
                    'name' : 'Bitter',
                    'className' : 'ff-Bitter'
                },
                {
                    'name' : 'Slabo 27px',
                    'className' : 'ff-Slabo27px'
                },
                {
                    'name' : 'Cinzel',
                    'className' : 'ff-Cinzel'
                },
                {
                    'name' : 'Suez One',
                    'className' : 'ff-SuezOne'
                }
            ],
            'modern': [
                {
                    'name': 'Roboto',
                    'className': 'ff-Roboto'
                },
                {
                    'name': 'Anek Malayalam',
                    'className': 'ff-AnekMalayalam'
                },
                {
                    'name': 'Montserrat',
                    'className': 'ff-Montserrat'
                },
                {
                    'name': 'Inter',
                    'className': 'ff-Inter'
                },
                {
                    'name': 'Bebas Neue',
                    'className': 'ff-BebasNeue'
                },
                {
                    'name': 'Bakbak One',
                    'className': 'ff-BakbakOne'
                },
                {
                    'name': 'Abel',
                    'className': 'ff-Abel'
                },
                {
                    'name': 'Chakra Petch',
                    'className': 'ff-ChakraPetch'
                },
                {
                    'name': 'Saira Semi Condensed',
                    'className': 'ff-SairaSemiCondensed'
                }
            ],

            'art': [
                {
                    'name': 'Dancing Script',
                    'className': 'ff-DancingScript'
                },
                {
                    'name': 'Lobster',
                    'className': 'ff-Lobster'
                },
                {
                    'name': 'Abril Fatface',
                    'className': 'ff-AbrilFatface'
                },
                {
                    'name': 'Indie Flower',
                    'className': 'ff-IndieFlower'
                },
                {
                    'name': 'Permanent Marker',
                    'className': 'ff-PermanentMarker'
                },
                {
                    'name': 'Amatic SC',
                    'className': 'ff-AmaticSC'
                },
                {
                    'name': 'Press Start 2P',
                    'className': 'ff-PressStart2P'
                }
                
            ]
        };

         const refs = ref([]);

        return {
            family,
            refs
        }
    }
});
</script>
<style scoped>
    /* .font-active {
        position: absolute;
        width: 130px;
        height: 120px;
        border: 2px solid #5B60CA;
        border-radius: 10px;
        top: 0;
        left: 10px;
        transition: .3s;
    } */

</style>