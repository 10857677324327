
    import {defineComponent} from 'vue';
   

    export default defineComponent({
        name: "Home",
        beforeCreate() {
            window.location.href = '/admin'
        },
    });

