
import {defineComponent} from 'vue';
import {mapGetters} from 'vuex';

export default defineComponent({
    name: 'Logo',
    components:{},
    computed: mapGetters(['getConstants']),

});
