<template>
    <div class="buttons">
        <!-- <div :style="{ 'top': activeElement.top + 'px', 'left' : activeElement.left + 'px', 'width' : activeElement.width +'px', 'height': activeElement.height+'px' }" class="buttons-active"></div> -->
        <div v-for="(item, index) in btn" :key="index">
            <div class="buttons-title">{{index.replace(/([A-Z])/g, ' $1')}}</div>
            <div class="buttons-row">
                <label  v-for="(value, i) in item" :key="value.className" 
                    :for="`btn-${i}${index}`" class="buttons-item">
                    <span class="buttons-border" :ref="el => { refs[i+index] = el }"  :class="{'active' : i+index == activeElement.index}">
                        <div class="mobile-btn" :class="value.className"></div>
                        <input :ref="el => { refsInput[i+index] = el }" @change="changeButton(i+index, value)" :id="`btn-${i}${index}`" type="radio" name="buttontype" :value="value.className">
                        <div class="buttons-name">{{value.name}}</div>
                    </span>
                </label>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import {defineComponent, ref} from 'vue';

export default defineComponent({
    name: 'AppearanceTypeButton',
    emits: ['changeButton'],
    props: {
        activeType: String,
        updateSize: Boolean
    },
    data() {
        return {
            // style: this.activeType,
            updateButton: Boolean,
            activeElement : {
                top: 0,
                left: 0,
                height: 122,
                width: 202,
                index: this.activeType as any,
            },
        }
    },
     methods: {
        changeButton(myref: any, item: any) {

            this.$emit('changeButton',item)
            this.activeElement.index = myref;
            this.resizeWindowButton();
             
        },
        resizeWindowButton() {
            // let top = (this.refs[this.activeElement.index] as HTMLInputElement).getBoundingClientRect().top;
            // let left = (this.refs[this.activeElement.index] as HTMLInputElement).getBoundingClientRect().left;
            // let height = (this.refs[this.activeElement.index] as HTMLInputElement).getBoundingClientRect().height;
            // let width = (this.refs[this.activeElement.index] as HTMLInputElement).getBoundingClientRect().width;
            
            // this.activeElement.top = top + window.pageYOffset ;
            // this.activeElement.left =  left + window.pageXOffset;
            // this.activeElement.height = height;
            // this.activeElement.width = width;

            (this.refsInput[this.activeElement.index] as HTMLInputElement).checked=true
        }


    },

    watch: {
        updateSize(val){
            this.updateButton = val;
        },

        updateButton() {
            new Promise( (resolve)=>{setTimeout(resolve, 300) } )
            .then( ()=> {
               this.resizeWindowButton();
            });
            
        },

        activeType(val) {
            this.activeElement.index = val;
            (this.refsInput[this.activeElement.index] as HTMLInputElement).checked=true
            // this.resizeWindowButton();

        }
    },

    // created() {
    //     window.addEventListener("resize", this.resizeWindowButton);
    // },

    setup() {
        const refs = ref([]);
        const refsInput = ref([]);

        const btn = {
            "shape" : [
                {
                    "name" : 'Square',
                    'className': 'btn-square'
                },
                {
                    "name" : 'Regular',
                    'className': 'btn-regular'
                },
                {
                    "name" : 'Rounded',
                    'className': 'btn-rounded'
                }
            ],
            "shadow" : [
                {
                    "name" : 'Square shadow',
                    'className': 'btn-square shadow'
                },
                {
                    "name" : 'Regular shadow',
                    'className': 'btn-regular shadow'
                },
                {
                    "name" : 'Rounded shadow',
                    'className': 'btn-rounded shadow'
                }
            ],
            "outline" : [
                {
                    "name" : 'Square  outline',
                    'className': 'btn-square-outline'
                },
                {
                    "name" : 'Regular outline',
                    'className': 'btn-regular-outline'
                },
                {
                    "name" : 'Rounded  outline',
                    'className': 'btn-rounded-outline'
                }
            ],
            "hardShadow" : [
                {
                    "name" : 'Square hard shadow',
                    'className': 'btn-square-outline hard-shadow'
                },
                {
                    "name" : 'Regular hard shadow',
                    'className': 'btn-regular-outline hard-shadow'
                },
                {
                    "name" : 'Rounded hard shadow',
                    'className': 'btn-rounded-outline hard-shadow'
                }
            ],
            "softShadow" : [
                {
                    "name" : 'Square soft shadow',
                    'className': 'btn-square-outline soft-shadow'
                },
                {
                    "name" : 'Regular soft shadow',
                    'className': 'btn-regular-outline soft-shadow'
                },
                {
                    "name" : 'Rounded soft shadow',
                    'className': 'btn-rounded-outline soft-shadow'
                }
            ],
        }

        return {
            refs,
            btn,
            refsInput
        }
    }
});
</script>