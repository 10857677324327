
import {defineComponent} from 'vue';

export default defineComponent({
    name: 'InputCount',
    emits: ['value'],
    components:{},
    props: {
        placeholder: String,
        maxsymbols: Number,
        value: String,
        inputClass: String,
        required: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            focused: false,
            inputValue: this.value ? this.value : '',
        }
    },
    watch: {
        value(val) {
            this.inputValue = val;
        }
    },
    methods: {
        changeInput(value: string) {
            this.$emit('value', value)
        },
       
    }
});
