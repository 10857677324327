<template>
    <div class="color" :class="classCustom">
        <div class="title">{{title}}</div>
        <div class="color-choice">
            <input type="color" class="input-lib" v-model="color">
            <input type="text" class="input-lib color-input" v-model="color">
            <ul class="color-items">
                <li v-for="(item, index) in previewColor" :key="index"
                @click="changeColor(item)"  :class="{'active' : color == item}" ></li>
            </ul>
        </div>
    </div>
</template>

<script lang="ts">
import {defineComponent} from 'vue';

export default defineComponent({
    name: 'SelectColor',
    components:{},
    emits: ['changeColor'],
    props: {
        selectColor: String,
        classCustom: String,
        title: String
    },
    data() {
        return {
            color: this.selectColor ? this.selectColor : '#F9F9F9'
        }
    },
    methods: {
        changeColor(color: string) {
            this.color = color;
        }
    },
    watch: {
        selectColor(val) {
            this.color = val;
            // console.log("selectColor update selectColor", this.color)
        },

        color(val){
            this.$emit('changeColor',val)
            // console.log("selectColor update color", this.color)
        },
    },
    setup(){
        const previewColor = ['#F9F9F9','#292D34','#5EA7DD','#5790E4','#616EE6','#6E56DF','#9959E1','#C059E3','#DE65D7','#DE5697','#EA755B','#F28E6E','#F0B65F','#FFD391','#9BE4B9','#5EC293']
        

        return {
            previewColor
        }
    }
});
</script>

<style lang="scss">
  
</style>