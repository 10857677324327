import { createApp } from 'vue'
import App from './App.vue'
// import './registerServiceWorker'
import router from './router'
import store from './store'
import './assets/scss/main.scss'
import '/node_modules/tippy.js/dist/tippy.css';

import VueSocialSharing from 'vue-social-sharing'
import vClickOutside from 'click-outside-vue3'
import VueClipboard from 'vue3-clipboard'
import {TippyPlugin} from 'tippy.vue';



createApp(App)
    .use(store)
    .use(router)
    .use(VueSocialSharing)
    .use(vClickOutside)
    .use(VueClipboard, {autoSetContainer: true,appendToBody: true,})
    .use(TippyPlugin, {
        tippyDefaults: {},
    })
    .mount('#app')