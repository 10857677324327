
import {defineComponent} from 'vue';

import AnaliticsLineChart from '../common/Analitics/AnaliticsLineChart.vue';
import AnaliticsLinkPerformance from '../common/Analitics/AnaliticsLinkPerformance.vue';
import AnaliticsSocialLinkPerformance from '../common/Analitics/AnaliticsSocialLinkPerformance.vue';
import AnaliticsTopReferrers from '../common/Analitics/AnaliticsTopReferrers.vue';
import AnaliticsTopPlatforms from '../common/Analitics/AnaliticsTopPlatforms.vue';
import AnaliticsTopLocations from '../common/Analitics/AnaliticsTopLocations.vue';
import AnaliticsTopBrowsers from '../common/Analitics/AnaliticsTopBrowsers.vue';
import Skeleton from '../common/Skeleton.vue';

export default defineComponent({
    name: 'Analitics',
    components:{
        AnaliticsLineChart,
        AnaliticsLinkPerformance,
        AnaliticsSocialLinkPerformance,
        AnaliticsTopReferrers,
        AnaliticsTopPlatforms,
        AnaliticsTopLocations,
        AnaliticsTopBrowsers,
        Skeleton
    },

    props: ['dataAnalitics', 'period', 'unique'],

    data() {
        return {
            loading: true,
            dataLine: [] as any,
            dataClicks: [] as any,
            dataLinksPerformance: [] as any,
            dataSocialPerformance: [] as any,
            dataTopPlatforms: [] as any,
            dataTopReferrers: [] as any,
            dataTopCities: [] as any,
            dataTopLocations: [] as any,
            dataTopBrowsers: [] as any,
            uniqueVal: this.unique,
            allData: this.dataAnalitics
        }
    },

    watch: {
        period() {
            this.loading = true;
        },

        unique(val) {
            this.loading = true;
            this.uniqueVal = val;
        },

        dataAnalitics(val) {
            this.loading = true;
            this.allData = val;

            this.createData(val);
        }
    },

    methods: {
        createData(val: any) {

            this.dataLine = [{
                page: {...val.page?.chart_clicks.main}, 
                simple:{...val.simple?.chart_clicks.main}, 
                social:{...val.social?.chart_clicks.main}
            }]

            this.dataClicks = [{
                page: {...val.page?.all_counted_clicks}, 
                simple:{...val.simple?.all_counted_clicks}, 
                social:{...val.social?.all_counted_clicks},
                ctr: val.main_ctr
            }]
            

            this.dataLinksPerformance = val.simple.chart_clicks.links_performance;
            
            this.dataSocialPerformance = val.social.chart_clicks.links_performance;

            this.dataTopReferrers = val.page.chart_clicks.referers;

            this.dataTopCities = val.page.chart_clicks.cities;

            this.dataTopPlatforms = [];

            if(val.page.chart_clicks.platforms) {
                Object.values(val.page.chart_clicks.platforms).forEach((element:any) => {
                    this.dataTopPlatforms.push(element)
                });
            }
            
            if(val.page.chart_clicks.countries) {
                this.dataTopLocations = val.page.chart_clicks.countries;
            }


            this.dataTopBrowsers = [];
            if(val.page.chart_clicks.browsers) {
                Object.values(val.page.chart_clicks.browsers).forEach((element:any) => {
                    this.dataTopBrowsers.push(element)
                });
            }

            if(val.pp_tr_links_count > 0) {
                this.loading = false;
            }

        }
    },

    created() {
        if(this.allData.pp_tr_links_count > 0) {
            this.createData(this.allData)
        }
    }

});
