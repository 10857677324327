<template>
    <div class="fonts mt-4">
        <div class="title">Font size:</div>
        <ul class="size-list">
            <li :class="[{'active' : classNameFont == item.className}, item.className]" @click="selectSize(item)" v-for="item in size" :key="item.className" >{{item.name}}</li>
        </ul>
    </div>
</template>

<script lang="ts">
import {defineComponent} from 'vue';

export default defineComponent({
    name: 'SelectFontSize',
    components:{},
    props: {
        selected: String
    },
    emits: ['changeFontSize'],
    data() {
        return {
            classNameFont: this.selected,
        }
    },
    methods: {
        selectSize(item: {'name': string, 'className': string}) {
            this.classNameFont = item.className;
            this.$emit('changeFontSize',item)

        }
    },
    watch: {
        selected(val) {
            this.classNameFont = val;
        }
    },
    setup() {
        const size = [
            {
                'name' : 'XS',
                'className' : 'font-xs'
            },
            {
                'name' : 'S',
                'className' : 'font-s'
            },
            {
                'name' : 'M',
                'className' : 'font-m'
            },
            {
                'name' : 'L',
                'className' : 'font-l'
            },
            {
                'name' : 'XL',
                'className' : 'font-xl'
            }
        ];

        return {
            size
        }
    }
});
</script>

<style lang="scss">
   .mt-4 {
        margin-top: 1.5rem !important;
   }
   .fonts {
        .size-list {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-left: -8px;
            margin-right: -8px;
            li {
                height: 46px;
                border: 1.23134px solid #E4E4E4;
                border-radius: 12.3134px;
                width: calc(100% / 5 - 16px);
                margin: 0 8px;
                display: flex;
                align-items: center;
                justify-content: center;
                font-style: normal;
                font-weight: 600;
                color: #000000;
                cursor: pointer;

                &.active {
                    border: 2px solid #5B60CA;
                }
            }

        }
   }
</style>