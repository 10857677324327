
import {defineComponent} from 'vue';
import {mapGetters} from 'vuex';
import Skeleton from '../common/Skeleton.vue';

export default defineComponent({
    name: 'Sidebar',
    components:{
    Skeleton
},
    props:{
        show: Boolean,
    },
    emits: ['update:show'],
    data(){
        return {
            menu: true,
            cl: 'active',
            current: ['sub0'],
            open: ['sub1','sub2'],
            widthWindow: window.innerWidth,
            menuItem: [] as any,
            loading: true,
        }
    },
    watch:{
        show(val){
            this.menu = val
        },
        menu(val){
            this.$emit('update:show',val)
            new Promise( (resolve)=>{setTimeout(resolve, 200) } )
            .then( ()=> {
                this.cl = this.menu ? 'show' : ''
                document.body.style.overflow= this.menu ? 'show' : ''
            });
        },
        getMainMenu(val) {
            this.menuItem = val.menu
            this.loading = false;
        }

    },

    computed: mapGetters(['getMainMenu']),
    methods: {

        myEventHandler() {
            // your code for handling resize...
            let widthWindow = window.innerWidth;
            if(widthWindow < 1400) {
                this.menu = false;
            }else{
                this.menu = true;
            }

        }
    },

    created() {

        window.addEventListener("resize", this.myEventHandler);

        if(this.widthWindow < 1400) {
            this.menu = false;
        }
    },

    unmounted() {
        window.removeEventListener("resize", this.myEventHandler);
    },
});
