<template>
    <ul class="pxlife-help">
        <li>
            <a class="link-lib" href="#">
                <svg class="icon">
                    <use xlink:href="#book-open"></use>
                </svg>
                <!-- <span>How to get started</span> -->
            </a>
        </li>
        <li>
            <a class="link-lib" href="#">
                <svg class="icon">
                    <use xlink:href="#play"></use>
                </svg>
                <!-- <span>Watch video tutorial</span> -->
            </a>
        </li>
        <li>
            <a class="link-lib" href="#">
                <svg class="icon">
                    <use xlink:href="#sparkles"></use>
                </svg>
                <!-- <span>Take a tour</span> -->
            </a>
        </li>
    </ul>
</template>

<script lang="ts">
import {defineComponent} from 'vue';

export default defineComponent({
    name: 'PxLifeMenu',
    components:{},
});
</script>

<style lang="scss">
    .pxlife {
        &-help {
            display: flex;
            align-items: center;
            padding-left: 16px;
            padding-right: 16px;
            .icon {
                width: 20px;
                height: 20px;
                margin-right: 0;
            }
            li {
                margin-left: 4px;
                &:first-child {
                    margin-left: 0;
                }
                
                .link-lib {
                    margin-left: 0;
                    margin-right: 0;
                }
            }
        }
    }

    @media screen and (max-width: 991px) {
        .pxlife {
            &-help {
                li {
                    margin-left: 10px;
                }
            }
        }    
    }
    @media screen and (max-width: 720px) {
        .pxlife {
            &-help {
                .icon {
                    width: 25px;
                    height: 25px;
                }
                li {
                    span {
                        display: none;
                    }
                }
            }
        }
    }
</style>