<template>
    <div class="analitics-container">
        <h6 class="h6-lib ff-Poppins font-weight-600 normal-margin">Top browsers</h6>
        <div class="chart-browsers" :class="{'d-none' : Object.values(dataBrowsers).length === 0}" ref="chartdiv"></div>
        <img v-if="Object.values(dataBrowsers).length === 0" src="@/assets/images/no-data.svg" alt="Empty data" class="w-100 no-data" />
    </div>
</template>

<script lang="ts">
import {defineComponent} from 'vue';
import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';

export default defineComponent({
    name: 'AnaliticsTopBrowsers',
    components:{},
    props: ['data', 'chartUnique'],
    data() {
        return {
            root: '' as any,
            dataBrowsers: this.data,
            unique: this.chartUnique ,
            loaded: false,
        }
    },
    watch: {
        data(val) {
            this.dataBrowsers = val;
            this.reloadChart();
        },

        chartUnique(val) {
            this.unique = val;
            this.reloadChart();
        }

    },

    methods: {
        reloadChart(){
            this.loaded = false;
            this.root.dispose();

            setTimeout(() =>{
                this.loaded = true;
                setTimeout(() =>{
                    this.loadChart(this.dataBrowsers);
                }, 200)
            }, 500) 
        },

       loadChart(data: any)  {
            let root = am5.Root.new((this.$refs.chartdiv as HTMLInputElement));
           
                        
            // Set themes
            // https://www.amcharts.com/docs/v5/concepts/themes/
            root.setThemes([
                am5themes_Animated.new(root)
            ]);


            // Create chart
            // https://www.amcharts.com/docs/v5/charts/xy-chart/
            let chart = root.container.children.push(am5xy.XYChart.new(root, {
                panX: false,
                panY: false,
                wheelX: "none",
                wheelY: "none"
            }));

            chart?.get("colors")?.set("colors", [
                am5.color(0xDE65D7),
                am5.color(0xC059E3),
                am5.color(0x9959E1),
                am5.color(0x6E56DF),
                am5.color(0x616EE6),
                am5.color(0x649AEB)
            ]);
            // We don't want zoom-out button to appear while animating, so we hide it
            chart.zoomOutButton.set("forceHidden", true);


            // Create axes
            // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
            let yRenderer = am5xy.AxisRendererY.new(root, {
                minGridDistance: 30
            });

            let yAxis = chart.yAxes.push(am5xy.CategoryAxis.new(root, {
                maxDeviation: 0,
                categoryField: "title",
                renderer: yRenderer,
                tooltip: am5.Tooltip.new(root, { themeTags: ["axis"] })
            }));

            let xAxis = chart.xAxes.push(am5xy.ValueAxis.new(root, {
                maxDeviation: 0,
                min: 0,
                extraMax:0.1,
                renderer: am5xy.AxisRendererX.new(root, {})
            }));


            // Add series
            // https://www.amcharts.com/docs/v5/charts/xy-chart/series/
            let series = chart.series.push(am5xy.ColumnSeries.new(root, {
                name: "Browsers",
                xAxis: xAxis,
                yAxis: yAxis,
                valueXField: this.unique ? "unique_value" : "total_value",
                categoryYField: "title",
                tooltip: am5.Tooltip.new(root, {
                    pointerOrientation: "left",
                    labelText: "{valueX}"
                })
            }));


            // Rounded corners for columns
            series.columns.template.setAll({
                cornerRadiusTR: 5,
                cornerRadiusBR: 5
            });

            // Make each column to be of a different color
            series.columns.template.adapters.add("fill", function(fill, target) {
                return chart?.get("colors")?.getIndex(series.columns.indexOf(target));
            });

            series.columns.template.adapters.add("stroke", function(stroke, target) {
                return chart?.get("colors")?.getIndex(series.columns.indexOf(target));
            });

            yAxis.data.setAll(data);
            series.data.setAll(data);
            sortCategoryAxis();

            // Get series item by category
            function getSeriesItem(category: any) {
                for (let i = 0; i < series.dataItems.length; i++) {
                    let dataItem = series.dataItems[i];
                    if (dataItem.get("categoryY") == category) {
                    return dataItem;
                    }
                }
            }

            chart.set("cursor", am5xy.XYCursor.new(root, {
                behavior: "none",
                xAxis: xAxis,
                yAxis: yAxis
            }));


            // Axis sorting
            function sortCategoryAxis() {

            // Sort by value
            series.dataItems.sort(function(x:any, y:any) {
                return x.get("valueX") - y.get("valueX"); // descending
                //return y.get("valueY") - x.get("valueX"); // ascending
            })

            // Go through each axis item
            am5.array.each(yAxis.dataItems, function(dataItem) {
                // get corresponding series item
                let seriesDataItem = getSeriesItem(dataItem.get("category"));

                if (seriesDataItem) {
                // get index of series data item
                let index = series.dataItems.indexOf(seriesDataItem);
                // calculate delta position
                let deltaPosition = (index - dataItem.get("index", 0)) / series.dataItems.length;
                // set index to be the same as series data item index
                dataItem.set("index", index);
                // set deltaPosition instanlty
                dataItem.set("deltaPosition", -deltaPosition);
                // animate delta position to 0
                dataItem.animate({
                    key: "deltaPosition",
                    to: 0,
                    duration: 1000,
                    easing: am5.ease.out(am5.ease.cubic)
                })
                }
            });

            // Sort axis items by index.
            // This changes the order instantly, but as deltaPosition is set,
            // they keep in the same places and then animate to true positions.
            yAxis.dataItems.sort(function(x:any, y:any) {
                return x.get("index") - y.get("index");
            });
            }


            // Make stuff animate on load
            // https://www.amcharts.com/docs/v5/concepts/animations/
            series.appear(1000);
            chart.appear(1000, 100);


            this.root = root;
       }
    },
    mounted() {
        // < !--Chart code-- >
        am5.addLicense("AM5C335843595");
        am5.addLicense("AM5M335847822");
        this.loadChart(this.dataBrowsers);
    },
});
</script>

<style lang="scss">
    .chart-browsers {
        min-height: 300px;
        margin: 0 -20px;
        width: calc(100% + 40px);
    }
</style>