<template>
    <div class="accordion-item" :class="{'active': showAccordion && !lock}">
        <div class="accordion-top" @click="showAccordion = !showAccordion">
            <div class="title">{{title}} 
            <span v-if="lock" class="lock-article">
                <span class="lock-svg"></span>
                <span class="lock-text">Upgrade to unlock</span>
            </span>
            </div>
            <div class="subtitle">{{subtitle}}</div>
        </div>
        <Transition v-if="!lock" name="slide-fade">
            <div class="accordion-content" v-show="showAccordion">
                <slot></slot>
            </div>
        </Transition>
    </div>
</template>

<script lang="ts">
import {defineComponent} from 'vue';

export default defineComponent({
    name: 'Accordion',
    components:{},
    emits: ['update:accordion'],
    props: {
        'title' : String,
        'subtitle' : String,
        'show' : {
            type: Boolean,
            default: false
        },
        'lock': {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            showAccordion : this.show ? this.show : false,
        }
    },
    watch: {
        showAccordion(e) {
            this.$emit('update:accordion',e)
        }
    }
});
</script>

<style lang="scss">

</style>