<template>
    <div class="profile">

        <div class="profile-photo" :class="{'error' : Object.keys(getError).length > 0 && getError.errors['options.avatar_image'] !== undefined}">
            <div class="photo-upload">
                <a v-if="dataProfile?.options?.avatar_image?.length > 0 && dataProfile?.options?.avatar_image != null" class="btn-lib btn-lib-secondary btn-change" @click="toggleShow">Change</a>
                <img v-if="dataProfile?.options?.avatar_image?.length > 0 && dataProfile?.options?.avatar_image != null" 
                    :src="dataProfile.options != null ? dataProfile?.options?.avatar_image : ''">
                <div @click="toggleShow" v-else class="img-empty">Click to upload avatar</div>
            </div>
            <!-- <myUpload field="img"
                    @crop-success="cropSuccess"
                    :maxSize="1536"
                    v-model="showUploadModal"
                    :langType="'en'"
                    img-format="png"
                    :noRotate="false"
                /> -->
           
        </div>
        <div class="profile-info">
            <ProfileChangeDomain 
                @changeDD="changeDomain"
                @value="changeInput"
                :value="dataProfile.code" 
                :domain="dataProfile.domain" />

            <InputCount
                :value="dataProfile.title "
                placeholder="Profile name"
                :maxsymbols="32" 
                @value="changeUserName"
            />

            <TextareaCount
                :required="false"
                :value="dataProfile.desc || ''"
                placeholder="Description"
                :maxsymbols="100" 
                @value="changeDescription" 
            />
            <MultipleDropdown 
                :select="dataProfile?.pixelcodes"
                :items="getUserInfo.tracking_codes"
                @changeDropdown="changeCode" />

        </div>
    </div>

    <Modal
        :close-overflow="false"
        :show="showUploadModal" 
        @close="closeModal" 
        @save="saveModal" 
        modalTitle="Upload your photo here"
    >
    <div class="drop-container text-left">
        <div class="drop-item">
                <!-- <h6 class="title">Upload your photo here</h6> -->
                <p class="subtitle">PNG, JPG, SVG, or GIF, maximum size 2mb</p>
                <div >
                    <UploadImage
                        v-show="cropImage === ''"
                        :max="1"  
                        @changeImage="changeImage"
                        :image="dataProfile?.options?.avatar_image ? dataProfile?.options?.avatar_image : cropperImgUpload" 
                        :maxSize="2" /> 

                    <cropper
                        class="cropper"
                        :src="cropperImgUpload"
                        ref="cropper" 
                        @change="changeCropper"
                    />
                </div>
                
            </div>
        </div>
    </Modal>

</template>

<script lang="ts">
import {defineComponent} from 'vue';
import {mapGetters} from 'vuex';
import ProfileChangeDomain from './ProfileChangeDomain.vue';
import InputCount from '../InputCount.vue';
import TextareaCount from '../TextareaCount.vue';
import MultipleDropdown from '../MultipleDropdown.vue';
// import myUpload from 'vue-image-crop-upload';
import Modal from '../Modal.vue';
import UploadImage from '../UploadImage.vue';
import { Cropper } from 'vue-advanced-cropper'

export interface profileData {
  domain: string;
  photo: string;
  shortlink: string;
  username: string;
  bio: string;
  plan: string;
}


export default defineComponent({
    name: 'ProfileCard',
    components:{ ProfileChangeDomain, InputCount, TextareaCount, MultipleDropdown, Modal, UploadImage, Cropper },
    emits: ['changeProfile'],
    props: {
        data: {} as any
    },

    data() {
        return {
            dataProfile: this.data,
            load: true,
            showUploadModal: false,
			cropImage: '',
            cropperImgUpload: '',
        }
    },
    watch: {
        data(val) {
            this.dataProfile = val;
        }
    },
    computed: mapGetters(['getUserInfo', 'getError']),

    methods: {

        closeModal(status: boolean) {
            this.showUploadModal = status;
        },

        saveModal() {
            if(this.cropImage !='') {
                this.dataProfile.options.avatar_image = this.cropImage;
                this.saveData(this.dataProfile);
            }
            this.cropImage = '';
            this.cropperImgUpload = '';
        },

        changeImage(img: any) {
            this.cropperImgUpload = img[0];
        },

        changeCropper(item: {coordinates: any, canvas:any}) {
            this.cropImage = item.canvas?.toDataURL("image/webp");
        },

        changeDomain(item: string) {
            this.dataProfile.domain = item;
            this.saveData(this.dataProfile);
        },
        changeInput(item: string) {
            this.dataProfile.code = item;
            this.saveData(this.dataProfile);
        },
        changeUserName(item: string) {
            this.dataProfile.title = item;
            this.saveData(this.dataProfile);
        },
        changeDescription(item: string) {
            this.dataProfile.desc = item;
            this.saveData(this.dataProfile);
        },

        changeCode(val: []) {
            this.dataProfile.pixelcodes = val;
            this.saveData(this.dataProfile);
        },

        saveData(item:  any) {
            this.$emit('changeProfile', item)
        },

        toggleShow() {
            this.cropImage = '';
            this.cropperImgUpload = '';
            this.showUploadModal = !this.showUploadModal;
		},

        cropSuccess(imgDataUrl: string){
            // this.imgDataUrl = imgDataUrl;

            this.dataProfile.options.avatar_image = imgDataUrl;
            this.saveData(this.dataProfile);
        },
        
    },
    created() {
        if(this.dataProfile.title == '') {
            this.changeUserName(this.getUserInfo.name)
        }
    }
});
</script>
