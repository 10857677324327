<template> 
  <nav class="nav">
    <div class="container-fluid">
      <div class="flex-row items-center">
        <button class="sidebar-toggler" type="button" @click="ActiveSidebar = !ActiveSidebar"></button>
        <Logo />
        <ShortenForm />
        <UserMenu />
      </div>
    </div>
  </nav>
  <Sidebar @update:show="updateStatus" v-model:show="ActiveSidebar" />
</template>

<script lang="ts">
import {defineComponent} from 'vue';
import Logo from '@/components/common/Logo.vue';
import ShortenForm from '@/components/common/ShortenForm.vue';
import UserMenu from '@/components/layout/UserMenu.vue';
import Sidebar from '@/components/layout/Sidebar.vue';

export default defineComponent({
  name: 'Header',
  emits: ['update:sidebar'],
  components: {
    Logo,
    ShortenForm,
    UserMenu,
    Sidebar
  },
  data() {
    return {
      ActiveSidebar: true
    }
  },
  methods: {
    updateStatus(status: boolean) {
       this.$emit('update:sidebar',status)
    }
  }
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
  .nav {
    background: #fff;
    padding: 16px 0;
    min-height: 78px;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    position: fixed;
    z-index: 99;
    width: 100%;
    box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.04);
    .flex-row {
      flex-wrap: nowrap;
      flex-direction: row;
    }
  }

  .sidebar-toggler {
    background: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='%2373818f' stroke-width='2.25' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
    background-size: 26px;
    background-position: center;
    background-repeat: no-repeat;
    width: 50px;
    height: 33px;
    border: 0;
    display: none;

    &:hover {
      background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='%2373818f' stroke-width='2.25' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
    }
  }

  @media screen and (max-width: 991px) {
    .sidebar-toggler {
      display: block;
    }

    .nav {
      position: relative;
    }
  }
</style>