<template>
    <div class="analitics-container">
        <h6 class="h6-lib ff-Poppins font-weight-600">Link performance</h6>
        <div class="analitics-items performance">
            <div class="analitics-item" v-for="item in dataItems" :key="item.pp_inner_link_id">
                <div class="analitics-name">
                    <component v-if="icons[item.options.favicon]"
                        :class="{'selected-icon' : item.options?.favicon !== 'PhotoIcon'}"
                        size="24"
                        :stroke-width="strokeWidth"
                        :is="icons[item.options.favicon]"
                        :style="{ color }"
                    />
                    <img v-else class="icon" :src="item.options?.favicon" alt="">
                    
                    <span v-tippy="`${item.url} `">{{item.url}}</span>
                </div>
                <div class="analitics-number" >
                    <template v-if="unique">{{item.unique}}</template>
                    <template v-else>{{item.total}}</template>
                </div>
                <div class="analitics-ctr">
                    <div class="ctr-title">CTR </div>
                    <div class="ctr-rate">
                        <template v-if="unique">{{item.ctr_unique}}%</template>
                        <template v-else>{{item.ctr_total}}%</template>
                    </div> 
                </div>
            </div>
        </div>
        <img v-if="Object.values(dataItems).length === 0" src="@/assets/images/no-data.svg" alt="Empty data" class="w-100 no-data">
    </div>
</template>

<script lang="ts">
import {defineComponent} from 'vue';
import * as icons from 'vue-tabler-icons';


export default defineComponent({
    name: 'AnaliticsLinkPerformance',
    components:{},
    props: ['data', 'chartUnique'],
    data() {
        return {
            color: '#5B60CA',
            strokeWidth: '2',
            unique: this.chartUnique,
            dataItems: this.data || []
        }
    },
    watch: {
        data(val) {
            this.dataItems = val;
        },
        chartUnique(val) {
            this.unique = val;
        } 
    },
    computed : {
        icons() {
            return icons;
        },
    }
});
</script>

<style lang="scss">
  .analitics-name {
    span {
        display: inline-block;
        white-space: nowrap;
        overflow: hidden;
        max-width: calc(100% - 30px);
    }
  }
    

</style>