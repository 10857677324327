<template>
 <draggable  handle=".drag-drop"  v-model="socialItems" @change="draggableChange"  v-bind="dragOptions"  @start="isDragging=true" @end="isDragging=false">
        <transition-group name="switch" appear>
            <template v-for="(link, index) in socialItems" :key="link.id">
                <InputSocialType  v-show="link.type ==='social'"
                    :index="index" 
                    :data="link"
                    @value="setSocialInputValue" 
                    @deleteEvent="deleteEvent"
                    @publishEvent="publishEvent"
                />
            </template>
            
        </transition-group>
    </draggable>


    <button @click="openModal" class="btn-lib btn-lib-primary">+ Add social link</button>
    
    <Modal
        modalTitle="Select social link"
        :show="showModal"
        classBody="social-modal"
        @close="closeModal" 
        type="social"
    >
        <form action="#" class="search">
            <input v-model="search" type="text" class="input-lib search-input search-social" placeholder="Search social" />
        </form>
        <div class="icon-container">
            <template v-for="(item, index) in filteredList" :key="index">
                <div @click="addEvent(index)" class="change-social" v-if="!socialItems?.find(item => item.options.favicon === String(index))" >
                    <svg class="icon">
                        <use :xlink:href="item.icon"></use>
                    </svg>

                    <div class="title">{{item.name}} <span>{{item.domain}}</span></div>
                </div>
            </template>
        </div>
    </Modal>
</template>

<script lang="ts">
import {defineComponent} from 'vue';
import {mapGetters} from 'vuex';
import { VueDraggableNext } from 'vue-draggable-next';
import InputSocialType from './InputSocialType.vue';
import Modal from '../Modal.vue';

export interface SocialItem {
  id: number;
  type: string;
  options: {
    favicon: string;
  };
  title: any;
  url: string;
  status: string;
  order: number;
}

export default defineComponent({
    name: 'SocialIcons',
    emits: ['socialLinks'],
    components:{
        draggable: VueDraggableNext,
        InputSocialType,
        Modal
    },
    props: {
        data : {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            socialItems: this.data as Array<SocialItem>,
            backStatus: false,
            showModal: false,
            search: '',
            editable: true,
            isDragging: false,
            delayedDragging: false
        }
    },

    watch: {
        data(val) {
            this.socialItems = val;
        },
        isDragging(newValue) {
            if (newValue) {
                this.delayedDragging = true;
                return;
            }
            this.$nextTick(() => {
                this.delayedDragging = false;
            });
        }
        
    },

    methods: {
        openModal() {
            this.showModal = true;
        },
        closeModal(status: boolean) {
            this.showModal = status;
        },
        addEvent (index: any) {
            let item = {
                "id": (new Date()).getTime(),
                "type": this.getConstants.pp_inner_links.type[1],
                "title": null,
                "url": "",
                "options": {
                    'favicon': index
                },
                "pp_tr_link_id": (new Date()).getTime(),
                "order": this.socialItems.length,
                "status": this.getConstants.common.status[1]
            };
            this.socialItems.push(item)
            this.saveData(this.socialItems);
            this.showModal = false;
        },
        deleteEvent: function(event: {'item': any, 'index': number}) {
            this.socialItems.splice(this.socialItems.indexOf(event.item), 1);
            this.saveData(this.socialItems);
        },
     
        draggableChange() {
            for (let i = 0; i < this.socialItems.length; i++) {
                this.socialItems[i].order = i;
            }
            
            this.saveData(this.socialItems);

        },

        setSocialInputValue(event: any) {
            this.socialItems[event.index].url = event.value;
            // this.socialItems[event.index].title = event.value;

            this.saveData(this.socialItems);

        },


        publishEvent(event: {'item': any, 'index': number}) {
            this.socialItems[event.index].status = event.item.status;
            this.saveData(this.socialItems);
        },
        
        backItem(){
            this.backStatus = true;
        },
         saveData(links:  Array<SocialItem>) {
            this.$emit('socialLinks', links)
        },
        
    },

    computed: {
        ...mapGetters(['getSocialsData', 'getConstants']),

        filteredList(): any {
            return  Object.fromEntries(Object.entries(this.getSocialsData).filter(([key]) => key.toLowerCase().includes(this.search.toLowerCase())));
        },
        dragOptions() {
            return {
                animation: 300,
                ghostClass: "ghost"
            };
        },
    }
});
</script>

<style lang="scss">
    .search-social {
        margin-bottom: 1.5rem !important;
        position: sticky;
        top: 0;
    }

    .change-social {
        display: flex;
        align-items: center;
        font-family: 'Lato';
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 28px;
        color: #292D34;
        margin-left: -32px;
        margin-right: -32px;
        width: calc(100% + 64px);
        cursor: pointer;
        padding: 19px 32px;
        text-align: left;
        transition: .3s;

        &:hover {
            background: #E0E9EE;
        }

        .icon {
            width: 24px;
            height: 24px;
            margin-right: 10px;
        }
        .title {
            span {
                display: block;
                font-size: 10px;
                line-height: 1;
                color: #BBBFC6;
            }
        }
    }

    .social-modal {
        .icon-container {
            padding-top: 0;
            padding-bottom: 0;
            border-bottom: 0;
            margin-bottom: -32px;
            max-height: 480px;
        }
    }
</style>