
import {defineComponent} from 'vue';
import * as icons from 'vue-tabler-icons';


export default defineComponent({
    name: 'AnaliticsLinkPerformance',
    components:{},
    props: ['data', 'chartUnique'],
    data() {
        return {
            color: '#5B60CA',
            strokeWidth: '2',
            unique: this.chartUnique,
            dataItems: this.data || []
        }
    },
    watch: {
        data(val) {
            this.dataItems = val;
        },
        chartUnique(val) {
            this.unique = val;
        } 
    },
    computed : {
        icons() {
            return icons;
        },
    }
});
