
import {defineComponent} from 'vue';
import Logo from '@/components/common/Logo.vue';
import ShortenForm from '@/components/common/ShortenForm.vue';
import UserMenu from '@/components/layout/UserMenu.vue';
import Sidebar from '@/components/layout/Sidebar.vue';

export default defineComponent({
  name: 'Header',
  emits: ['update:sidebar'],
  components: {
    Logo,
    ShortenForm,
    UserMenu,
    Sidebar
  },
  data() {
    return {
      ActiveSidebar: true
    }
  },
  methods: {
    updateStatus(status: boolean) {
       this.$emit('update:sidebar',status)
    }
  }
});
