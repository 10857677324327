<template  >
    <transition name="fade" appear @keydown="keydownEsc" >
        <div class="modal" v-show="show" >
            <div @click="onClose" class="close"></div>
            <div v-click-outside="vcoConfig" :class="classModal" class="modal-content text-center"  >
                <div v-if="type === 'qr'">
                    <div class="preview-top">
                        <button ref="mylink" v-clipboard:copy="qrLink" v-clipboard:success="copyLink"  class="preview-link  link-lib">
                            <i class="icon-copy"><svg class="icon"><use xlink:href="#copy"></use></svg></i>
                            <span v-if="!copiedLink">
                                {{qrLink}}
                            </span>
                            <span v-else>
                                Copied
                            </span>
                        </button>
                    </div>
                </div>
                <h4 v-else class=" ff-Poppins font-weight-600 " :class="[{'h6-lib': type == 'share'}, {'h5-lib' : type != 'share'}]">{{modalTitle}}</h4>
                <slot></slot>
                <div class="btn-group" v-if="!type || type =='qr'">
                    <button @click="onClose" class="btn-lib btn-lib-secondary">Cancel</button>
                    <button v-if="type !== 'qr'" @click="saveSetting" class="btn-lib btn-lib-dark">Save </button>
                </div>
            </div>
        </div>
    </transition>    
</template>

<script lang="ts">
import {defineComponent} from 'vue';


export default defineComponent({
    name: 'Modal',
    components:{},
    props: {
        modalTitle: String, 
        qrLink: String,
        show: {
            type: Boolean,
            default: false,
        },
        type: String,
        classBody: String,
        closeOverflow: {
            type: Boolean,
            default: true
        },
    },
    emits: ['close', 'save'],

    data() {
        return {
            modal: false,
            classModal: this.classBody ,
            copiedLink: false,
            vcoConfig: {
                middleware: this.middleware,
                events: ['click'],
                // Note: The default value is true, but in case you want to activate / deactivate
                //       this directive dynamically use this attribute.
                isActive: true,
                // Note: The default value is true. See "Detecting Iframe Clicks" section
                //       to understand why this behaviour is behind a flag.
                detectIFrame: true,
                // Note: The default value is false. Sets the capture option for EventTarget addEventListener method.
                //       Could be useful if some event's handler calls stopPropagation method preventing event bubbling.
                capture: true
            }
        }
    },
    watch:{
        show(val){
            this.modal = val
        },
        modal(val){
            this.$emit('close',val)
        }
    },
    methods: {
        saveSetting() {
            this.$emit('save', 'success');
            this.modal = false;
        },
        onClose() {
            this.modal = false;
        },

        middleware (event:any) {

            if(event.target.className !== this.classModal && event.target.className == 'modal' && this.closeOverflow) {
                this.modal = false;
                this.$emit('close',this.modal)
            }
        },

        async copyLink() {
            this.copiedLink = true;
            new Promise( (resolve)=>{setTimeout(resolve, 2000) } )
                .then( ()=> {
                this.copiedLink = false;
            });
        },

        keydownEsc(event:any) {
            if (event.code == 'Escape') {
                this.modal = false;
                this.$emit('close',this.modal)
            }
        }
    },

    mounted() {
        document.addEventListener('keydown', this.keydownEsc);
        
    }
});
</script>

<style lang="scss">
 .modal {
  overflow: hidden;
  background: rgba(41, 45, 52, .8);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 198;

  .preview-top {
    margin-top: -32px;
    margin-left: -32px;
    margin-right: -32px;
    width: calc(100% + 64px);
  }

  .close {
    background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='0.00012207' y='18.0001' width='25.4556' height='2.8284' transform='rotate(-45 0.00012207 18.0001)' fill='white'/%3E%3Crect x='18' y='20.0001' width='25.4556' height='2.8284' transform='rotate(-135 18 20.0001)' fill='white'/%3E%3C/svg%3E%0A");
    width: 20px;
    height: 20px;
    position: absolute;
    top: 32px;
    right: 32px;
    z-index: 100;
    cursor: pointer;
    transition: .1s;
    display: block;
    opacity: .8;

    &:hover {
        opacity: 1;
    //   transform: scale(1.2);
    }
  }

  &.share {
    .modal-content {
        max-width: 350px;

        h4 {
            font-size: 20px;
        }
    }
  }

  &-content {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    z-index: 99;
    padding: 32px;
    max-width: 500px;
    width: 100%;
    background: #FFFFFF;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
    border-radius: 8px;
    max-height: calc(100vh - 32px);
    overflow-x: hidden;
    overflow-y: auto;

    &.social-modal  {
        max-width: 400px;
        max-height: 660px;
    }

    h4 {
      font-size: 28px;
      line-height: 40px;
      letter-spacing: -0.2px;
      margin-bottom: 16px;
    }

    .btn-group {
      justify-content: space-between;
      display: flex;
      align-items: center;
      .btn-lib {
        width: auto;
      }
    }
  }
}
</style>