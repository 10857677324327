
import {defineComponent} from 'vue';
import * as am5 from '@amcharts/amcharts5';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import * as am5map from "@amcharts/amcharts5/map";

import am5geodata_worldLow from "@amcharts/amcharts5-geodata/worldLow";
import am5geodata_data_countries2 from "@amcharts/amcharts5-geodata/data/countries2";
import Skeleton from '../Skeleton.vue';


export default defineComponent({
    name: 'AnaliticsTopLocations',
    components:{ Skeleton },
    props: ['data', 'chartUnique'],
    data() {
        return {
            root: '' as any,
            continents : {} as any,
            dataLocations: this.data,
            unique: this.chartUnique,
            loaded: false,
        }
    },
    watch: {
        data(val) {
            this.dataLocations = val;
            this.reloadChart();
        },

        chartUnique(val) {
            this.unique = val;
            this.reloadChart();
        }

    },
    methods: {
        reloadChart(){
            this.loaded = false;
            this.root.dispose();

            setTimeout(() =>{
                this.loaded = true;
                setTimeout(() =>{
                    this.loadChart(this.dataLocations);
                }, 200)
            }, 500)
        },

       loadChart($data:any)  {
            // Set themes
            // https://www.amcharts.com/docs/v5/getting-started/#Root_element

            this.continents  = {
                'AF': 0,
                'AN': 1,
                'AS': 2,
                'EU': 3,
                'NA': 4,
                'OC': 5,
                'SA': 6
            }

            // Create root element
            // https://www.amcharts.com/docs/v5/getting-started/#Root_element
            let root = am5.Root.new((this.$refs.locations as HTMLInputElement));
            let colors = am5.ColorSet.new(root, {});


            // Set themes
            // https://www.amcharts.com/docs/v5/concepts/themes/
            root.setThemes([
                am5themes_Animated.new(root)
            ]);


            // Create the map chart
            // https://www.amcharts.com/docs/v5/charts/map-chart/
            let chart = root.container.children.push(am5map.MapChart.new(root, {
                panX: "rotateX",
                projection: am5map.geoMercator()
            }));


            // Create polygon series for the world map
            // https://www.amcharts.com/docs/v5/charts/map-chart/map-polygon-series/
            let worldSeries = chart.series.push(am5map.MapPolygonSeries.new(root, {
                geoJSON: am5geodata_worldLow,
                exclude: ["AQ"]
            }));

            worldSeries.mapPolygons.template.setAll({
                tooltipText: "{name}",
                interactive: true,
                fill: am5.color(0xaaaaaa),
                templateField: "polygonSettings"
            });

            worldSeries.mapPolygons.template.states.create("hover", {
                fill: colors.getIndex(9)
            });

           

            // Create polygon series for the country map
            // https://www.amcharts.com/docs/v5/charts/map-chart/map-polygon-series/
            let countrySeries = chart.series.push(am5map.MapPolygonSeries.new(root, {
                visible: false
            }));

            countrySeries.mapPolygons.template.setAll({
                tooltipText: "{name}",
                interactive: true,
                fill: am5.color(0xaaaaaa)
            });

            countrySeries.mapPolygons.template.states.create("hover", {
                fill: colors.getIndex(9)
            });

            let data = [];

            Object.values($data).forEach((el: { title: string; title2: string; }) => {
                if (el.title === 'Czechia') {
                    el.title2 = 'Czech Republic'
                }
            })


            for (let id in am5geodata_data_countries2) {
                if(Object.prototype.hasOwnProperty.call(am5geodata_data_countries2, id)) {
                    let country = am5geodata_data_countries2[id];
                    let selectedCountry:any = Object.values($data).find((el: { title: string; title2: string; }) => el.title === country.country || el.title2 === country.country)
                    
                    if (country.maps.length) {
                        data.push({
                            id: id,
                            map: country.maps[0],
                            name: selectedCountry ? `${country.country} - ${this.unique ? selectedCountry.unique_value : selectedCountry.total_value}` : country.country,
                            polygonSettings: {
                            // fill: colors.getIndex(continents[country.continent_code]),
                                fill: selectedCountry ? selectedCountry.hex_color : 'gray',
                            },
                        });
                    }
                }
            }

            worldSeries.data.setAll(data);
        
            //END Set themes
            this.root = root;
       }
    },
    mounted() {
        // < !--Chart code-- >
        am5.addLicense("AM5C335843595");
        am5.addLicense("AM5M335847822");
        this.loadChart(this.dataLocations);
    },
    created() {
        this.loaded = true;
    }
});
